import { localizedStrings } from '../localization/LocalizedStrings';

export const config = {
  businessUnitManagedProperty: 'owstaxIdBusinessx0020Unit',
  clientId: process.env.REACT_APP_CLIENT_ID as string,
  localization: {
    availableLanguages: [
      {
        lcid: 1031,
        name: 'de',
      },
      {
        lcid: 1033,
        name: 'en',
      },
      {
        lcid: 1036,
        name: 'fr',
      },
      {
        lcid: 1040,
        name: 'it',
      },
      {
        lcid: 1044,
        name: 'no',
      },
      {
        lcid: 1053,
        name: 'sv',
      },
    ],
    defaultLanguageCodeName: 'en',
    defaultLanguageLcid: 1033,
  },
  navigation: {
    customerSatisfactionLinkUrl: 'https://ims.implenia.com/ratingform.xhtml',
    // tslint:disable-next-line:max-line-length
    helpUrl: 'https://intra.implenia.net/ueber-uns/global-functions/project-excellence-services/quality/implenia-management-system/ims-prozessportal',
    processMapCustomProperty: 'ProcessMapLink',
    projectsLinkUrl: 'https://ims.implenia.com/projectlist.xhtml',
  },
  notificationDuration: 2000,
  predefinedFilters: {
    businessUnit: {
      cacheKey: 'PredefinedFilter.BusinessUnit',
      termSetId: process.env.REACT_APP_BUSINESS_UNIT_TERM_SET_ID as string,
    },
    country: {
      cacheKey: 'PredefinedFilter.Country',
      termSetId: process.env.REACT_APP_APPLICABLE_COUNTRY_TERM_SET_ID as string,
    },
    languages: {
      cacheKey: 'PredefinedFilter.Languages',
      termSetId: process.env.REACT_APP_DOCUMENT_LANGUAGE_TERM_SET_ID as string,
    },
    scopeOfService: {
      cacheKey: 'PredefinedFilter.ScopeOfService',
      termSetId: process.env.REACT_APP_SCOPE_OF_SERVICE_TERM_SET_ID as string,
    },
  },
  screenSizes: {
    landscapeScreenSize: 1200,
    mobileScreenSize: 576,
    tabletScreenSize: 768,
  },
  search: {
    pageSize: 250,
    queryTemplate: [
      'ContentType:"Implenia Standard"',
      `Path:"${process.env.REACT_APP_SHAREPOINT_URL}/Implenia Standard/*"`,
      '{query}',
      '{businessUnitWithParents}',
      'owstaxIdApplicablex0020Country:{country}',
      'owstaxIdProcessx0020Type:{processType}',
      '{languages}',
    ].join(' AND '),
    refiners: [
      {
        label: localizedStrings.documentTypeRefiner,
        name: 'ImplDocumentType',
        termSetId: process.env.REACT_APP_DOCUMENT_TYPE_TERM_SET_ID as string,
      },
      {
        label: localizedStrings.processTypeRefiner,
        name: 'ImplProcessType',
        termSetId: process.env.REACT_APP_PROCESS_TYPE_TERM_SET_ID as string,
      },
      {
        label: localizedStrings.subProcessRefiner,
        name: 'ImplSubProcess',
        termSetId: process.env.REACT_APP_SUB_PROCESS_TERM_SET_ID as string,
      },
      {
        label: localizedStrings.individualTagRefiner,
        name: 'ImplIndividualTag',
        termSetId: process.env.REACT_APP_INDIVIDUAL_TAG_TERM_SET_ID as string,
      },
    ],
    selectProperties: [
      'ImplFileTypeSortable',
      'UniqueId',
      'Filename',
      'DefaultEncodingURL',
      'SPSiteURL',
      'owstaxIdDocumentx0020Type',
      'owstaxIdDocumentx0020Language',
      'owstaxIdIndividualx0020tag',
      'DlcDocId',
      'ListID',
      'ListItemID',
    ],
    trimDuplicates: false,
  },
  sharepointUrl: process.env.REACT_APP_SHAREPOINT_URL as string,
  tenantId: process.env.REACT_APP_TENANT_ID as string,
};
