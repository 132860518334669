import * as React from 'react';
import { connect } from 'react-redux';
import * as accordionActions from '../../accordion/AccordionActions';
import { mapAccordionStateToProps } from '../../accordion/AccordionUtils';
import { houseConfig } from '../../config/HouseConfig';
import { Floor2Container } from '../../house/floor2/Floor2Container';
import { getFloorHeader } from '../../house/housePage/HousePageUtils';
import { RootState } from '../../store/types/RootState';
import { BaseHouseRowAccordionContainer } from './BaseHouseRowAccordionContainer';
import { IAccordionContainerDispatchProps } from './IAccordionContainerDispatchProps';
import { IAccordionContainerProps } from './IAccordionContainerProps';
import { IAccordionContainerStateProps } from './IAccordionContainerStateProps';

class Floor2AccordionContainer extends BaseHouseRowAccordionContainer<IAccordionContainerProps> {

  protected getHeaderElement(): JSX.Element {
    const { termSet, predefinedUrlParameters } = this.props;
    return getFloorHeader(predefinedUrlParameters, termSet, houseConfig.floor2.termId, false, false, 'bg-l');
  }

  // tslint:disable-next-line: prefer-function-over-method
  protected getContentsElement(): JSX.Element {
    return <Floor2Container key="floor2" />;
  }
}

const mapStateToProps = (state: RootState, ownProps: IAccordionContainerProps): IAccordionContainerStateProps =>
  mapAccordionStateToProps(ownProps.name, state);

const dispatchProps: IAccordionContainerDispatchProps = {
  onRender: accordionActions.createAccordion,
  onToggle: accordionActions.toggleCollapsedState,
};

const floor2AccordionContainer = connect(
  mapStateToProps,
  dispatchProps,
)(Floor2AccordionContainer);

export {
  floor2AccordionContainer as Floor2AccordionContainer,
};
