import { getType } from 'typesafe-actions';
import { ITaxonomyTerm } from '../lib/taxonomy/ITaxonomyTerm';
import { ITermSetInfo } from '../lib/taxonomy/ITermSetInfo';
import { RootState } from '../store/types/RootState';
import { ITermDescription } from './ITermDescription';
import { loadTermDescription, loadTermSet } from './TermSetActions';
import { TermSetAction } from './types/TermSetAction';

const defaultTermSets: ITermSetInfo[] = [];

const defaultTermDescriptions: ITermDescription[] = [];

const defaultTermSet: ITermSetInfo = {
  id: '',
  name: '',
  terms: [],
};

const defaultTerm: ITaxonomyTerm = {
  customProperties: {},
  defaultLanguageLabel: '',
  id: '',
  labels: [],
  pathOfTerm: [],
  termsCount: 0,
};

export function getTermSets(termSets: ITermSetInfo[] = defaultTermSets, action: TermSetAction): ITermSetInfo[] {
  switch (action.type) {
    case getType(loadTermSet.failure):
      return termSets;
    case getType(loadTermSet.success):
      return pushTermSet(action.payload, termSets);
    default:
      return termSets;
  }
}

export function getTermDescriptions(
  termDescriptions: ITermDescription[] = defaultTermDescriptions,
  action: TermSetAction,
): ITermDescription[] {
  switch (action.type) {
    case getType(loadTermDescription.failure):
      return termDescriptions;
    case getType(loadTermDescription.success):
      return pushTermDescription(action.payload, termDescriptions);
    default:
      return termDescriptions;
  }
}

export function getTermSetByIdFromState(state: RootState, termSetId: string): ITermSetInfo {
  const existingTermSet = state.termSets.find(termSet => termSet.id === termSetId);
  return existingTermSet === undefined ? defaultTermSet : existingTermSet;
}

export function getTermFromState(state: RootState, termSetId: string, termId: string): ITaxonomyTerm {
  const termSet = getTermSetByIdFromState(state, termSetId);
  const existingTerm = termSet.terms.find(term => term.id === termId);
  return existingTerm === undefined ? defaultTerm : existingTerm;
}

export function getTermDescriptionFromState(
  state: RootState,
  termId: string,
  lcid?: number,
): ITermDescription | undefined {
  return lcid === undefined
    ? state.termDescriptions.find(description => description.termId === termId && description.isDefault)
    : state.termDescriptions.find(description => description.termId === termId && description.lcid === lcid);
}

function pushTermSet(termSet: ITermSetInfo, termSets: ITermSetInfo[]): ITermSetInfo[] {
  const existingTermSet = termSets.find(x => x.id === termSet.id);
  if (existingTermSet !== undefined) {
    return termSets;
  }
  const newTermSets = [...termSets];
  newTermSets.push(termSet);
  return newTermSets;
}

function pushTermDescription(
  termDescription: ITermDescription,
  termDescriptions: ITermDescription[],
): ITermDescription[] {
  const existingTermSet = termDescriptions
    .find(description => description.termId === termDescription.termId && description.lcid === termDescription.lcid);
  if (existingTermSet !== undefined) {
    return termDescriptions;
  }
  const newTermDescriptions = [...termDescriptions];
  newTermDescriptions.push(termDescription);
  return newTermDescriptions;
}
