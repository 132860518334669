import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../store/types/RootState';
import { loadDocumentDetailsAsync, selectDocument } from '../SearchActions';
import { DocumentPanel } from './DocumentPanel';
import { IDocumentPanelContainerDispatchProps } from './IDocumentPanelContainerDispatchProps';
import { IDocumentPanelContainerProps } from './IDocumentPanelContainerProps';
import { IDocumentPanelContainerStateProps } from './IDocumentPanelContainerStateProps';

class DocumentPanelContainer extends React.PureComponent<IDocumentPanelContainerProps> {

  public render(): JSX.Element {
    const { selectedDocumentInfo, selectedDocumentDetails, onClose } = this.props;
    const opened = selectedDocumentInfo.listId !== '' && selectedDocumentInfo.listItemId !== 0;
    const document = selectedDocumentDetails.id !== 0 ? selectedDocumentDetails : undefined;
    return <DocumentPanel open={opened} document={document} onClose={onClose} />;
  }

  public componentDidUpdate(prevProps: IDocumentPanelContainerProps): void {
    const { listId, listItemId } = this.props.selectedDocumentInfo;
    if (listId !== '' && listItemId !== 0) {
      const { listId: prevListId, listItemId: prevListItemId } = prevProps.selectedDocumentInfo;
      if (listId !== prevListId || listItemId !== prevListItemId) {
        this.props.loadDocumentDetails();
      }
    }
  }
}

const mapStateToProps = (state: RootState): IDocumentPanelContainerStateProps => ({
  selectedDocumentDetails: state.search.selectedDocumentDetails,
  selectedDocumentInfo: state.search.selectedDocumentInfo,
});

const dispatchProps: IDocumentPanelContainerDispatchProps = {
  loadDocumentDetails: loadDocumentDetailsAsync.request,
  onClose: () => selectDocument(undefined),
};

const documentPanelContainer = connect(
  mapStateToProps,
  dispatchProps,
)(DocumentPanelContainer);

export {
  documentPanelContainer as DocumentPanelContainer,
};
