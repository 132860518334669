import React from 'react';
import { connect } from 'react-redux';
import { IconButton } from '../../../components/iconButton/IconButton';
import { openSortMenu } from '../../SearchActions';
import { ISortButtonContainerProps } from './ISortButtonContainerProps';
import './SortButtonContainer.scss';

class SortButtonContainer extends React.PureComponent<ISortButtonContainerProps> {

  public render(): JSX.Element {
    return (
      <div className="sort-button-container">
        <IconButton iconClass="icon-Sort" onClick={this.props.onClick} />
      </div>
    );
  }
}

const dispatchProps: ISortButtonContainerProps = {
  onClick: openSortMenu,
};

const sortButtonContainer = connect(
  undefined,
  dispatchProps,
)(SortButtonContainer);

export {
  sortButtonContainer as SortButtonContainer,
};
