import { connect } from 'react-redux';
import { config } from '../../../config/Config';
import { RootState } from '../../../store/types/RootState';
import { IPredefinedFilter } from '../../entities/IPredefinedFilter';
import { FilterChips } from './FilterChips';
import { IFilterChipsProps } from './IFilterChipsProps';

const mapStateToProps = (state: RootState): IFilterChipsProps => ({
  predefinedFilters: getPredefinedFilters(state),
  refinementFilters: state.search.refinementFilters,
});

const filterChipsContainer = connect(
  mapStateToProps,
  undefined,
)(FilterChips);

// tslint:disable-next-line: no-big-function
function getPredefinedFilters(state: RootState): IPredefinedFilter[] {
  const languagesChips = state.search.languageTermIds.map<IPredefinedFilter>(termId => ({
    multiple: true,
    showHierarchyInLabel: false,
    termId,
    termSetId: config.predefinedFilters.languages.termSetId,
  }));
  return [
    {
      multiple: false,
      showHierarchyInLabel: true,
      termId: state.search.businessUnitTermId,
      termSetId: config.predefinedFilters.businessUnit.termSetId,
    },
    {
      multiple: false,
      showHierarchyInLabel: false,
      termId: state.search.countryTermId,
      termSetId: config.predefinedFilters.country.termSetId,
    },
    ...languagesChips,
  ];
}

export {
  filterChipsContainer as FilterChipsContainer,
};
