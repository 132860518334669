import {
  QueryPropertyValueType,
  ResultTable,
  ResultTableCollection,
  SearchProperty,
  SearchQuery,
  SearchResponse,
  SearchResults,
  SortDirection as PnpSortDirection,
} from '@pnp/sp';
// tslint:disable-next-line:no-submodule-imports
import { IRefiner } from '@pnp/sp/src/search';
import { SortDirection } from '../entities/SortDirection';
import { IRawRefinerEntry } from './IRawRefinerEntry';
import { IRawSearchProperty } from './IRawSearchItem';
import { IRefinement } from './IRefinement';
import { IRefinementEntry } from './IRefinementEntry';
import { ISearchItem } from './ISearchItem';
import { ISearchQuery } from './ISearchQuery';
import { ISearchResult } from './ISearchResult';
import { getSearchQueryRefinementFilters } from './Refinement';
import {
  booleanValueType,
  dateTimeValueType,
  doubleValueType,
  int32ValueType,
  int64ValueType,
  nullValueType,
  stringValueType,
} from './SearchItemValueTypes';

export function convertSearchResult(searchResults: SearchResults): ISearchResult {
  const primaryQueryResult = searchResults.RawSearchResults.PrimaryQueryResult;
  return {
    items: primaryQueryResult !== undefined ? getSearchItems(primaryQueryResult) : [],
    properties: getSearchProperties(searchResults.RawSearchResults),
    refinements: primaryQueryResult !== undefined ? getRefinements(primaryQueryResult) : [],
    totalRows: searchResults.TotalRows,
    totalRowsIncludingDuplicates: searchResults.TotalRowsIncludingDuplicates,
  };
}

export function convertToPnpSearchQuery(query: ISearchQuery): SearchQuery {
  return {
    Properties: getSearchQueryProperties(),
    Querytext: query.queryText,
    RefinementFilters: getRefinementFilters(query),
    Refiners: getRefinersString(query),
    RowLimit: query.rowLimit,
    SelectProperties: query.selectProperties,
    SortList: query.sortProperties.map(property => ({
      Direction: property.direction === SortDirection.ascending
        ? PnpSortDirection.Ascending
        : PnpSortDirection.Descending,
      Property: property.propertyName,
    })),
    StartRow: query.rowLimit * (query.pageNumber - 1),
    TrimDuplicates: query.trimDuplicates,
  };
}

function getSearchQueryProperties(): SearchProperty[] {
  return [
    // adds modern group site content to search result
    {
      Name: 'EnableDynamicGroups',
      Value: {
        BoolVal: true,
        QueryPropertyValueTypeIndex: QueryPropertyValueType.BooleanType,
      },
    },
  ];
}

function getRefinementFilters(query: ISearchQuery): string[] | undefined {
  return query.refinementFilters !== undefined ? getSearchQueryRefinementFilters(query.refinementFilters) : undefined;
}

function getRefinersString(query: ISearchQuery): string | undefined {
  return query.refiners !== undefined ? query.refiners.join(',') : undefined;
}

function getSearchItems(primaryQueryResult: ResultTableCollection): ISearchItem[] {
  const relevantResults = primaryQueryResult.RelevantResults;
  return relevantResults !== undefined && relevantResults.Table !== undefined
    ? relevantResults.Table.Rows.map(row => getSearchItem(row.Cells))
    : [];
}

function getSearchProperties(searchResponse: SearchResponse): ISearchItem {
  return searchResponse.Properties !== undefined ? getSearchItem(searchResponse.Properties) : {};
}

function getRefinements(primaryQueryResult: ResultTableCollection): IRefinement[] {
  const refinementResults = primaryQueryResult.RefinementResults as ResultTable | null;
  return refinementResults !== null && refinementResults.Refiners !== undefined
    ? refinementResults.Refiners.map(getRefinement)
    : [];
}

function getRefinement(refiner: IRefiner): IRefinement {
  return {
    entries: refiner.Entries.map(getRefinementEntry),
    refinerName: refiner.Name,
  };
}

function getRefinementEntry(rawEntry: IRawRefinerEntry): IRefinementEntry {
  return {
    count: rawEntry.RefinementCount,
    name: rawEntry.RefinementName,
    token: rawEntry.RefinementToken,
    value: rawEntry.RefinementValue,
  };
}

function getSearchItem(properties: IRawSearchProperty[]): ISearchItem {
  const item: ISearchItem = {};
  properties.forEach(property => {
    item[property.Key] = parseSearchPropertyValue(property);
  });
  return item;
}

// tslint:disable-next-line:cyclomatic-complexity mccabe-complexity max-union-size
function parseSearchPropertyValue(property: IRawSearchProperty): undefined | string | number | Date | boolean {
  switch (property.ValueType) {
    case nullValueType:
       return undefined;
    case int32ValueType:
    case int64ValueType:
      return parseInt(property.Value, 10);
    case doubleValueType:
      return parseFloat(property.Value);
    case booleanValueType:
      return property.Value.toLowerCase() === 'true';
    case dateTimeValueType:
      return new Date(property.Value);
    case stringValueType:
    default:
      return property.Value;
  }
}
