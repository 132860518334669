import * as cachedUserService from '../lib/user/CachedUser';
import * as preferencesService from '../preferences/common/PreferencesStorage';
import * as documentDetailsService from '../search/documents/services/DocumentDetails';
import * as documentsSearchService from '../search/documents/services/DocumentSearch';
import * as searchLocationService from '../search/location/SearchLocation';
import * as cachedTaxonomyService from '../termSets/CachedTaxonomyStorage';

export const rootService = {
  cachedTaxonomyService,
  cachedUserService,
  documentDetailsService,
  documentsSearchService,
  preferencesService,
  searchLocationService,
};
