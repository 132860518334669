import { getValue, setValue } from '../../lib/cache/StorageCache';
import { IPreferenceInfo } from './IPreferenceInfo';

export function setPreference(key: string, value: string | undefined): void {
  let val = value;
  if (val === undefined) {
    val = '';
  }
  setValue(key, val);
}

export function setPreferences(key: string, values: string[] | undefined): void {
  let vals = values;
  if (vals === undefined) {
    vals = [];
  }
  setValue(key, vals);
}

export function getPreference(key: string): IPreferenceInfo {
  const cacheValue = getValue<string>(key);
  return {
    name: key,
    value: cacheValue !== undefined ? cacheValue : '',
  };
}

export function getPreferences(key: string): IPreferenceInfo {
  const cacheValue = getValue<string[]>(key);
  return {
    name: key,
    values: cacheValue !== undefined ? cacheValue : [],
  };
}
