import * as React from 'react';
import { houseConfig } from '../../config/HouseConfig';
import { IFloorTerm } from '../common/IFloorTerm';
import { IHouseFloorConfig } from '../common/IHouseFloorConfig';
import { Floor } from '../floor/Floor';
import { addFloor2 } from '../floor2/Floor2Utils';
import './HousePage.scss';
import { getFloorHeader, getFloorTerms } from './HousePageUtils';
import { IHousePageProps } from './IHousePageProps';

export class HousePage extends React.PureComponent<IHousePageProps> {

  public render(): JSX.Element {
    return (
      <div>
        {this.getFloors()}
      </div>
    );
  }

  private getFloors(): JSX.Element[] {
    let floors = this.addRoof([]);
    floors = this.addFloor3(floors);
    floors = addFloor2(floors);
    return this.addFloor1(floors);
  }

  private addRoof(floors: JSX.Element[]): JSX.Element[] {
    const roofElement = this.getFloorHeader(houseConfig.roofTermId, true, false, 'house-roof single-node');
    return [...floors, roofElement];
  }

  private addFloor3(floors: JSX.Element[]): JSX.Element[] {
    const floorConfig = houseConfig.floor3 as IHouseFloorConfig;
    const floorHeader = this.getFloorHeader(floorConfig.termId, false, false, 'om-bordered om-bordered-top');
    const floorTerms = this.getFloorTerms(floorConfig);
    const floorContents = <Floor key="floor3" childTerms={floorTerms} columnClass="col-md-20-100" />;
    return [...floors, floorHeader, floorContents];
  }

  private addFloor1(floors: JSX.Element[]): JSX.Element[] {
    const floorConfig = houseConfig.floor1 as IHouseFloorConfig;
    const floorHeader = this.getFloorHeader(floorConfig.termId, false, false, 'om-bordered');
    const floorTerms = this.getFloorTerms(floorConfig);
    const floorContents = (
      <Floor
        key="floor1"
        childTerms={floorTerms}
        columnClass="col-md-3"
        borderClass="om-bordered-bottom"
      />
    );
    return [...floors, floorHeader, floorContents];
  }

  private getFloorHeader(
    termId: string,
    clickable: boolean = false,
    redirectToSearch: boolean = false,
    className?: string,
  ): JSX.Element {
    const { termSet, predefinedUrlParameters } = this.props;
    return getFloorHeader(predefinedUrlParameters, termSet, termId, clickable, redirectToSearch, className);
  }

  private getFloorTerms(floorConfig: IHouseFloorConfig): IFloorTerm[] {
    const { termSet, predefinedUrlParameters } = this.props;
    return getFloorTerms(floorConfig, termSet, predefinedUrlParameters);
  }
}
