
import { BearerTokenFetchClient, FetchOptions, isUrlAbsolute } from '@pnp/common';
import AuthenticationContext from 'adal-angular';
import { getResource } from './PnPUtils';

export class PnPFetchClient extends BearerTokenFetchClient {

  public constructor(private readonly authContext: AuthenticationContext) {
    // tslint:disable-next-line: no-null-keyword
    super(null);
  }

  public async fetch(url: string, options: FetchOptions = {}): Promise<Response> {
    if (!isUrlAbsolute(url)) {
      throw new Error('You must supply absolute urls to PnPFetchClient.fetch.');
    }
    const resource = getResource(url);
    this.token = await this.getToken(resource);
    return super.fetch(url, options);
  }

  // tslint:disable-next-line: cognitive-complexity
  private async getToken(resource: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.authContext.acquireToken(resource, (message, token) => {
        if (token === null) {
          const err = new Error(message !== null ? message : '');
          reject(err);
        } else {
          resolve(token);
        }
      });
    });
  }
}
