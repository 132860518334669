import { IDictionary } from '../shared/IDictionary';
import { ILocalizedValue } from '../shared/ILocalizedValue';
import { IRawTerm } from './IRawTerm';
import { IRawTermSet } from './IRawTermSet';
import { IRawTermStore } from './IRawTermStore';
import { ITaxonomyTerm } from './ITaxonomyTerm';
import { ITermSetInfo } from './ITermSetInfo';
import { ITermStoreInfo } from './ITermStoreInfo';

export function convertTermStore(rawTermStore: IRawTermStore): ITermStoreInfo {
  return {
    defaultLcid: rawTermStore.DefaultLanguage as number,
    taxKeywordsTermSetId: convertGuidToString(rawTermStore.KeywordsTermSet.Id),
  };
}

export function convertTermSets(rawTermSets: IRawTermSet[], termStoreLcid: number): ITermSetInfo[] {
  return rawTermSets.map(rawTermSet => ({
    customSortOrder: parseCustomSortOrder(rawTermSet.termSet.CustomSortOrder as string | null),
    id: convertGuidToString(rawTermSet.termSet.Id as string),
    name: rawTermSet.termSet.Name as string,
    terms: getTermsFromCollection(rawTermSet.terms, termStoreLcid),
  }));
}

function getTermsFromCollection(rawTerms: IRawTerm[], termStoreLcid: number): ITaxonomyTerm[] {
  return rawTerms.map(rawTerm => convertTerm(rawTerm, termStoreLcid));
}

function convertTerm(rawTerm: IRawTerm, termStoreLcid: number): ITaxonomyTerm {
  const labels = getTermLabels(rawTerm);
  return {
    customProperties: rawTerm.CustomProperties as IDictionary<string>,
    customSortOrder: parseCustomSortOrder(rawTerm.CustomSortOrder),
    defaultLanguageLabel: getDefaultLanguageLabel(labels, termStoreLcid),
    description: rawTerm.Description,
    id: convertGuidToString(rawTerm.Id as string),
    isAvailableForTagging: rawTerm.IsAvailableForTagging,
    labels,
    parentId: rawTerm.Parent !== null ? convertGuidToString(rawTerm.Parent.Id) : undefined,
    pathOfTerm: (rawTerm.PathOfTerm as string).split(';'),
    termsCount: rawTerm.TermsCount !== undefined ? rawTerm.TermsCount : 0,
  };
}

function getTermLabels(term: IRawTerm): ILocalizedValue<string>[] {
  return term.Labels._Child_Items_.map(label => ({
    lcid: label.Language,
    value: label.Value,
  }));
}

function getDefaultLanguageLabel(allLabels: ILocalizedValue<string>[], termStoreLcid: number): string {
  return allLabels
    .filter(label => label.lcid === termStoreLcid)
    .map(label => label.value)[0];
}

function convertGuidToString(guidString: string): string {
  return guidString.replace('/Guid(', '')
    .replace(')/', '');
}

function parseCustomSortOrder(value: string | null): string[] | undefined {
  return value !== null ? value.split(':') : undefined;
}
