import { withStyles } from '@material-ui/core';
import React from 'react';
import Select from 'react-select';
// tslint:disable-next-line:no-submodule-imports
import { SelectComponentsConfig } from 'react-select/lib/components';
import { Control } from './Control';
import { DropdownIndicator } from './DropdownIndicator';
import { IndicatorSeparator } from './IndicatorSeparator';
import { ISearchableSelectProps } from './ISearchableSelectProps';
import { Menu } from './Menu';
import { NoOptionsMessage } from './NoOptionsMessage';
import { Option } from './Option';
import { Placeholder } from './Placeholder';
import { SingleValue } from './SingleValue';
import { searchableSelectStyles } from './Styles';
import { ValueContainer } from './ValueContainer';

class SearchableSelect<TOption> extends React.PureComponent<ISearchableSelectProps<TOption>> {

  private readonly components: SelectComponentsConfig<TOption> = {
    Control,
    DropdownIndicator,
    IndicatorSeparator,
    Menu,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
  };

  public render(): JSX.Element {
    return (
      <Select
        components={this.components}
        {...this.props}
      />
    );
  }
}

// tslint:disable-next-line:no-any
const searchableSelectWithStyles = withStyles(searchableSelectStyles, { withTheme: true })(SearchableSelect as any);

export {
  searchableSelectWithStyles as SearchableSelect,
};
