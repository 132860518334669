
import * as React from 'react';
import { localizedStrings } from '../../localization/LocalizedStrings';
import { ISearchBoxProps } from './ISearchBoxProps';
import { ISearchBoxState } from './ISearchBoxState';
import './SearchBox.scss';
import { getStateQueryText } from './SearchBoxUtils';

export class SearchBox extends React.PureComponent<ISearchBoxProps, ISearchBoxState> {

  public constructor(props: ISearchBoxProps) {
    super(props);
    this.state = {
      queryText: getStateQueryText(props),
    };
  }

  // tslint:disable-next-line:no-big-function
  public render(): JSX.Element {
    return (
      <div className="search-box-wrapper">
        <input
          type="text"
          placeholder={localizedStrings.searchBoxPlaceholder}
          value={this.state.queryText}
          onChange={this.handleChange}
          onKeyPress={this.handleKeyPress}
          className="search-box"
        />
        <div className="icons">
          <a
            role="button"
            className={`icon-Close icon ${this.getClearButtonVisibilityClass()}`}
            onClick={this.handleClearSearchText}
          />
          <a
            role="button"
            className="icon-Search icon"
            onClick={this.raiseOnSearch}
          />
        </div>
      </div>
    );
  }

  public componentDidUpdate(prevProps: Readonly<ISearchBoxProps>): void {
    if (prevProps.queryText !== this.props.queryText) {
      const queryText = getStateQueryText(this.props);
      this.updateValue(queryText);
    }
  }

  private readonly handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.updateValue(event.currentTarget.value);
  }

  private readonly handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      this.raiseOnSearch();
    }
  }

  private updateValue(queryText: string): void {
    this.setState({
      queryText,
    });
  }

  private readonly raiseOnSearch = (): void => {
    const { queryText } = this.state;
    if (queryText !== '') {
      this.props.onSearch(queryText);
    }
  }

  private readonly getClearButtonVisibilityClass = (): string =>
    this.state.queryText !== '' ? 'visible' : ''

  private readonly handleClearSearchText = (): void => {
    this.setState({
      queryText: '',
    });
    this.props.onClearSearch();
  }
}
