/* tslint:disable:match-default-export-name */
import docx from '../../../images/fileTypes/docx.svg';
import dotx from '../../../images/fileTypes/dotx.svg';
import file from '../../../images/fileTypes/genericfile.svg';
import url from '../../../images/fileTypes/link.svg';
import mpp from '../../../images/fileTypes/mpp.svg';
import pdf from '../../../images/fileTypes/pdf.svg';
import jpg from '../../../images/fileTypes/photo.svg';
import pptx from '../../../images/fileTypes/pptx.svg';
import vsdx from '../../../images/fileTypes/vsdx.svg';
import xlsx from '../../../images/fileTypes/xlsx.svg';
import xltx from '../../../images/fileTypes/xltx.svg';
import zip from '../../../images/fileTypes/zip.svg';
/* tslint:enable:match-default-export-name */
import { IDictionary } from '../../../lib/shared/IDictionary';

const icons: IDictionary<string> = {
  doc: docx,
  docm: docx,
  docx,
  dot: dotx,
  dotx,
  jpg,
  lnk: url,
  mpp,
  pdf,
  ppt: pptx,
  pptx,
  url,
  vsd: vsdx,
  vsdx,
  xls: xlsx,
  xlsm: xlsx,
  xlsx,
  xltm: xltx,
  xltx,
  zip,
};

const defaultIcon = file;

export function getIconSrcByFileType(fileType: string | undefined): string {
  let lowerCaseFileType = '';
  if (typeof fileType === 'string') {
    lowerCaseFileType = fileType.toLocaleLowerCase();
  }
  return icons.hasOwnProperty(lowerCaseFileType) ? icons[lowerCaseFileType] : defaultIcon;
}
