import { createAsyncAction } from 'typesafe-actions';
import { ITermSetInfo } from '../lib/taxonomy/ITermSetInfo';
import { ILoadTermDescriptionParameters } from './ILoadTermDescriptionParameters';
import { ITermDescription } from './ITermDescription';

export const loadTermSet = createAsyncAction(
  'LOAD_TERMSET_REQUEST',
  'LOAD_TERMSET_SUCCESS',
  'LOAD_TERMSET_FAILURE',
)<string, ITermSetInfo, string>();

export const loadTermDescription = createAsyncAction(
  'LOAD_TERM_DESCRIPTION_REQUEST',
  'LOAD_TERM_DESCRIPTION_SUCCESS',
  'LOAD_TERM_DESCRIPTION_FAILURE',
)<ILoadTermDescriptionParameters, ITermDescription, string>();
