import { ITaxonomyTerm } from '../../../../lib/taxonomy/ITaxonomyTerm';
import { ITermSetInfo } from '../../../../lib/taxonomy/ITermSetInfo';
import { getTermHierarchy, getTermLabel } from '../../../../lib/taxonomy/Taxonomy';

export function getChipLabel(term: ITaxonomyTerm, termSet: ITermSetInfo, showHierarchyInLabel: boolean): string {
  if (!showHierarchyInLabel) {
    return getTermLabel(term);
  }

  const terms = getTermHierarchy(termSet, term.id);
  const labels = terms.map(getTermLabel);
  if (labels.length > 1) {
      return labels.slice(1)
                   .join(':');
  } else {
      return labels.join(':');
  }
}
