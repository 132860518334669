import React from 'react';
import { Link } from 'react-router-dom';
import { houseRoute } from '../../entities/Routs';

export class BreadcrumbHouse extends React.PureComponent {

  // tslint:disable-next-line:prefer-function-over-method
  public render(): JSX.Element {
    return (
      <Link to={houseRoute} className="breadcrumb-house-link">
        <span className="icon-Processes" />
      </Link>
    );
  }
}
