import { IconButton, Link, TableCell, TableRow, Typography } from '@material-ui/core';
// tslint:disable-next-line: match-default-export-name no-submodule-imports
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import * as React from 'react';
import { DocumentTag } from './DocumentTag';
import { IDocumentRowProps } from './IDocumentRowProps';

export class DocumentRow extends React.PureComponent<IDocumentRowProps> {

  // tslint:disable-next-line:no-big-function
  public render(): JSX.Element {
    const { title, documentType, tags, languages, iconSrc, fileType, link } = this.props.document;
    const languagesText = languages.join(', ');
    const selectedRowClassName = this.props.selected ? 'selected' : undefined;
    return (
      <TableRow className={`document-row ${selectedRowClassName}`}>
        <TableCell align="left" className="document-info-cell">
          <IconButton
            aria-label="Show document details"
            className="document-info-button"
            onClick={this.handleInfoClick}
          >
            <InfoOutlinedIcon />
          </IconButton>
        </TableCell>
        <TableCell align="left" className="document-icon-cell">
          <img src={iconSrc} alt={fileType} className="document-icon" title={fileType} />
        </TableCell>
        <TableCell align="left" className="document-title-cell" component="th" scope="row">
          <Link className="document-title-link" href={link} target="_blank" onClick={this.handleLinkClick}>
            <Typography className="document-title-link-text">{title}</Typography>
          </Link>
          <div className="document-title-cell-content">
            <Typography variant="caption" className="document-directive-text">{documentType}</Typography>
            <Typography variant="caption" className="document-languages-text">{languagesText}</Typography>
          </div>
        </TableCell>
        <TableCell align="left" className="document-type-cell">
          <Typography>{documentType}</Typography>
        </TableCell>
        <TableCell align="left" className="document-tags-cell">
          {tags.map(tag => <DocumentTag key={tag} title={tag} />)}
        </TableCell>
        <TableCell align="left" className="document-language-cell">
          <Typography>{languagesText}</Typography>
        </TableCell>
      </TableRow>
    );
  }

  private readonly handleInfoClick = (): void => {
    this.props.onToggleSelect(this.props.document);
  }

  private readonly handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
    event.stopPropagation();
  }
}
