import { connect } from 'react-redux';
import { toggleFiltersPanel } from '../../../search/SearchActions';
import { RootState } from '../../../store/types/RootState';
import { DocumentFilters } from './DocumentFilters';
import { IDocumentFiltersDispatchProps } from './IDocumentFiltersDispatchProps';
import { IDocumentFiltersStateProps } from './IDocumentFiltersStateProps';

const mapStateToProps = (state: RootState): IDocumentFiltersStateProps => ({
  isFilterPanelExpanded: state.search.isFiltersPanelExpanded,
});

const dispatchProps: IDocumentFiltersDispatchProps = {
  onTogglePanel: toggleFiltersPanel,
};

const documentFiltersContainer = connect(
  mapStateToProps,
  dispatchProps,
)(DocumentFilters);

export {
  documentFiltersContainer as DocumentFiltersContainer,
};
