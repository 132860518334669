// tslint:disable:no-console
// We keep it for a while to force unregistering the service-worker on client
// already having it.
export function unregister(): void {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then(registration => {
        registration.unregister()
          .catch((reason: string) => {
            console.error(reason);
          });
      })
      .catch((reason: string) => {
        console.error(reason);
      });
  }
}
