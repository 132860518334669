import { Typography } from '@material-ui/core';
import React from 'react';
import { localizedStrings } from '../../../localization/LocalizedStrings';
import { FilterChipsContainer } from '../chips/FilterChipsContainer';
import { DocumentFiltersContainer } from '../filters/DocumentFiltersContainer';
import { SortButtonContainer } from '../sortButton/SortButtonContainer';
import { SortMenuContainer } from '../sortMenu/SortMenuContainer';
import { Documents } from './Documents';
import './DocumentsSearchResult.scss';
import { IDocumentsSearchResultProps } from './IDocumentsSearchResultProps';

export class DocumentsSearchResult extends React.PureComponent<IDocumentsSearchResultProps> {

  public render(): JSX.Element {
    const { result, selectedDocumentInfo, onToggleSelect } = this.props;
    return (
      <div className="documents-search-results-container">
        <DocumentFiltersContainer />
        <div className="buttons-wrapper">
          <div className="filter-chips-wrapper">
            <FilterChipsContainer />
          </div>
          <SortButtonContainer />
        </div>
        {this.getNotAllResultsMessage()}
        <Documents
          documents={result.documents}
          selectedDocumentInfo={selectedDocumentInfo}
          onToggleSelect={onToggleSelect}
        />
        <SortMenuContainer />
      </div>
    );
  }

  private getNotAllResultsMessage(): JSX.Element {
    const { total, documents } = this.props.result;
    return total > documents.length
      ? <Typography className="not-all-messages">{localizedStrings.notAllResults}</Typography>
      : <></>;
  }
}
