import { parse } from 'query-string';
import * as React from 'react';
import { connect } from 'react-redux';
import { config } from '../../config/Config';
import { areArraysEqual } from '../../lib/utils/Array';
import { RootState } from '../../store/types/RootState';
import { loadTermSet as loadTermSetAction } from '../../termSets/TermSetActions';
import { getTermSetByIdFromState } from '../../termSets/TermSetUtils';
import { ISearchUrlParameters } from '../location/ISearchUrlParameters';
import { resetSearchParameters, showSpinner as showSpinnerAction, updateStateFromUrl } from '../SearchActions';
import { ISearchPageContainerDispatchProps } from './ISearchPageContainerDispatchProps';
import { ISearchPageContainerProps } from './ISearchPageContainerProps';
import { ISearchPageContainerStateProps } from './ISearchPageContainerStateProps';
import { SearchPage } from './SearchPage';

class SearchPageContainer extends React.PureComponent<ISearchPageContainerProps> {

  public render(): JSX.Element {
    return (
      <SearchPage hideSearchBox={this.props.hideSearchBox} />
    );
  }

  public componentDidMount(): void {
    const { loadTermSet, termSet, showSpinner, updatedStateFromUrl } = this.props;
    loadTermSet(config.predefinedFilters.businessUnit.termSetId);
    if (termSet.terms.length === 0) {
      showSpinner();
    }
    updatedStateFromUrl(true);
  }

  public componentDidUpdate(prevProps: ISearchPageContainerProps): void {
    const triggerSearch = this.isSearchRequired(prevProps);
    this.props.updatedStateFromUrl(triggerSearch);
  }

  public componentWillUnmount(): void {
    this.props.onUnmounting();
  }

  // tslint:disable-next-line: cyclomatic-complexity mccabe-complexity
  private isSearchRequired(prevProps: ISearchPageContainerProps): boolean {
    if (prevProps.location === undefined || this.props.location === undefined) {
      return false;
    }
    if (this.props.termSet !== prevProps.termSet) {
      return true;
    }
    const parameters = parse(this.props.location.search) as ISearchUrlParameters;
    const prevParameters = parse(prevProps.location.search) as ISearchUrlParameters;
    // detect if changes was in parameters that trigger search
    return parameters.bu !== prevParameters.bu
      || parameters.country !== prevParameters.country
      || parameters.process !== prevParameters.process
      || parameters.lang !== prevParameters.lang
      || parameters.query !== prevParameters.query
      || parameters.sortBy !== prevParameters.sortBy
      || parameters.sortDir !== prevParameters.sortDir
      || this.doRefinerFiltersHaveChanges(parameters.refFilters, prevParameters.refFilters);
  }

  // tslint:disable-next-line: cyclomatic-complexity mccabe-complexity cognitive-complexity prefer-function-over-method
  private doRefinerFiltersHaveChanges(filters?: string[] | string, prevFilters?: string[] | string): boolean {
    if (filters === undefined && prevFilters === undefined) {
      return false;
    }
    if (filters === undefined || prevFilters === undefined) {
      return true;
    }
    if (typeof filters === 'string' && typeof prevFilters === 'string') {
      return filters !== prevFilters;
    }
    if (typeof filters === 'string' || typeof prevFilters === 'string') {
      return true;
    }
    return !areArraysEqual(filters, prevFilters);
  }
}

const mapStateToProps = (state: RootState): ISearchPageContainerStateProps => ({
  termSet: getTermSetByIdFromState(state, config.predefinedFilters.businessUnit.termSetId),
});

const dispatchProps: ISearchPageContainerDispatchProps = {
  loadTermSet: loadTermSetAction.request,
  onUnmounting: resetSearchParameters,
  showSpinner: showSpinnerAction,
  updatedStateFromUrl: updateStateFromUrl,
};

const searchPageContainer = connect(
  mapStateToProps,
  dispatchProps,
)(SearchPageContainer);

export {
  searchPageContainer as SearchPageContainer,
};
