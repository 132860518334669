import { CircularProgress } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { ErrorMessage } from '../../../components/errorMessage/ErrorMessage';
import { RootState } from '../../../store/types/RootState';
import { doesUrlContainParameters } from '../../location/SearchLocation';
import { selectDocument } from '../../SearchActions';
import { DocumentsSearchResult } from './DocumentsSearchResult';
import './DocumentsSearchResultContainer.scss';
import { IDocumentsSearchResultContainerProps } from './IDocumentsSearchResultContainerProps';
import { IDocumentsSearchResultDispatchProps } from './IDocumentsSearchResultDispatchProps';
import { IDocumentsSearchResultStateProps } from './IDocumentsSearchResultStateProps';

class DocumentsSearchResultContainer extends React.PureComponent<IDocumentsSearchResultContainerProps> {

  private isRendered: boolean = false;

  public render(): JSX.Element | null {
    // tslint:disable-next-line:no-null-keyword
    return doesUrlContainParameters() ? this.getSearchResultElement() : null;
  }

  // tslint:disable-next-line: no-big-function
  private getSearchResultElement(): JSX.Element {
    const { onToggleSelect, result, selectedDocumentInfo, errorMessage } = this.props;
    if (result.documents.length === 0) {
      if (errorMessage !== '') {
        return <ErrorMessage message={errorMessage} />;
      }
      if (this.isLoadingIndicatorVisible()) {
        return (
          <div className="progress-container">
            <CircularProgress className="progress" />
          </div>
        );
      }
    }
    this.isRendered = true;
    return (
      <DocumentsSearchResult
        result={result}
        selectedDocumentInfo={selectedDocumentInfo}
        onToggleSelect={onToggleSelect}
      />
    );
  }

  private isLoadingIndicatorVisible(): boolean {
    return this.props.isLoading && !this.isRendered;
  }
}

const mapStateToProps = (state: RootState): IDocumentsSearchResultStateProps => ({
  errorMessage: state.search.errorMessage,
  isLoading: state.search.isLoading,
  queryText: state.search.queryText,
  result: state.search.result,
  selectedDocumentInfo: state.search.selectedDocumentInfo,
});

const dispatchProps: IDocumentsSearchResultDispatchProps = {
  onToggleSelect: document => selectDocument({
    listId: document.listId,
    listItemId: document.listItemId,
  }),
};

const documentsSearchResultContainer = connect(
  mapStateToProps,
  dispatchProps,
)(DocumentsSearchResultContainer);

export {
  documentsSearchResultContainer as DocumentsSearchResultContainer,
};
