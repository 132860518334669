import React from 'react';
import { connect } from 'react-redux';
import { config } from '../../config/Config';
import { houseConfig } from '../../config/HouseConfig';
import { RootState } from '../../store/types/RootState';
import { loadTermSet } from '../../termSets/TermSetActions';
import { getTermSetByIdFromState } from '../../termSets/TermSetUtils';
import { getPredefinedUrlParameters } from '../housePage/HousePageUtils';
import { Floor2 } from './Floor2';
import { IFloor2ContainerDispatchProps } from './IFloor2ContainerDispatchProps';
import { IFloor2ContainerStateProps } from './IFloor2ContainerStateProps';

class Floor2Container extends React.PureComponent<IFloor2ContainerDispatchProps & IFloor2ContainerStateProps> {

  public componentDidMount(): void {
    this.props.onLoadTermSet(config.predefinedFilters.scopeOfService.termSetId);
    this.props.onLoadTermSet(houseConfig.termSetId);
  }

  public render(): JSX.Element {
    const {
      businessUnitTermId,
      countryTermId,
      languageTermIds,
      scopeOfServiceTermId,
      scopeOfServiceTermSet,
      houseTermSet } = this.props;
    const urlParameters = getPredefinedUrlParameters(businessUnitTermId, countryTermId, languageTermIds);
    return (
      <Floor2
        predefinedUrlParameters={urlParameters}
        configurationTermSet={scopeOfServiceTermSet}
        configurationTermId={scopeOfServiceTermId}
        navigationTermSet={houseTermSet}
        navigationTermPropertyName={houseConfig.floor2.termCustomProperty}
      />
    );
  }
}

const mapStateToProps = (state: RootState): IFloor2ContainerStateProps => ({
  businessUnitTermId: state.preferences.businessUnitTermId,
  countryTermId: state.preferences.countryTermId,
  houseTermSet: getTermSetByIdFromState(state, houseConfig.termSetId),
  languageTermIds: state.preferences.languageTermIds,
  scopeOfServiceTermId: state.preferences.scopeOfServiceTermId,
  scopeOfServiceTermSet: getTermSetByIdFromState(state, config.predefinedFilters.scopeOfService.termSetId),
});

const dispatchProps: IFloor2ContainerDispatchProps = {
  onLoadTermSet: loadTermSet.request,
};

const floor2Container = connect(
  mapStateToProps,
  dispatchProps,
)(Floor2Container);

export {
  floor2Container as Floor2Container,
};
