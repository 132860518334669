import { findPlaceholderOccurrences } from './Finder';
import { IPlaceholderOccurrence } from './IPlaceholderOccurrence';

export function replacePlaceholder(inputString: string, placeholderName: string, placeholderValue: string): string {
  const occurrences: IPlaceholderOccurrence[] = findPlaceholderOccurrences(inputString, placeholderName);
  return occurrences.reduce(
    (processedString, occurrence) => replacePlaceholderOccurrence(processedString, occurrence, placeholderValue),
    inputString,
  );
}

function replacePlaceholderOccurrence(inputString: string, occurrence: IPlaceholderOccurrence, value: string): string {
  return inputString.replace(occurrence.placeholderMatch, value);
}
