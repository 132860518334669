import { getTermDescription, getTermSetById, getTermStoreDefaultLcid } from '../lib/taxonomy/CachedTaxonomy';
import { ITermSetInfo } from '../lib/taxonomy/ITermSetInfo';
import { RootState } from '../store/types/RootState';
import { ITermDescription } from './ITermDescription';

export async function ensureTermSetUsingState(state: RootState, id: string): Promise<ITermSetInfo | undefined> {
  const existingTermSet = state.termSets.find(termSet => termSet.id === id);
  return existingTermSet === undefined ? getTermSetById(id) : existingTermSet;
}

export async function ensureTermDescription(
  state: RootState,
  termSetId: string,
  termId: string,
  lcid?: number,
): Promise<ITermDescription> {
  const existingTermDescription = state.termDescriptions.find(description => description.termId === termId);
  if (existingTermDescription !== undefined) {
    return existingTermDescription;
  }
  const resolvedLcid = lcid === undefined ? await getTermStoreDefaultLcid() : lcid;
  const descriptionValue = await getTermDescription(termSetId, termId, resolvedLcid);
  return {
    isDefault: lcid === undefined,
    lcid: resolvedLcid,
    termId,
    value: descriptionValue,
  };
}
