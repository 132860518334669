export function copyArray<TItem>(array: TItem[]): TItem[] {
  const newArray: TItem[] = [];
  for (let i = 0; i < array.length; i++) {
    newArray[i] = array[i];
  }
  return newArray;
}

export function uniqueItems<TItem>(items: TItem[]): TItem[] {
  for (let i = 0; i < items.length; ++i) {
    removeItemFromArray(items, items[i], i + 1);
  }
  return items;
}

export function areArraysEqual<T>(array1: T[], array2: T[]): boolean {
  return array1.length === array2.length
    ? !array1.some((item, index) => array2[index] !== item)
    : false;
}

function removeItemFromArray<TItem>(items: TItem[], item: TItem, startIndex: number): void {
  for (let i = startIndex; i < items.length; ++i) {
    if (item === items[i]) {
      items.splice(i, 1);
    }
  }
}
