import { Web } from '@pnp/sp';
import { sp } from '@pnp/sp-addinhelpers';
import { IUrlFieldValue } from './IUrlFieldValue';

export async function getListItems<T>(
  webUrl: string,
  listId: string,
  ids: number[],
  selects: string[],
  expands: string[],
): Promise<T[]> {
  if (ids.length === 0) {
    return [];
  }
  const batch = sp.createBatch();
  const listItems = new Web(webUrl)
    .lists
    .getById(listId)
    .items;
  const itemPromises: Promise<T>[] = ids.map(async id =>
    listItems
      .getById(id)
      .select(...selects)
      .expand(...expands)
      .inBatch(batch)
      .get(),
  );
  await batch.execute();
  return Promise.all(itemPromises);
}

export async function getListItem<T>(
  webUrl: string,
  listId: string,
  id: number,
  selects: string[],
  expands: string[],
): Promise<T> {
  return new Web(webUrl)
    .lists
    .getById(listId)
    .items
    .getById(id)
    .select(...selects)
    .expand(...expands)
    .get();
}

export function getMetaInfoValue(metaInfoValue: string, fieldName: string): string | undefined {
  const valueRegExp = new RegExp(`${fieldName}:SW\\|(.*?)\\r\\n`, 'ig');
  const matches = valueRegExp.exec(metaInfoValue);
  const valueGroupIndex = 1;
  return matches !== null && matches.length > 1 ? matches[valueGroupIndex] : undefined;
}

export function parseUrlFieldValue(urlValue: string): IUrlFieldValue {
  const urlIndex = 0;
  const titleIndex = 1;
  const parts = urlValue.split(', ');
  return {
    title: parts[titleIndex],
    url: parts[urlIndex],
  };
}
