import { Typography } from '@material-ui/core';
import React from 'react';
import { IHouseTitleProps } from './IHouseTitleProps';

export class HouseTitle extends React.PureComponent<IHouseTitleProps> {

  public render(): JSX.Element {
    const { title } = this.props;
    return (
      <div className="house-header-title">
        <Typography className="house-header-title-text" component="span">{title}</Typography>
      </div>
    );
  }
}
