import React from 'react';
// tslint:disable-next-line:no-submodule-imports
import { PlaceholderProps } from 'react-select/lib/components/Placeholder';

export class Placeholder<TOption> extends React.PureComponent<PlaceholderProps<TOption>> {

  // tslint:disable-next-line:prefer-function-over-method
  public render(): JSX.Element | null {
    // tslint:disable-next-line:no-null-keyword
    return null;
  }
}
