import React from 'react';
import { ITermSetInfo } from '../../lib/taxonomy/ITermSetInfo';
import { findTermById } from '../../lib/taxonomy/Taxonomy';
import { Floor2Container } from './Floor2Container';
import { Floor2HeaderContainer } from './Floor2HeaderContainer';

export function getNavigationTermId(
  configurationTermId: string,
  configurationTermSet: ITermSetInfo,
  navigationTermPropertyName: string,
): string | undefined {
  const configurationTerm = findTermById(configurationTermSet, configurationTermId);
  return configurationTerm !== undefined
    ? configurationTerm.customProperties[navigationTermPropertyName]
    : undefined;
}

export function addFloor2(floors: JSX.Element[]): JSX.Element[] {
  const floorHeader = <Floor2HeaderContainer key="floor2-header" />;
  const floorContents = <Floor2Container key="floor2" />;
  return [...floors, floorHeader, floorContents];
}
