import { Button } from '@material-ui/core';
import * as React from 'react';
import { houseRoute } from '../../entities/Routs';
import { history } from '../../History';
import { localizedStrings } from '../../localization/LocalizedStrings';
import { IHousePageLinkProps } from './IHousePageLinkProps';

export class HousePageLink extends React.PureComponent<IHousePageLinkProps> {

  public render(): JSX.Element {
    return this.props.hidden ? <></> : this.getLinkElement();
  }

  private getLinkElement(): JSX.Element {
    return (
      <Button className="open-house-button" variant="contained" onClick={this.handleHouseRedirect}>
        {localizedStrings.goToHousePage}
      </Button>
    );
  }

  private readonly handleHouseRedirect = (): void => {
    history.push(houseRoute);
  }
}
