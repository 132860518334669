import { replaceSearchPlaceholder } from '../../../lib/search/SearchPlaceholderReplacers';
import { docLanguageMp } from './DocumentsSearchManagedProperties';
import {
  businessUnitWithParentsPlaceholder,
  countryPlaceholder,
  languagesPlaceholder,
  processTypePlaceholder,
} from './DocumentsSearchPlaceholders';

export function replaceDocumentPlaceholders(
  query: string,
  processType?: string,
  businessUnit?: string,
  country?: string,
  languageTerms?: string[] | undefined,
): string {
  let processedQuery = replaceTaxonomyValue(query, processTypePlaceholder, processType);
  processedQuery = replaceSearchPlaceholder(processedQuery, businessUnitWithParentsPlaceholder, businessUnit);
  processedQuery = replaceTaxonomyValue(processedQuery, countryPlaceholder, country);
  processedQuery = replaceTaxonomyValues(processedQuery, languagesPlaceholder, docLanguageMp, ' OR ', languageTerms);
  return processedQuery;
}

function replaceTaxonomyValue(query: string, placeholder: string, termId: string | undefined): string {
  const value = createTaxonomyValue(termId);
  return replaceSearchPlaceholder(query, placeholder, value);
}

function replaceTaxonomyValues(
  query: string,
  placeholder: string,
  managedPropertyName: string,
  separator: string,
  termIds: string[] | undefined,
): string {
  const value = createTaxonomyValues(termIds, managedPropertyName, separator);
  return replaceSearchPlaceholder(query, placeholder, value);
}

function createTaxonomyValue(termId: string | undefined): string {
  return termId !== undefined && termId !== '' ? `GP0|#${termId}` : '';
}

function createTaxonomyValues(termIds: string[] | undefined, managedPropertyName: string, separator: string): string {
  if (termIds === undefined || termIds.length === 0) {
    return '';
  }

  const value = termIds
    .map(termId => {
      const taxvalue = createTaxonomyValue(termId);
      return `${managedPropertyName}:${taxvalue}`;
    })
    .join(separator);

  return `(${value})`;
}
