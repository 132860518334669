import { combineReducers } from 'redux';
import { getType } from 'typesafe-actions';
import { config } from '../config/Config';
import { IMenuState } from './IMenuState';
import * as actions from './MenuActions';
import { toggleVisibility } from './MenuUtils';
import { MenuAction } from './types/MenuAction';

function getTouched(touched: boolean = false, action: MenuAction): boolean {
  return action.type === getType(actions.toggleVisibility) ? true : touched;
}

function getVisible(
  visible: boolean = window.innerWidth > config.screenSizes.landscapeScreenSize,
  action: MenuAction,
): boolean {
  if (action.type === getType(actions.toggleVisibility)) {
    return toggleVisibility(visible, action.payload);
  }
  if (action.type === getType(actions.clickOnMenuItem)) {
    return window.innerWidth < config.screenSizes.tabletScreenSize ?
      toggleVisibility(visible, action.payload) : visible;
  }
  return visible;
}

export const menuReducer = combineReducers<IMenuState, MenuAction>({
  touched: getTouched,
  visible: getVisible,
});
