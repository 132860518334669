import { TableCell, TableSortLabel, Typography } from '@material-ui/core';
import * as React from 'react';
import { SortDirection } from '../../lib/entities/SortDirection';
import { localizedStrings } from '../../localization/LocalizedStrings';
import { IHeadCellProps } from './IHeadCellProps';

export class HeadCell extends React.PureComponent<IHeadCellProps> {

  public render(): JSX.Element {
    const { sortable } = this.props;
    return (
      <TableCell className={this.props.className} align="left" padding="none">
        {sortable ? this.getSortableCellContent() : this.getCellContent()}
      </TableCell>
    );
  }

  private getSortableCellContent(): JSX.Element {
    const { sortDirection } = this.props;
    return (
      <TableSortLabel
        active={sortDirection !== undefined}
        direction={sortDirection}
        onClick={this.handleOnSort}
        title={localizedStrings.sortTooltip}
      >
        {this.getCellContent()}
      </TableSortLabel>
    );
  }

  private getCellContent(): JSX.Element {
    const { label } = this.props;
    return <Typography variant="subtitle2">{label}</Typography>;
  }

  private readonly handleOnSort = (): void => {
    const { sortable, sortProperty, sortDirection, onSort } = this.props;
    if (sortable && sortProperty !== undefined) {
      if (sortDirection === undefined) {
        onSort({
          direction: SortDirection.ascending,
          propertyName: sortProperty,
        });
        return;
      }
      if (sortDirection === SortDirection.ascending) {
        onSort({
          direction: SortDirection.descending,
          propertyName: sortProperty,
        });
        return;
      }
      onSort(undefined);
    }
  }
}
