import LocalizedStrings from 'react-localization';
import deStrings from './de.json';
import enStrings from './en.json';
import frStrings from './fr.json';
import itStrings from './it.json';
import noStrings from './no.json';
import svStrings from './sv.json';

export const localizedStrings: typeof enStrings = new LocalizedStrings({
  en: enStrings,
  // tslint:disable-next-line:object-literal-sort-keys
  de: deStrings,
  fr: frStrings,
  it: itStrings,
  no: noStrings,
  nb: noStrings,
  nn: noStrings,
  sv: svStrings,
});
