import { FormControl } from '@material-ui/core';
import * as React from 'react';
// tslint:disable-next-line:no-submodule-imports
import { Option } from 'react-select/lib/filters';
// tslint:disable-next-line:no-submodule-imports
import { ActionMeta, ValueType } from 'react-select/lib/types';
import { ISelectOption } from '../../components/select/ISelectOption';
import { SearchableSelect } from '../../components/select/SearchableSelect';
import { SortDirection } from '../../lib/entities/SortDirection';
import { IDocumentRefinementFilter } from '../documents/services/IDocumentRefinementFilter';
import { IRefinerDispatchProps } from './IRefinerDispatchProps';
import { IRefinerItem } from './IRefinerItem';
import { IRefinerOwnProps } from './IRefinerOwnProps';
import { convertToOption } from './RefinerUtils';

export class Refiner extends React.PureComponent<IRefinerOwnProps & IRefinerDispatchProps> {

  public render(): JSX.Element {
    const { label } = this.props;
    const sortedItems = this.getOptions();
    const selectedOption = this.getSelectedOption();
    const value = selectedOption !== undefined ? [selectedOption] : [];
    return (
      <FormControl className="refiner-wrapper">
        <SearchableSelect
          options={sortedItems}
          value={value}
          onChange={this.handleChange}
          label={label}
          escapeClearsValue={true}
          filterOption={this.filterRefiners}
        />
      </FormControl>
    );
  }

  private readonly handleChange = (valueType: ValueType<ISelectOption>, actionMeta: ActionMeta) => {
    let item: IRefinerItem | undefined;
    if (valueType !== undefined && valueType !== null) {
      item = this.props.items.find(i => i.token === (valueType as ISelectOption).value);
    }
    this.raiseOnChange(item);
  }

  private readonly filterRefiners = (option: Option, rawInput: string): boolean => {
    const label = option.label.toLowerCase();
    const input = rawInput.toLowerCase();
    return label.indexOf(input) > -1;
  }

  private raiseOnChange(selectedRefinerItem: IRefinerItem | undefined): void {
    const { name, onChange } = this.props;
    const refinementFilter: IDocumentRefinementFilter = {
      name,
      values: [],
    };
    if (selectedRefinerItem !== undefined) {
      const { label, termId, token, showTermHierarchy } = selectedRefinerItem;
      refinementFilter.values.push({
        hierarchy: showTermHierarchy ? showTermHierarchy : undefined,
        label: termId === undefined ? label : undefined,
        termId,
        token,
      });
    }
    onChange(refinementFilter);
  }

  private getSelectedOption(): ISelectOption | undefined {
    const { selectedToken, items } = this.props;
    const item = items.find(i => i.token === selectedToken);
    return item !== undefined ? convertToOption(item) : undefined;
  }

  private getOptions(): ISelectOption[] {
    const { items, sortDirection } = this.props;
    const options = items.map(convertToOption);
    if (sortDirection === undefined) {
      return options;
    }
    const sortedOptions = [...options].sort((item1, item2) => item1.label.localeCompare(item2.label));
    return sortDirection === SortDirection.ascending ? sortedOptions : sortedOptions.reverse();
  }
}
