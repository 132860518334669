import { connect } from 'react-redux';
import * as menuActions from '../../menu/MenuActions';
import { store } from '../../store/Store';
import { IMenuButtonProps } from './IMenuButtonProps';
import { MenuButton } from './MenuButton';

const dispatchProps: IMenuButtonProps = {
  onToggleButtonClick: () => menuActions.toggleVisibility(store.getState().menu.touched),
};

const menuButtonContainer = connect(
  undefined,
  dispatchProps,
)(MenuButton);

export {
  menuButtonContainer as MenuButtonContainer,
};
