import React from 'react';
import { HouseIconLink } from '../common/HouseIconLink';
import { IFloorTerm } from '../common/IFloorTerm';

export class FloorItem extends React.PureComponent<IFloorTerm> {

  public render(): JSX.Element {
    const { label, redirectUrl, icon, columnClass } = this.props;
    const className = `${columnClass} icon-grp`;

    return (
      <div className={className}>
        <HouseIconLink title={label} url={redirectUrl} icon={icon} />
      </div>
    );
  }
}
