import { combineReducers } from 'redux';
import { config } from '../config/Config';
import { getTaxonomyPreference, getTaxonomyPreferences } from './common/taxonomy/TaxonomyReducerUtils';
import { IPreferencesState } from './IPreferencesState';
import { PreferencesAction } from './types/PreferencesAction';

export const preferencesReducer = combineReducers<IPreferencesState, PreferencesAction>({
  businessUnitTermId: (state, action) => getTaxonomyPreference(state, action, config.predefinedFilters.businessUnit),
  countryTermId: (state, action) => getTaxonomyPreference(state, action, config.predefinedFilters.country),
  languageTermIds: (state, action) => getTaxonomyPreferences(state, action, config.predefinedFilters.languages),
  scopeOfServiceTermId:
    (state, action) => getTaxonomyPreference(state, action, config.predefinedFilters.scopeOfService),
});
