import * as React from 'react';
import { connect } from 'react-redux';
import { TaxonomySelector } from '../../components/taxonomySelector/TaxonomySelector';
import { config } from '../../config/Config';
import { localizedStrings } from '../../localization/LocalizedStrings';
import { RootState } from '../../store/types/RootState';
import { loadTermSet } from '../../termSets/TermSetActions';
import { getTermSetByIdFromState } from '../../termSets/TermSetUtils';
import { ITaxonomyMultiPreferenceDispatchProps } from '../common/taxonomy/ITaxonomyMultiPreferenceDispatchProps';
import { ITaxonomyMultiPreferenceProps } from '../common/taxonomy/ITaxonomyMultiPreferenceProps';
import { ITaxonomyMultiPreferenceStateProps } from '../common/taxonomy/ITaxonomyMultiPreferenceStateProps';
import { setPreferences } from '../PreferencesActions';

class LanguagesPredefinedFilterContainer extends React.PureComponent<ITaxonomyMultiPreferenceProps> {

  public componentDidMount(): void {
    this.props.onLoadTermSet(config.predefinedFilters.languages.termSetId);
  }

  public render(): JSX.Element {
    return (
      <TaxonomySelector
        inputId="languages-predefined-filter"
        collapsible={false}
        treeView={false}
        label={localizedStrings.languagePredefinedFilterPlaceholder}
        onMultipleChangeSelection={this.props.onMultipleChangeSelection}
        termSet={this.props.termSet}
        selectedIds={this.props.selectedIds}
        allowOnlyTags={true}
        hasEmptyMenuItem={false}
        multiSelect={true}
      />
    );
  }
}

const mapStateToProps = (state: RootState): ITaxonomyMultiPreferenceStateProps => ({
  selectedIds: state.preferences.languageTermIds,
  termSet: getTermSetByIdFromState(state, config.predefinedFilters.languages.termSetId),
});

const dispatchProps: ITaxonomyMultiPreferenceDispatchProps = {
  onLoadTermSet: loadTermSet.request,
  onMultipleChangeSelection: termIds => setPreferences({
    name: config.predefinedFilters.languages.cacheKey,
    values: termIds,
  }),
};

const languagesPredefinedFilterContainer = connect(
  mapStateToProps,
  dispatchProps,
)(LanguagesPredefinedFilterContainer);

export {
  languagesPredefinedFilterContainer as LanguagesPredefinedFilterContainer,
};
