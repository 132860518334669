import React from 'react';
import { connect } from 'react-redux';
import { SortDirection } from '../../lib/entities/SortDirection';
import { ITermSetInfo } from '../../lib/taxonomy/ITermSetInfo';
import { RootState } from '../../store/types/RootState';
import { loadTermSet } from '../../termSets/TermSetActions';
import { applyRefinementFilter } from '../SearchActions';
import { IRefinerContainerDispatchProps } from './IRefinerContainerDispatchProps';
import { IRefinerContainerOwnProps } from './IRefinerContainerOwnProps';
import { IRefinerContainerStateProps } from './IRefinerContainerStateProps';
import { Refiner } from './Refiner';
import { getLabel, getRefinerItems, getRefinerName, getSelectedToken, getTermSetId } from './RefinerUtils';

class RefinerContainer extends React.PureComponent<
    IRefinerContainerOwnProps &
    IRefinerContainerStateProps &
    IRefinerContainerDispatchProps
  > {

  public componentDidMount(): void {
    this.loadTermSet();
  }

  public render(): JSX.Element | undefined {
    const { refinement, refinementFilter, onChange } = this.props;
    const name = getRefinerName(refinement, refinementFilter);
    if (name === undefined) {
      return undefined;
    }
    const selectedToken = getSelectedToken(refinementFilter);
    const label = getLabel(refinement, refinementFilter);
    const termSet = this.getTermSet();
    const items = getRefinerItems(refinement, refinementFilter, termSet);
    return (
      <Refiner
        name={name}
        label={label}
        items={items}
        selectedToken={selectedToken}
        sortDirection={SortDirection.ascending}
        onChange={onChange}
      />
    );
  }

  private loadTermSet(): void {
    this.getTermSet(true);
  }

  private getTermSet(loadIfNotFound: boolean = false): ITermSetInfo | undefined {
    const { refinement, refinementFilter, termSets, onLoadTermSet } = this.props;
    const termSetId = getTermSetId(refinement, refinementFilter);
    if (termSetId !== undefined) {
      const loadedTermSet = termSets.find(termSet => termSet.id === termSetId);
      if (loadedTermSet === undefined && loadIfNotFound) {
        onLoadTermSet(termSetId);
      }
      return loadedTermSet;
    }
    return undefined;
  }
}

const mapStateToProps = (state: RootState): IRefinerContainerStateProps => ({
  termSets: state.termSets,
});

const dispatchProps: IRefinerContainerDispatchProps = {
  onChange: applyRefinementFilter,
  onLoadTermSet: loadTermSet.request,
};

const refinerContainer = connect(
  mapStateToProps,
  dispatchProps,
)(RefinerContainer);

export {
  refinerContainer as RefinerContainer,
};
