import { localizedStrings } from '../../../localization/LocalizedStrings';
import { ISortColumnConfig } from './ISortColumnConfig';

export const iconColumn: ISortColumnConfig = {
  sortProperty: 'ImplFileTypeSortable',
  title: localizedStrings.iconColumnName,
};

export const titleColumn: ISortColumnConfig = {
  sortProperty: 'Filename',
  title: localizedStrings.titleColumnName,
};

export const documentTypeColumn: ISortColumnConfig = {
  sortProperty: 'ImplDocumentType',
  title: localizedStrings.documentTypeColumnName,
};
