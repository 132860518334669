import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../store/types/RootState';
import { sort } from '../../SearchActions';
import { ISortMenuItemContainerDispatchProps } from './ISortMenuItemContainerDispatchProps';
import { ISortMenuItemContainerProps } from './ISortMenuItemContainerProps';
import { ISortMenuItemContainerStateProps } from './ISortMenuItemContainerStateProps';
import { SortMenuItem } from './SortMenuItem';

class SortMenuItemContainer extends React.PureComponent<ISortMenuItemContainerProps> {

  public render(): JSX.Element {
    const { label, sortProperty, stateSortProperty, stateSortDirection, onSort } = this.props;
    return (
      <SortMenuItem
        label={label}
        sortProperty={sortProperty}
        sortDirection={stateSortProperty === sortProperty ? stateSortDirection : undefined}
        onSort={onSort}
      />
    );
  }
}

const mapStateToProps = (state: RootState): ISortMenuItemContainerStateProps => ({
  stateSortDirection: state.search.sortDirection,
  stateSortProperty: state.search.sortBy,
});

const dispatchProps: ISortMenuItemContainerDispatchProps = {
  onSort: sort,
};

const sortMenuItemContainer = connect(
  mapStateToProps,
  dispatchProps,
)(SortMenuItemContainer);

export {
  sortMenuItemContainer as SortMenuItemContainer,
};
