import { IPlaceholderOccurrence } from './IPlaceholderOccurrence';

const placeholderMatchIndex: number = 0;

export function hasPlaceholders(queryText: string, placeholderNames: string[]): boolean {
  return queryText !== '' &&
    placeholderNames.some(name => findPlaceholderOccurrences(queryText, name).length > 0);
}

export function findPlaceholderOccurrences(inputString: string, placeholderName: string): IPlaceholderOccurrence[] {
  const regex: RegExp = new RegExp(`{${placeholderName}}`, 'g');
  const placeholders: IPlaceholderOccurrence[] = [];
  let execArray: RegExpExecArray | null = regex.exec(inputString);
  while (execArray !== null) {
    placeholders.push({
      placeholderMatch: execArray[placeholderMatchIndex],
      placeholderName,
    });
    execArray = regex.exec(inputString);
  }
  return placeholders;
}
