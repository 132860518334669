import { ISelectOption } from '../../components/select/ISelectOption';
import { config } from '../../config/Config';
import { IRefinement } from '../../lib/search/IRefinement';
import { IRefinementEntry } from '../../lib/search/IRefinementEntry';
import { ITaxonomyTerm } from '../../lib/taxonomy/ITaxonomyTerm';
import { ITermSetInfo } from '../../lib/taxonomy/ITermSetInfo';
import {
  findTermById,
  findTermByPath,
  getTermHierarchy,
  getTermLabel,
} from '../../lib/taxonomy/Taxonomy';
import { IDocumentRefinementFilter } from '../documents/services/IDocumentRefinementFilter';
import { IDocumentRefinementFilterValue } from '../documents/services/IDocumentRefinementFilterValue';
import { IRefinerItem } from './IRefinerItem';

const hierarchyTermSeparator = ':';

export function updateRefinementFilters(
  currentFilters: IDocumentRefinementFilter[],
  newFilter: IDocumentRefinementFilter,
): IDocumentRefinementFilter[] {
  const newFilters: IDocumentRefinementFilter[] = currentFilters.filter(filter => filter.name !== newFilter.name);
  if (newFilter.values.length > 0) {
    newFilters.push(newFilter);
  }
  return newFilters;
}

export function getTermSetId(
  refinement: IRefinement | undefined,
  refinementFilter: IDocumentRefinementFilter | undefined,
): string | undefined {
  const refinerConfig = getRefinerConfig(refinement, refinementFilter);
  return refinerConfig !== undefined ? refinerConfig.termSetId : undefined;
}

export function getLabel(
  refinement: IRefinement | undefined,
  refinementFilter: IDocumentRefinementFilter | undefined,
): string {
  const refinerConfig = getRefinerConfig(refinement, refinementFilter);
  return refinerConfig !== undefined ? refinerConfig.label : '';
}

export function getSelectedToken(refinementFilter: IDocumentRefinementFilter | undefined): string {
  return refinementFilter !== undefined ? refinementFilter.values[0].token : '';
}

export function getRefinerName(
  refinement: IRefinement | undefined,
  refinementFilter: IDocumentRefinementFilter | undefined,
): string | undefined {
  if (refinementFilter !== undefined) {
    return refinementFilter.name;
  }
  return refinement !== undefined ? refinement.refinerName : undefined;
}

export function getRefinerItems(
  refinement: IRefinement | undefined,
  refinementFilter: IDocumentRefinementFilter | undefined,
  termSet: ITermSetInfo | undefined,
): IRefinerItem[] {
  if (termSet !== undefined) {
    if (refinement !== undefined) {
      return refinement.entries.map(entry => getRefinerFilterItem(entry, termSet));
    }
    if (refinementFilter !== undefined) {
      return refinementFilter.values.map(value => getRefinerFilterItemFromFilter(value, termSet));
    }
  }
  return [];
}

export function convertToOption(item: IRefinerItem): ISelectOption {
  return {
    label: item.label,
    value: item.token,
  };
}

export function getRefinerItemLabel(
  termSet: ITermSetInfo,
  isHierarchy: boolean,
  termId: string | undefined,
  fallbackLabel: string | undefined,
): string {
  if (termId !== undefined) {
    const termLabel = getLocalizedTermLabel(termSet, termId, isHierarchy);
    if (termLabel !== undefined) {
      return termLabel;
    }
  }
  return fallbackLabel !== undefined ? fallbackLabel : '';
}

function getLocalizedTermLabel(termSet: ITermSetInfo, termId: string, isHierarchy: boolean): string | undefined {
  if (isHierarchy) {
    return getTermHierarchyLabel(termSet, termId);
  }
  const term = findTermById(termSet, termId);
  return term !== undefined ? getTermLabel(term) : undefined;
}

function getRefinerConfig(
  refinement: IRefinement | undefined,
  refinementFilter: IDocumentRefinementFilter | undefined,
): { label: string; name: string; termSetId: string } | undefined {
  const refinerName = getRefinerName(refinement, refinementFilter);
  return refinerName !== undefined ? config.search.refiners.find(refiner => refiner.name === refinerName) : undefined;
}

function getRefinerFilterItem(entry: IRefinementEntry, termSet: ITermSetInfo): IRefinerItem {
  const { name, token } = entry;
  const showTermHierarchy = isHierarchyValue(name);
  const term = getTermByRefinementEntryName(entry, termSet);
  const termId = term !== undefined ? term.id : undefined;
  const label = getRefinerItemLabel(termSet, showTermHierarchy, termId, name);
  return {
    label,
    showTermHierarchy,
    termId,
    token,
  };
}

function getRefinerFilterItemFromFilter(value: IDocumentRefinementFilterValue, termSet: ITermSetInfo): IRefinerItem {
  const { termId, label, hierarchy, token } = value;
  const showTermHierarchy = hierarchy !== undefined ? hierarchy : false;
  const refinerItemLabel = getRefinerItemLabel(termSet, showTermHierarchy, termId, label);
  return {
    label: refinerItemLabel,
    showTermHierarchy,
    termId,
    token,
  };
}

function getTermHierarchyLabel(termSet: ITermSetInfo, termId: string): string {
  const terms = getTermHierarchy(termSet, termId);
  // tslint:disable-next-line: no-unnecessary-callback-wrapper
  return terms.map(term => getTermLabel(term))
    .join(hierarchyTermSeparator);
}

function getTermByRefinementEntryName(entry: IRefinementEntry, termSet: ITermSetInfo): ITaxonomyTerm | undefined {
  const termPath = entry.name.split(hierarchyTermSeparator);
  return findTermByPath(termSet, termPath);
}

function isHierarchyValue(value: string): boolean {
  return value.indexOf(hierarchyTermSeparator) > -1;
}
