import * as React from 'react';
import { ITaxonomyTerm } from '../../lib/taxonomy/ITaxonomyTerm';
import { getTermHierarchy } from '../../lib/taxonomy/Taxonomy';
import { localizedStrings } from '../../localization/LocalizedStrings';
import { Breadcrumb } from '../../search/breadcrumb/Breadcrumb';
import { ISubProcessPageParams } from './ISubProcessPageParams';
import './SubProcessPage.scss';
import { TermTile } from './TermTile';

export class SubProcessPage extends React.PureComponent<ISubProcessPageParams> {

  public render(): JSX.Element {
    const { description, term, termChildren, termSet } = this.props;
    const terms = getTermHierarchy(termSet, term.id);
    return (
      <div>
        <Breadcrumb terms={terms} />
        <div className="tiles-container">
          {this.getTermTile(term, true, localizedStrings.showAllSubProcessButton, true)}
          {termChildren.map(child => this.getTermTile(child))}
        </div>
        <div className="term-description" dangerouslySetInnerHTML={{ __html: description }} />
      </div>
    );
  }

  private getTermTile(
    term: ITaxonomyTerm,
    highlighted: boolean = false,
    label?: string,
    navigateToSearch: boolean = false,
  ): JSX.Element {
    return (
      <TermTile
        highlighted={highlighted}
        key={term.id}
        label={label}
        navigateToSearch={navigateToSearch}
        predefinedUrlParameters={this.props.predefinedUrlParameters}
        term={term}
      />
    );
  }
}
