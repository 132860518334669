
import { connect } from 'react-redux';
import {  } from 'react-router-dom';
import { RootState } from '../../store/types/RootState';
import { HousePageLink } from './HousePageLink';
import './HousePageLink.scss';
import { IHousePageLinkContainerStateProps } from './IHousePageLinkContainerStateProps';

const mapStateToProps = (state: RootState): IHousePageLinkContainerStateProps => ({
  hidden: state.preferences.businessUnitTermId === '' || state.preferences.countryTermId === '',
});

const housePageContainer = connect(
  mapStateToProps,
)(HousePageLink);

export {
  housePageContainer as HousePageLinkContainer,
};
