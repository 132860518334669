import { parse, stringify } from 'query-string';
import { history } from '../../History';
import { SortDirection } from '../../lib/entities/SortDirection';
import { RootState } from '../../store/types/RootState';
import { IDocumentRefinementFilter } from '../documents/services/IDocumentRefinementFilter';
import { ISelectedDocumentInfo } from '../documents/services/ISelectedDocumentInfo';
import { ISearchState } from '../ISearchState';
import { ISearchUrlParameters } from './ISearchUrlParameters';

export function getQueryFromLocation(): string | undefined {
  const { query } = getSearchUrlParameters();
  return query;
}

export function getSortByFromLocation(): string | undefined {
  const { sortBy } = getSearchUrlParameters();
  return sortBy;
}

export function getSortDirectionFromLocation(): SortDirection | undefined {
  const { sortDir } = getSearchUrlParameters();
  return sortDir as SortDirection | undefined;
}

export function getRefinementFiltersFromLocation(): IDocumentRefinementFilter[] | undefined {
  const { refFilters } = getSearchUrlParameters();
  if (refFilters === undefined) {
    return undefined;
  }
  return typeof refFilters === 'string'
    ? [parseRefinementFilter(refFilters)]
    : refFilters.map(parseRefinementFilter);
}

export function getBusinessUnitFromLocation(): string | undefined {
  const { bu } = getSearchUrlParameters();
  return bu;
}

export function getCountryFromLocation(): string | undefined {
  const { country } = getSearchUrlParameters();
  return country;
}

export function getLanguagesFromLocation(): string[] | undefined {
  const { lang } = getSearchUrlParameters();
  // tslint:disable-next-line:strict-type-predicates
  if (typeof lang === 'string') {
    return [ lang ];
  } else if (typeof lang === 'undefined') {
    return [];
  }
  return lang;
}

export function getProcessTypeFromLocation(): string | undefined {
  const { process } = getSearchUrlParameters();
  return process;
}

export function getSelectedDocumentInfoFromLocation(): ISelectedDocumentInfo | undefined {
  const { id, listId } = getSearchUrlParameters();
  return id === undefined || listId === undefined
    ? undefined
    : {
      listId,
      listItemId: id,
    };
}

export function getSearchUrlParameters(): ISearchUrlParameters {
  return parse(history.location.search);
}

export function doesUrlContainParameters(): boolean {
  const urlParameters = getSearchUrlParameters();
  return Object.values(urlParameters).length !== 0;
}

export function updateHistory(state: RootState): void {
  const usePreferences = !hasSearchParameters();
  const search = getLocationSearchString(state, usePreferences);
  updateHistoryWithSearch(search);
}

export function resetSearch(): void {
  updateHistoryWithSearch();
}

export function serializeSearchParameters(parameters: ISearchUrlParameters): string {
  return stringify(parameters);
}

function hasSearchParameters(): boolean {
  const { search } = history.location;
  return search !== '';
}

function getLocationSearchString(state: RootState, usePreferences: boolean): string {
  let parameters = setSearchParameters(state.search, {});
  parameters = setPreferencesParameters(state, parameters, usePreferences);
  parameters = setPreferencesParameters(state, parameters, usePreferences);
  return serializeSearchParameters(parameters);
}

function setSearchParameters(state: ISearchState, parameters: ISearchUrlParameters): ISearchUrlParameters {
  const { queryText, sortBy, sortDirection, refinementFilters, processTypeTermId, selectedDocumentInfo } = state;
  setQueryText(queryText, parameters);
  setSortBy(sortBy, sortDirection, parameters);
  setProcessTypeTermId(processTypeTermId, parameters);
  setRefinementFilters(refinementFilters, parameters);
  setSelectedDocument(selectedDocumentInfo, parameters);
  return parameters;
}

function setQueryText(queryText: string, parameters: ISearchUrlParameters): void {
  if (queryText !== '') {
    parameters.query = queryText;
  }
}

function setSortBy(sortBy: string, sortDirection: SortDirection, parameters: ISearchUrlParameters): void {
  if (sortBy !== '') {
    parameters.sortBy = sortBy;
    parameters.sortDir = sortDirection;
  }
}

function setProcessTypeTermId(processTypeTermId: string, parameters: ISearchUrlParameters): void {
  if (processTypeTermId !== '') {
    parameters.process = processTypeTermId;
  }
}

function setRefinementFilters(refinementFilters: IDocumentRefinementFilter[], parameters: ISearchUrlParameters): void {
  if (refinementFilters.length > 0) {
    parameters.refFilters = refinementFilters.map(stringifyRefinementFilter);
  }
}

function setSelectedDocument(selectedDocumentInfo: ISelectedDocumentInfo, parameters: ISearchUrlParameters): void {
  if (selectedDocumentInfo.listId !== '' && selectedDocumentInfo.listItemId !== 0) {
    parameters.id = selectedDocumentInfo.listItemId;
    parameters.listId = selectedDocumentInfo.listId;
  }
}

function setPreferencesParameters(
  state: RootState,
  parameters: ISearchUrlParameters,
  usePreferences: boolean,
): ISearchUrlParameters {
  setBusinessUnitPreference(state, parameters, usePreferences);
  setCountryPreference(state, parameters, usePreferences);
  setLanguagesPreference(state, parameters, usePreferences);
  return parameters;
}

function setBusinessUnitPreference(
  state: RootState,
  parameters: ISearchUrlParameters,
  usePreferences: boolean,
): ISearchUrlParameters {
  if (state.search.businessUnitTermId !== '') {
    parameters.bu = state.search.businessUnitTermId;
  } else {
    if (state.preferences.businessUnitTermId !== '' && usePreferences) {
      parameters.bu = state.preferences.businessUnitTermId;
    }
  }
  return parameters;
}

function setCountryPreference(
  state: RootState,
  parameters: ISearchUrlParameters,
  usePreferences: boolean,
): ISearchUrlParameters {
  if (state.search.countryTermId !== '') {
    parameters.country = state.search.countryTermId;
  } else {
    if (state.preferences.countryTermId !== '' && usePreferences) {
      parameters.country = state.preferences.countryTermId;
    }
  }
  return parameters;
}

function setLanguagesPreference(
  state: RootState,
  parameters: ISearchUrlParameters,
  usePreferences: boolean,
): ISearchUrlParameters {
  if (state.search.languageTermIds.length > 0) {
    parameters.lang = state.search.languageTermIds;
  } else {
    if (state.preferences.languageTermIds.length > 0 && usePreferences) {
      parameters.lang = state.preferences.languageTermIds;
    }
  }
  return parameters;
}

function stringifyRefinementFilter(refinementFilter: IDocumentRefinementFilter): string {
  return JSON.stringify(refinementFilter);
}

function parseRefinementFilter(refinementFilterString: string): IDocumentRefinementFilter {
  return JSON.parse(refinementFilterString) as IDocumentRefinementFilter;
}

function updateHistoryWithSearch(search?: string): void {
  history.push({
    pathname: history.location.pathname,
    search,
  });
}
