export const nullValueType: string = 'Null';

export const stringValueType: string = 'Edm.String';

export const int32ValueType: string = 'Edm.Int32';

export const int64ValueType: string = 'Edm.Int64';

export const doubleValueType: string = 'Edm.Double';

export const booleanValueType: string = 'Edm.Boolean';

export const dateTimeValueType: string = 'Edm.DateTime';

export const guidValueType: string = 'Edm.Guid';
