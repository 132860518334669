import React from 'react';
import { findTermById, getTermLabel } from '../../lib/taxonomy/Taxonomy';
import { getSearchLinkUrl } from '../common/Utils';
import { FloorHeader } from '../floorHeader/FloorHeader';
import { getNavigationTermId } from './Floor2Utils';
import { IFloor2HeaderProps } from './IFloor2HeaderProps';

export class Floor2Header extends React.PureComponent<IFloor2HeaderProps> {

  public render(): JSX.Element {
    const { titleNavigationTermId } = this.props;
    const term = findTermById(this.props.navigationTermSet, titleNavigationTermId);
    if (term === undefined) {
      return <span />;
    }
    const label = getTermLabel(term);
    const url: string = this.getHeaderUrl();
    return <FloorHeader key={term.id} className="bg-l om-bordered floor2" title={label} url={url} />;
  }

  private getHeaderUrl(): string {
    const navigationTermId = getNavigationTermId(
      this.props.configurationTermId,
      this.props.configurationTermSet,
      this.props.navigationTermPropertyName,
    );
    return navigationTermId !== undefined
      ? getSearchLinkUrl(navigationTermId, this.props.predefinedUrlParameters)
      : '';
  }
}
