import React from 'react';
import { IPredefinedFilter } from '../../entities/IPredefinedFilter';
import { getFilterKey } from '../../entities/PredefinedFilterUtils';
import { ClearFiltersButtonContainer } from './clearFiltersButton/ClearFiltersButtonContainer';
import './FilterChips.scss';
import { IFilterChipsProps } from './IFilterChipsProps';
import { PredefinedFilterChipContainer } from './predefinedFilter/PredefinedFilterChipContainer';
import { RefinementFilterChipContainer } from './refinementFilter/RefinementFilterChipContainer';

export class FilterChips extends React.PureComponent<IFilterChipsProps> {

  public render(): JSX.Element {
    return (
      <div className="chips-wrapper">
        {this.getClearAllChip()}
        {this.getRefinementFilterChips()}
        {this.getPredefinedFilterChips()}
      </div>
    );
  }

  private getClearAllChip(): JSX.Element | undefined {
    const predefinedFilters = this.getPredefinedFilters();
    if (this.props.refinementFilters.length > 0 || predefinedFilters.length > 0) {
      return (
        <div className="chip-wrapper">
          <ClearFiltersButtonContainer />
        </div>
      );
    }
    return undefined;
  }

  private getRefinementFilterChips(): JSX.Element[] {
    const elements: JSX.Element[] = [];
    this.props.refinementFilters.forEach(filter => {
      filter.values.forEach((value, index) => {
        const valueChipElement = (
          <div className="chip-wrapper" key={value.token} >
            <RefinementFilterChipContainer refinementFilter={filter} valueIndex={index} />
          </div>
        );
        elements.push(valueChipElement);
      });
    });
    return elements;
  }

  private getPredefinedFilterChips(): JSX.Element[] {
    return this.getPredefinedFilters()
      .map(filter =>
        (
          <div className="chip-wrapper" key={getFilterKey(filter)}>
            <PredefinedFilterChipContainer
              termSetId={filter.termSetId}
              termId={filter.termId}
              multiple={filter.multiple}
              showHierarchyInLabel={filter.showHierarchyInLabel}
            />
          </div>
        ),
      );
  }

  private getPredefinedFilters(): IPredefinedFilter[] {
    return this.props.predefinedFilters.filter(filter => filter.termId !== '');
  }
}
