// tslint:disable:file-name-casing
// tslint:disable-next-line:no-import-side-effect
import './polyfill';
import './styles/common.scss';
import './styles/icons.scss';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { MuiThemeProvider } from '@material-ui/core';

import { App } from './App';
import { config } from './config/Config';
import { deleteExpiredCachedItems } from './lib/cache/StorageCache';
import { configLocalization } from './lib/localization/Localization';
import { Authentication } from './lib/sharePoint/Authentication';
import { configurePnp } from './lib/sharePoint/PnPUtils';
import { StorageType } from './lib/storage/StorageType';
import { configTaxonomyCache, getTermSetsByIds } from './lib/taxonomy/CachedTaxonomy';
import { configUserCache } from './lib/user/CachedUser';
import { unregister } from './RegisterServiceWorker';
import { store } from './store/Store';
import { theme } from './Theme';

const auth = new Authentication();
auth.init();
if (auth.isAuthenticated) {
  configurePnp(auth.authenticationContext);
  configLocalization(config.localization);
  deleteExpiredCachedItems();
  configUserCache({
    storageCacheExpirationDays: 1,
    storageType: StorageType.Session,
    useMemoryCache: true,
    useStorageCache: true,
  });
  configTaxonomyCache({
    storageCacheExpirationDays: 1,
    storageType: StorageType.LocalStorage,
    useMemoryCache: true,
    useStorageCache: true,
  });
  getTermSetsByIds([
    process.env.REACT_APP_BUSINESS_UNIT_TERM_SET_ID as string,
    process.env.REACT_APP_APPLICABLE_COUNTRY_TERM_SET_ID as string,
    process.env.REACT_APP_DOCUMENT_TYPE_TERM_SET_ID as string,
    process.env.REACT_APP_DOCUMENT_LANGUAGE_TERM_SET_ID as string,
    process.env.REACT_APP_PROCESS_TYPE_TERM_SET_ID as string,
    process.env.REACT_APP_SCOPE_OF_SERVICE_TERM_SET_ID as string,
    process.env.REACT_APP_SUB_PROCESS_TERM_SET_ID as string,
    process.env.REACT_APP_INDIVIDUAL_TAG_TERM_SET_ID as string,
  ])
    .catch(error => {
      // tslint:disable-next-line:no-console
      console.error(error);
    });
  ReactDOM.render(
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <App />
      </MuiThemeProvider>
    </Provider>,
    document.getElementById('root') as HTMLElement,
  );
  unregister();
} else {
  // tslint:disable-next-line:no-console
  console.log('Auth not successful');
}
