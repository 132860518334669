import { connect } from 'react-redux';
import { RootState } from '../store/types/RootState';
import { INotificationDispatchProps } from './INotificationDispatchProps';
import { INotificationStateProps } from './INotificationStateProps';
import { Notification } from './Notification';
import { hideNotification, showNotification } from './NotificationActions';

const mapStateToProps = (state: RootState): INotificationStateProps => ({
  message: state.notification.message,
});

const dispatchProps: INotificationDispatchProps = {
  onHide: hideNotification,
  onShow: showNotification,
};

const notificationContainer = connect(
  mapStateToProps,
  dispatchProps,
)(Notification);

export {
  notificationContainer as NotificationContainer,
};
