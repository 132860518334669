import React from 'react';
import { connect } from 'react-redux';
import { findTermById } from '../../../../lib/taxonomy/Taxonomy';
import { RootState } from '../../../../store/types/RootState';
import { loadTermSet } from '../../../../termSets/TermSetActions';
import { getTermSetByIdFromState } from '../../../../termSets/TermSetUtils';
import { applyPredefinedFilter } from '../../../SearchActions';
import { IPredefinedFilterChipContainerDispatchProps } from './IPredefinedFilterChipContainerDispatchProps';
import { IPredefinedFilterChipContainerOwnProps } from './IPredefinedFilterChipContainerOwnProps';
import { IPredefinedFilterChipContainerStateProps } from './IPredefinedFilterChipContainerStateProps';
import { PredefinedFilterChip } from './PredefinedFilterChip';

class PredefinedFilterChipContainer extends React.PureComponent<
  IPredefinedFilterChipContainerOwnProps &
  IPredefinedFilterChipContainerStateProps &
  IPredefinedFilterChipContainerDispatchProps
> {

  public componentDidMount(): void {
    this.props.onLoadTermSet(this.props.termSetId);
  }

  public render(): JSX.Element | null {
    const { term } = this.props;
    return term !== undefined
      ? (
        <PredefinedFilterChip
          termSetId={this.props.termSetId}
          termSet={this.props.termSet}
          term={term}
          showHierarchyInLabel={this.props.showHierarchyInLabel}
          onRemove={this.props.onRemove}
          multiple={this.props.multiple}
        />
      )
      // tslint:disable-next-line:no-null-keyword
      : null;
  }
}

const mapStateToProps =
  (state: RootState, ownProps: IPredefinedFilterChipContainerOwnProps): IPredefinedFilterChipContainerStateProps => {
    const termSet = getTermSetByIdFromState(state, ownProps.termSetId);
    const term = findTermById(termSet, ownProps.termId);
    return {
      term,
      termSet,
    };
  };

const dispatchProps: IPredefinedFilterChipContainerDispatchProps = {
  onLoadTermSet: loadTermSet.request,
  onRemove: (termSetId, value) => applyPredefinedFilter({
    termSetId,
    value,
  }),
};

const predefinedFilterChipContainer = connect(
  mapStateToProps,
  dispatchProps,
)(PredefinedFilterChipContainer);

export {
  predefinedFilterChipContainer as PredefinedFilterChipContainer,
};
