import * as React from 'react';
import { IErrorMessageProps } from './IErrorMessageProps';

export class ErrorMessage extends React.PureComponent<IErrorMessageProps> {

  public render(): JSX.Element {
    return (
      <div>
        <div>Something went wrong</div>
        <div>Technical details:</div>
        <div>{this.props.message}</div>
      </div>
    );
  }
}
