import { connect } from 'react-redux';
import { RootState } from '../../store/types/RootState';
import { query, resetSearch } from '../SearchActions';
import { ISearchBoxDispatchProps } from './ISearchBoxDispatchProps';
import { ISearchBoxStateProps } from './ISearchBoxStateProps';
import { SearchBox } from './SearchBox';

const mapStateToProps = (state: RootState): ISearchBoxStateProps => ({
  queryText: state.search.queryText,
});

const dispatchProps: ISearchBoxDispatchProps = {
  onClearSearch: resetSearch,
  onSearch: query,
};

const searchBoxContainer = connect(
  mapStateToProps,
  dispatchProps,
)(SearchBox);

export {
  searchBoxContainer as SearchBoxContainer,
};
