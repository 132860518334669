import { connect } from 'react-redux';
import { RootState } from '../../../store/types/RootState';
import { closeSortMenu } from '../../SearchActions';
import { ISortMenuDispatchProps } from './ISortMenuDispatchProps';
import { ISortMenuStateProps } from './ISortMenuStateProps';
import { SortMenu } from './SortMenu';

const mapStateToProps = (state: RootState): ISortMenuStateProps => ({
  opened: state.search.isSortMenuOpened,
});

const dispatchProps: ISortMenuDispatchProps = {
  onClose: closeSortMenu,
};

const sortMenuContainer = connect(
  mapStateToProps,
  dispatchProps,
)(SortMenu);

export {
  sortMenuContainer as SortMenuContainer,
};
