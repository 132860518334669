import { getDocumentLinks } from '../../../lib/document/DocumentSearchConverter';
import { ISearchItemLink } from '../../../lib/document/ISearchItemLink';
import { getCurrentLanguageCodeName } from '../../../lib/localization/Localization';
import { ISearchItem } from '../../../lib/search/ISearchItem';
import { ISearchResult } from '../../../lib/search/ISearchResult';
import { getTermSetById } from '../../../lib/taxonomy/CachedTaxonomy';
import { ITaxonomyTerm } from '../../../lib/taxonomy/ITaxonomyTerm';
import { ITermSetInfo } from '../../../lib/taxonomy/ITermSetInfo';
import { findTermById, getTermLabel, getTermParents } from '../../../lib/taxonomy/Taxonomy';
import { getMultipleTermLabels, getSingleTermLabel } from '../../../lib/taxonomy/TaxonomySearchConverter';
import { getFileNameWithoutExtension } from '../../../lib/utils/File';
import { getIconSrcByFileType } from './Icons';
import { IDocument } from './IDocument';
import { IDocumentDetails } from './IDocumentDetails';
import { IDocumentDetailsQueryResult } from './IDocumentDetailsQueryResult';
import { IDocumentsSearchResult } from './IDocumentsSearchResult';
import { ITaxonomyFieldValue } from './ITaxonomyFieldValue';
import { IUserFieldValue } from './IUserFieldValue';

export async function convertToDocumentsSearchResult(searchResult: ISearchResult): Promise<IDocumentsSearchResult> {
  return {
    documents: await getDocuments(searchResult),
    refinements: searchResult.refinements,
    total: searchResult.totalRows,
  };
}

export async function convertToDocumentDetails(
  item: IDocumentDetailsQueryResult,
  listId: string,
  listItemId: number,
): Promise<IDocumentDetails> {
  return {
    approvalDate: getDateFieldValue(item.Modified),
    approver: getUserFieldTextValue(item.Approver),
    authors: getUsersFieldTextValue(item.Author1),
    businessUnits: await getBusinessUnitLabels(item),
    comment: getFieldValueOrEmpty(item.Comment1),
    countries: await getCountryLabels(item),
    documentType: await getDocumentTypeLabel(item.Document_x0020_Type),
    errorMessage: '',
    id: listItemId,
    languages: await getLanguageLabels(item),
    listId,
    owner: getUserFieldTextValue(item.Owner),
    processTypes: await getProcessTypeLabels(item),
    subProcesses: await getSubProcessLabels(item),
    tags: await getTagLabels(item),
    title: getFileNameWithoutExtension(item.FileLeafRef),
    version: item.OData__UIVersionString,
  };
}

async function getDocuments(searchResult: ISearchResult): Promise<IDocument[]> {
  const links = await getDocumentLinks(searchResult.items);
  const documents = searchResult.items.map(async item => ({
    documentType: await getSingleTermLabel(item.owstaxIdDocumentx0020Type as string | undefined),
    fileType: item.ImplFileTypeSortable as string,
    iconSrc: getIconSrcByFileType(item.ImplFileTypeSortable as string),
    id: item.UniqueId as string,
    languages: await getTermLabels(item.owstaxIdDocumentx0020Language as string | undefined),
    link: getDocumentLink(item, links),
    listId: item.ListID as string,
    listItemId: item.ListItemID as number,
    tags: await getTermLabels(item.owstaxIdIndividualx0020tag as string | undefined),
    title: getFileNameWithoutExtension(item.Filename as string),
  }));
  return Promise.all(documents);
}

async function getTermLabels(searchValue: string | undefined): Promise<string[]> {
  const labels = await getMultipleTermLabels(searchValue);
  return labels.sort();
}

function getDateFieldValue(date: string): string {
  const dateValue = new Date(date);
  const languageCodeName = getCurrentLanguageCodeName();
  return dateValue.toLocaleDateString(languageCodeName);
}

function getDocumentLink(item: ISearchItem, links: ISearchItemLink[]): string {
  const itemLink = links.find(link => link.item === item);
  return itemLink !== undefined ? itemLink.url : '';
}

function getUsersFieldTextValue(users?: IUserFieldValue[]): string[] {
  return users === undefined ? [] : users.map(getUserFieldTextValue);
}

function getUserFieldTextValue(user: IUserFieldValue): string {
  return user.Title;
}

async function getBusinessUnitLabels(item: IDocumentDetailsQueryResult): Promise<string[]> {
  return getTaxonomyLocalizedValues(
    process.env.REACT_APP_BUSINESS_UNIT_TERM_SET_ID as string,
    item.Business_x0020_Unit,
    true);
}

async function getCountryLabels(item: IDocumentDetailsQueryResult): Promise<string[]> {
  return getTaxonomyLocalizedValues(
    process.env.REACT_APP_APPLICABLE_COUNTRY_TERM_SET_ID as string,
    item.Applicable_x0020_Country);
}

async function getDocumentTypeLabel(documentType: ITaxonomyFieldValue): Promise<string> {
  const labels = await getTaxonomyLocalizedValues(
    process.env.REACT_APP_DOCUMENT_TYPE_TERM_SET_ID as string,
    [documentType]);
  return labels.length > 0 ? labels[0] : '';
}

async function getProcessTypeLabels(item: IDocumentDetailsQueryResult): Promise<string[]> {
  return getTaxonomyLocalizedValues(
    process.env.REACT_APP_PROCESS_TYPE_TERM_SET_ID as string,
    item.Process_x0020_Type,
    true);
}

async function getSubProcessLabels(item: IDocumentDetailsQueryResult): Promise<string[]> {
  return getTaxonomyLocalizedValues(
    process.env.REACT_APP_SUB_PROCESS_TERM_SET_ID as string,
    item.Sub_x002d_process);
}

async function getLanguageLabels(item: IDocumentDetailsQueryResult): Promise<string[]> {
  return getTaxonomyLocalizedValues(
    process.env.REACT_APP_DOCUMENT_LANGUAGE_TERM_SET_ID as string,
    item.Document_x0020_Language);
}

async function getTagLabels(item: IDocumentDetailsQueryResult): Promise<string[]> {
  return getTaxonomyLocalizedValues(
    process.env.REACT_APP_INDIVIDUAL_TAG_TERM_SET_ID as string,
    item.Individual_x0020_tag);
}

// tslint:disable-next-line: cognitive-complexity
async function getTaxonomyLocalizedValues(
  termSetId: string,
  values: ITaxonomyFieldValue[] | undefined,
  localizeFullPath: boolean = false,
): Promise<string[]> {
  const labels: string[] = [];
  if (values !== undefined) {
    const termSet = await getTermSetById(termSetId);
    if (termSet !== undefined) {
      values.forEach(value => {
        const term = findTermById(termSet, value.TermGuid);
        if (term !== undefined) {
          if (localizeFullPath) {
            const pathLabels = getTaxonomyLocalizedPathValues(termSet, term);
            labels.push(pathLabels.join(':'));
          } else {
            labels.push(getTermLabel(term));
          }
        }
      });
    }
  }
  return labels;
}

function getTaxonomyLocalizedPathValues(
  termSet: ITermSetInfo,
  term: ITaxonomyTerm,
): string[] {
  const labels: string[] = [];
  const parents = getTermParents(termSet, term.id);
  parents.forEach(parent => {
    labels.push(getTermLabel(parent));
  });

  labels.push(getTermLabel(term));
  return labels;
}

function getFieldValueOrEmpty(value: string | null): string {
  return value !== null ? value : '';
}
