import { User } from '@microsoft/microsoft-graph-types';
import { graph } from '@pnp/graph';
import { convertPhotoToDataUrl } from '../utils/File';
import { IUserProfile } from './IUserProfile';

export async function retrieveCurrentUserProfile(): Promise<IUserProfile> {
  const user = await getUser();
  const pictureUrl = await getUserPhotoAsDataUrl();
  return {
    displayName: user.displayName,
    pictureUrl,
  };
}

async function getUser(): Promise<User> {
  return graph.me
    .select('displayName')
    .get();
}

async function getUserPhotoAsDataUrl(): Promise<string | undefined> {
  try {
    const photo = await graph.me.photo.getBlob();
    return await convertPhotoToDataUrl(photo);
    } catch {
      return undefined;
  }
}
