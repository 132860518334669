import React from 'react';

export class BreadcrumbArrow extends React.PureComponent {

  // tslint:disable-next-line:prefer-function-over-method
  public render(): JSX.Element {
    return (
      <span className="icon-Arrow-Right breadcrumb-arrow" />
    );
  }
}
