import { ILocalizationConfig } from './ILocalizationConfig';

const englishLcid = 1033;
const englishCodeName = 'en';

const languageCodeNameLength = 2;

let config: ILocalizationConfig = {
  availableLanguages: [
    {
      lcid: englishLcid,
      name: englishCodeName,
    },
  ],
  defaultLanguageCodeName: englishCodeName,
  defaultLanguageLcid: englishLcid,
};

export function configLocalization(localizationConfig: ILocalizationConfig): void {
  config = {
    ...localizationConfig,
  };
}

export function getCurrentLanguageLcid(): number {
  const interfaceLanguageName = navigator.language.slice(0, languageCodeNameLength);
  const interfaceLanguageConfig = config.availableLanguages.find(language => language.name === interfaceLanguageName);
  return interfaceLanguageConfig !== undefined ? interfaceLanguageConfig.lcid : config.defaultLanguageLcid;
}

export function getCurrentLanguageCodeName(): string {
  const interfaceLanguageName = navigator.language.slice(0, languageCodeNameLength);
  const interfaceLanguageConfig = config.availableLanguages.find(language => language.name === interfaceLanguageName);
  return interfaceLanguageConfig !== undefined ? interfaceLanguageConfig.name : config.defaultLanguageCodeName;
}
