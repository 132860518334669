export const termIdFieldName: string = 'Id';

export const termLabelsFieldName: string = 'Labels';

export const termCustomPropertiesFieldName: string = 'CustomProperties';

export const termIsAvailableForTaggingFieldName: string = 'IsAvailableForTagging';

export const termParentFieldName: string = 'Parent';

export const termPathFieldName: string = 'PathOfTerm';

export const termTermsCountFieldName: string = 'TermsCount';

export const termCustomSortOrderFieldName: string = 'CustomSortOrder';

export const termSetIdFieldName: string = 'Id';

export const pictureUrlPropertyName: string = 'PictureURL';

export const childItemsPropertyName: string = '_Child_Items_';

export const termStoreIdFieldName: string = 'Id';

export const defaultLanguageFieldName: string = 'DefaultLanguage';

export const keywordsTermSetFieldName: string = 'KeywordsTermSet';
