
import { MenuItem } from '@material-ui/core';
import React from 'react';
// tslint:disable-next-line:no-submodule-imports
import { OptionProps } from 'react-select/lib/components/Option';

export class Option<TOption> extends React.PureComponent<OptionProps<TOption>> {

  public render(): JSX.Element {
    const { innerProps, innerRef, isFocused, children } = this.props;
    return (
      <MenuItem
        buttonRef={innerRef}
        selected={isFocused}
        component="div"
        {...innerProps}
      >
        {children}
      </MenuItem>
    );
  }
}
