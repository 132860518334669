import { combineEpics, Epic } from 'redux-observable';
import * as headerEpics from '../header/Epics';
import * as preferencesEpics from '../preferences/Epics';
import * as searchEpics from '../search/Epics';
import * as termSetEpics from '../termSets/Epics';
import { RootAction } from './types/RootAction';
import { RootService } from './types/RootService';
import { RootState } from './types/RootState';

const searchEpicsValues = Object
  .keys(searchEpics)
  .map(key => searchEpics[key] as Epic<RootAction, RootAction, RootState, RootService>);

const preferencesEpicsValues = Object
  .keys(preferencesEpics)
  .map(key => preferencesEpics[key] as Epic<RootAction, RootAction, RootState, RootService>);

const headerEpicsValues = Object
  .keys(headerEpics)
  .map(key => headerEpics[key] as Epic<RootAction, RootAction, RootState, RootService>);

const termSetEpicsValues = Object
  .keys(termSetEpics)
  .map(key => termSetEpics[key] as Epic<RootAction, RootAction, RootState, RootService>);

const epics = [
  ...headerEpicsValues,
  ...preferencesEpicsValues,
  ...searchEpicsValues,
  ...termSetEpicsValues,
];

export const rootEpic = combineEpics(...epics);
