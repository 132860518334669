import * as React from 'react';
import { connect } from 'react-redux';
import * as accordionActions from '../../accordion/AccordionActions';
import { mapAccordionStateToProps } from '../../accordion/AccordionUtils';
import { houseConfig } from '../../config/HouseConfig';
import { Floor } from '../../house/floor/Floor';
import { getFloorHeader, getFloorTerms } from '../../house/housePage/HousePageUtils';
import { RootState } from '../../store/types/RootState';
import { IHouseFloorConfig } from '../common/IHouseFloorConfig';
import { BaseHouseRowAccordionContainer } from './BaseHouseRowAccordionContainer';
import { IAccordionContainerDispatchProps } from './IAccordionContainerDispatchProps';
import { IAccordionContainerProps } from './IAccordionContainerProps';
import { IAccordionContainerStateProps } from './IAccordionContainerStateProps';

class Floor1AccordionContainer extends BaseHouseRowAccordionContainer<IAccordionContainerProps> {

  protected getHeaderElement(): JSX.Element {
    const { termSet, predefinedUrlParameters } = this.props;
    const floorConfig = houseConfig.floor1 as IHouseFloorConfig;
    return getFloorHeader(predefinedUrlParameters, termSet, floorConfig.termId, false);
  }

  protected getContentsElement(): JSX.Element {
    const { termSet, predefinedUrlParameters } = this.props;
    const floorConfig = houseConfig.floor1 as IHouseFloorConfig;
    const floorTerms = getFloorTerms(floorConfig, termSet, predefinedUrlParameters);
    return <Floor key="floor1" childTerms={floorTerms} columnClass="col-md-3" />;
  }
}

const mapStateToProps = (state: RootState, ownProps: IAccordionContainerProps): IAccordionContainerStateProps =>
  mapAccordionStateToProps(ownProps.name, state);

const dispatchProps: IAccordionContainerDispatchProps = {
  onRender: accordionActions.createAccordion,
  onToggle: accordionActions.toggleCollapsedState,
};

const floor1AccordionContainer = connect(
  mapStateToProps,
  dispatchProps,
)(Floor1AccordionContainer);

export {
  floor1AccordionContainer as Floor1AccordionContainer,
};
