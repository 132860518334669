import { Epic } from 'redux-observable';
// tslint:disable-next-line:no-submodule-imports
import { filter, map, tap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { localizedStrings } from '../localization/LocalizedStrings';
import { showNotification } from '../notification/NotificationActions';
import { RootAction } from '../store/types/RootAction';
import { RootService } from '../store/types/RootService';
import { RootState } from '../store/types/RootState';
import { setPreference, setPreferences } from './PreferencesActions';

export const setPreferenceEpic: Epic<RootAction, RootAction, RootState, RootService> =
  (action$, state$, { preferencesService }) => (
    action$.pipe(
      filter(isActionOf(setPreference)),
      tap(action => {
        preferencesService.setPreference(action.payload.name, action.payload.value);
      }),
      map(() => showNotification(localizedStrings.predefinedFiltersSavedMessage)),
    )
  );

export const setPreferencesEpic: Epic<RootAction, RootAction, RootState, RootService> =
  (action$, state$, { preferencesService }) => (
    action$.pipe(
      filter(isActionOf(setPreferences)),
      tap(action => {
        preferencesService.setPreferences(action.payload.name, action.payload.values);
      }),
      map(() => showNotification(localizedStrings.predefinedFiltersSavedMessage)),
    )
  );
