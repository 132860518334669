import { StyledComponentProps } from '@material-ui/core';
import React from 'react';
// tslint:disable-next-line:no-submodule-imports
import { IndicatorProps } from 'react-select/lib/components/indicators';

export class DropdownIndicator<TOption> extends React.PureComponent<IndicatorProps<TOption>> {

  public render(): JSX.Element {
    const { selectProps } = this.props;
    const styledProps = selectProps as StyledComponentProps;
    const className = styledProps.classes !== undefined ? styledProps.classes.dropdownIndicator : undefined;
    return <span className={`icon-Arrow-Drop-Down ${className}`} />;
  }
}
