
import { StyledComponentProps, Typography } from '@material-ui/core';
import React from 'react';
// tslint:disable-next-line:no-submodule-imports
import { NoticeProps } from 'react-select/lib/components/Menu';

export class NoOptionsMessage<TOption> extends React.PureComponent<NoticeProps<TOption>> {

  public render(): JSX.Element {
    const { innerProps, selectProps, children } = this.props;
    const styledProps = selectProps as StyledComponentProps;
    const className = styledProps.classes !== undefined ? styledProps.classes.noOptionsMessage : undefined;
    return (
      <Typography
        color="textSecondary"
        className={className}
        {...innerProps}
      >
        {children}
      </Typography>
    );
  }
}
