import { graph } from '@pnp/graph';
import { sp } from '@pnp/sp-addinhelpers';
import { taxonomy } from '@pnp/sp-taxonomy';
import AuthenticationContext from 'adal-angular';
import { config } from '../../config/Config';
import { PnPFetchClient } from './PnPFetchClient';

export function configurePnp(auth: AuthenticationContext): void {
  graph.setup({
    graph: {
      fetchClientFactory: () => new PnPFetchClient(auth),
    },
  });
  sp.setup({
    sp: {
      fetchClientFactory: () => new PnPFetchClient(auth),
    },
  });
  taxonomy.setup({
    sp: {
      baseUrl: config.sharepointUrl,
      fetchClientFactory: () => new PnPFetchClient(auth),
    },
  });
}

export function getResource(url: string): string {
  const parser = document.createElement('a');
  parser.href = url;
  return `${parser.protocol}//${parser.hostname}`;
}
