import { combineReducers } from 'redux';
import { getType } from 'typesafe-actions';
import { INotificationState } from './INotificationState';
import * as actions from './NotificationActions';
import { NotificationAction } from './types/NotificationAction';

const defaultMessage = '';

function getMessage(message: string = defaultMessage, action: NotificationAction): string {
  switch (action.type) {
    case getType(actions.showNotification):
      return action.payload;
    case getType(actions.hideNotification):
      return defaultMessage;
    default:
      return message;
  }
}

export const notificationReducer = combineReducers<INotificationState, NotificationAction>({
  message: getMessage,
});
