import * as React from 'react';
import { BreadcrumbContainer } from '../breadcrumb/BreadcrumbContainer';
import { DocumentPanelContainer } from '../documentPanel/DocumentPanelContainer';
import { DocumentsSearchResultContainer } from '../documents/list/DocumentsSearchResultContainer';
import { SearchBoxContainer } from '../searchBox/SearchBoxContainer';
import { ISearchPageProps } from './ISearchPageProps';
import './SearchPage.scss';

export class SearchPage extends React.PureComponent<ISearchPageProps> {

  public render(): JSX.Element {
    return (
      <div>
        {this.getSearchBox()}
        <BreadcrumbContainer />
        <div>
          <DocumentsSearchResultContainer />
        </div>
        <DocumentPanelContainer />
      </div>
    );
  }

  private getSearchBox(): JSX.Element {
    if (this.props.hideSearchBox === true) {
      return <></>;
    }
    return (
      <div className="search-box-container">
        <SearchBoxContainer />
      </div>
    );
  }
}
