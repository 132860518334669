import React from 'react';
import { connect } from 'react-redux';
import { config } from '../../../../config/Config';
import { ITermSetInfo } from '../../../../lib/taxonomy/ITermSetInfo';
import { RootState } from '../../../../store/types/RootState';
import { loadTermSet } from '../../../../termSets/TermSetActions';
import { getRefinerItemLabel } from '../../../refiners/RefinerUtils';
import { applyRefinementFilter } from '../../../SearchActions';
import { IRefinementFilterChipContainerDispatchProps } from './IRefinementFilterChipContainerDispatchProps';
import { IRefinementFilterChipContainerOwnProps } from './IRefinementFilterChipContainerOwnProps';
import { IRefinementFilterChipContainerStateProps } from './IRefinementFilterChipContainerStateProps';
import { RefinementFilterChip } from './RefinementFilterChip';

class RefinementFilterChipContainer extends React.PureComponent<
  IRefinementFilterChipContainerOwnProps &
  IRefinementFilterChipContainerStateProps &
  IRefinementFilterChipContainerDispatchProps
  > {

  public componentDidMount(): void {
    this.loadTermSet();
  }

  public render(): JSX.Element | null {
    const { refinementFilter, valueIndex, onRemove } = this.props;
    const label = this.getLabel();
    return (
      <RefinementFilterChip
        label={label}
        refinementFilter={refinementFilter}
        valueIndex={valueIndex}
        onRemove={onRemove}
      />
    );
  }

  private loadTermSet(): void {
    this.getTermSet(true);
  }

  private getTermSet(loadIfNotFound: boolean = false): ITermSetInfo | undefined {
    const { refinementFilter, termSets, onLoadTermSet } = this.props;
    const refinerConfig = config.search.refiners.find(refiner => refiner.name === refinementFilter.name);
    if (refinerConfig !== undefined) {
      const loadedTermSet = termSets.find(termSet => termSet.id === refinerConfig.termSetId);
      if (loadedTermSet === undefined && loadIfNotFound) {
        onLoadTermSet(refinerConfig.termSetId);
      }
      return loadedTermSet;
    }
    return undefined;
  }

  private getLabel(): string {
    const termSet = this.getTermSet();
    if (termSet === undefined) {
      return '';
    }
    const { refinementFilter, valueIndex } = this.props;
    const { hierarchy, label, termId} = refinementFilter.values[valueIndex];
    return getRefinerItemLabel(termSet, hierarchy === true, termId, label);
  }
}

const mapStateToProps = (state: RootState): IRefinementFilterChipContainerStateProps => ({
  termSets: state.termSets,
});

const dispatchProps: IRefinementFilterChipContainerDispatchProps = {
  onLoadTermSet: loadTermSet.request,
  onRemove: (refinerFilter, valueIndex) => {
    refinerFilter.values.splice(valueIndex, 1);
    return applyRefinementFilter(refinerFilter);
  },
};

const refinementFilterChipContainer = connect(
  mapStateToProps,
  dispatchProps,
)(RefinementFilterChipContainer);

export {
  refinementFilterChipContainer as RefinementFilterChipContainer,
};
