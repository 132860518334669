import { Avatar, Typography } from '@material-ui/core';
import * as React from 'react';
import { IUserInfoProps } from './IUserInfoProps';
import './UserInfo.scss';

export class UserInfo extends React.PureComponent<IUserInfoProps> {

  public render(): JSX.Element {
    const avatarElement = this.getAvatar();
    return (
      <div className="current-user-info">
        {avatarElement}
        <div className="user-name">
          <Typography>{this.props.displayName}</Typography>
        </div>
      </div>
    );
  }

  public async componentDidMount(): Promise<void> {
    this.props.onLoad();
  }

  private getAvatar(): JSX.Element {
    return this.props.pictureUrl !== undefined
      ? <Avatar className="user-avatar" src={this.props.pictureUrl} />
      : <></>;
  }
}
