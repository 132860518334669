import { Snackbar, SnackbarContent } from '@material-ui/core';
import React from 'react';
import { config } from '../config/Config';
import { INotificationProps } from './INotificationProps';
import './Notification.scss';

export class Notification extends React.PureComponent<INotificationProps> {

  public render(): JSX.Element {
    const { message } = this.props;
    const isOpened = message !== '';
    return (
      <Snackbar
        className="notification"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={isOpened}
        autoHideDuration={config.notificationDuration}
        onClose={this.handleClose}
      >
        <SnackbarContent
          className="notification-content"
          message={message}
          action={<a role="button" className="close-button icon-Close" onClick={this.handleClose} />}
        />
      </Snackbar>
    );
  }

  private readonly handleClose = () => {
    this.props.onHide();
  }
}
