import { dateAdd } from '@pnp/common';
import { resolveStorage } from '../storage/StorageFactory';
import { StorageType } from '../storage/StorageType';
import { IExpiredCachedItem } from './IExpiredCachedItem';

export const cacheKeyPrefix: string = 'ims.om';

export function deleteExpiredCachedItems(): void {
  deleteExpiredCachedItemsFromStorage(StorageType.LocalStorage);
  deleteExpiredCachedItemsFromStorage(StorageType.Session);
}

export function getValue<TValue>(key: string, storageType?: StorageType): TValue | undefined {
  const storage = resolveStorage(storageType);
  const serializedValue = storage.getItem(internalKey(key));
  if (serializedValue !== null) {
    const chachedItem = deserializeValue<TValue>(serializedValue);
    if (!isExpired(chachedItem)) {
      return chachedItem.value;
    }
    removeValue(key);
  }
  return undefined;
}

export function setValue<TValue>(
  key: string,
  value: TValue,
  storageType?: StorageType,
  storageCacheExpirationDays?: number,
): void {
  const storage = resolveStorage(storageType);
  const expiration = getExpirationDate(storageCacheExpirationDays);
  const serializedValue = serializeValue(value, expiration);
  storage.setItem(internalKey(key), serializedValue);
}

export function removeValue(key: string, storageType?: StorageType): void {
  const storage = resolveStorage(storageType);
  storage.removeItem(internalKey(key));
}

export function hasValue(key: string, storageType?: StorageType): boolean {
  return getValue(key, storageType) !== undefined;
}

function deleteExpiredCachedItemsFromStorage(storageType: StorageType): void {
  const storage: Storage = resolveStorage(StorageType.LocalStorage);
  for (let i: number = 0; i < storage.length; i++) {
    const key = storage.key(i);
    if (key !== null && key.startsWith(cacheKeyPrefix)) {
      getValue(key);
    }
  }
}

function getExpirationDate(storageCacheExpirationDays?: number): Date | undefined {
  return storageCacheExpirationDays !== undefined
    ? dateAdd(new Date(), 'day', storageCacheExpirationDays)
    : undefined;
}

function deserializeValue<TValue>(serializedValue: string): IExpiredCachedItem<TValue> {
  const { expiration, value } = JSON.parse(serializedValue) as { expiration?: string; value: TValue};
  return {
    expiration: expiration === undefined ? undefined : new Date(expiration),
    value,
  };
}

function serializeValue<TValue>(value: TValue, expiration?: Date): string {
  const cachedItem: IExpiredCachedItem<TValue> = {
    expiration,
    value,
  };
  return JSON.stringify(cachedItem);
}

function internalKey(key: string): string {
  if (key.startsWith(cacheKeyPrefix)) {
    return key;
  } else {
    return `${cacheKeyPrefix}.${key}`;
  }
}

function isExpired<TValue>(cachedItem: IExpiredCachedItem<TValue>): boolean {
  return cachedItem.expiration !== undefined && cachedItem.expiration < new Date();
}
