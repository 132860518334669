import { StyledComponentProps } from '@material-ui/core';
import React from 'react';
// tslint:disable-next-line:no-submodule-imports
import { ValueContainerProps } from 'react-select/lib/components/containers';

export class ValueContainer<TOption> extends React.PureComponent<ValueContainerProps<TOption>> {

  public render(): JSX.Element {
    const { selectProps, children } = this.props;
    const styledProps = selectProps as StyledComponentProps;
    const className = styledProps.classes !== undefined ? styledProps.classes.valueContainer : undefined;
    return <div className={className}>{children}</div>;
  }
}
