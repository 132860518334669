import { IRefinementFilter } from './IRefinementFilter';

export function getSearchQueryRefinementFilters(refinementFilters: IRefinementFilter[]): string[] {
  return refinementFilters.map(getQueryRefinementFilter);
}

function getQueryRefinementFilter(filter: IRefinementFilter): string {
  const escapedValues: string[] = escapeFilterValues(filter.values);
  const serializedFilterValue: string = serializeFilterValue(escapedValues);
  return `${filter.name}:${serializedFilterValue}`;
}

function escapeFilterValues(values: string[]): string[] {
  return values.map(value => !isRefinementValueToken(value) && !isRefinementValueDateRange(value)
    ? `"${value}"`
    : value);
}

function serializeFilterValue(filterValues: string[]): string {
  return filterValues.length === 1 ? filterValues[0] : `or(${filterValues.join(',')})`;
}

function isRefinementValueToken(refinementValue: string): boolean {
  return refinementValue.indexOf('ǂǂ') !== -1;
}

function isRefinementValueDateRange(refinementValue: string): boolean {
  const rangeRegExp: RegExp = new RegExp('range\(.*,.*,from="ge",to="le"\)');
  return rangeRegExp.test(refinementValue);
}
