import { createMuiTheme } from '@material-ui/core';

export const theme = createMuiTheme({
  typography: {
    body2: {
      fontSize: 16,
    },
    fontFamily: 'SourceSansPro-Regular',
    useNextVariants: true,
  },
});
