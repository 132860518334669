// tslint:disable-next-line:no-submodule-imports
import { createStyles, Theme } from '@material-ui/core/styles';

/* tslint:disable:no-big-function object-literal-sort-keys no-magic-numbers */
export const searchableSelectStyles = ({spacing, typography}: Theme) => createStyles({
  input: {
    'display': 'flex',
    'padding': 0,
    '& input': {
      fontFamily: typography.body2.fontFamily,
      width: '100% !important',
    },
  },
  singleValue: {
    overflow: 'hidden',
    position: 'absolute',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    top: '50%',
    transform: 'translateY(-50%)',
    boxSizing: 'border-box',
    width: '100%',
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    padding: '0.375rem 0 0.4375rem 0',
    position: 'relative',
    height: '1.1875rem',
    overflow: 'hidden',
    fontSize: `${typography.body2.fontSize} !important`,
    fontFamily: `${typography.body2.fontFamily} !important`,
  },
  noOptionsMessage: {
    padding: `${spacing.unit}px ${spacing.unit * 2}px`,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: spacing.unit,
    left: 0,
    minWidth: '100%',
  },
  dropdownIndicator: {
    fontSize: '1.5rem',
    color: 'rgba(0, 0, 0, 0.54)',
  },
});
