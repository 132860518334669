import React from 'react';
import { connect } from 'react-redux';
import { config } from '../../config/Config';
import { RootState } from '../../store/types/RootState';
import { loadTermSet } from '../../termSets/TermSetActions';
import { getTermSetByIdFromState } from '../../termSets/TermSetUtils';
import { IProcessMapMenuLinkContainerDispatchProps } from './IProcessMapMenuLinkContainerDispatchProps';
import { IProcessMapMenuLinkContainerStateProps } from './IProcessMapMenuLinkContainerStateProps';
import { ProcessMapMenuLink } from './ProcessMapMenuLink';

class ProcessMapMenuLinkContainer extends React.PureComponent<
  IProcessMapMenuLinkContainerDispatchProps & IProcessMapMenuLinkContainerStateProps
> {

  public componentDidMount(): void {
    this.props.onLoadTermSet(config.predefinedFilters.scopeOfService.termSetId);
  }

  public render(): JSX.Element {
    const { scopeOfServiceTermSet, scopeOfServiceTermId } = this.props;
    const scopeOfServiceTerm = scopeOfServiceTermSet.terms.find(term => term.id === scopeOfServiceTermId);
    return <ProcessMapMenuLink term={scopeOfServiceTerm} />;
  }
}

const mapStateToProps = (state: RootState): IProcessMapMenuLinkContainerStateProps => ({
  scopeOfServiceTermId: state.preferences.scopeOfServiceTermId,
  scopeOfServiceTermSet: getTermSetByIdFromState(state, config.predefinedFilters.scopeOfService.termSetId),
});

const dispatchProps: IProcessMapMenuLinkContainerDispatchProps = {
  onLoadTermSet: loadTermSet.request,
};

const processMapMenuLinkContainer = connect(
  mapStateToProps,
  dispatchProps,
)(ProcessMapMenuLinkContainer);

export {
  processMapMenuLinkContainer as ProcessMapMenuLinkContainer,
};
