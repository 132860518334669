import * as React from 'react';
import { connect } from 'react-redux';
import { TaxonomySelector } from '../../components/taxonomySelector/TaxonomySelector';
import { config } from '../../config/Config';
import { localizedStrings } from '../../localization/LocalizedStrings';
import { RootState } from '../../store/types/RootState';
import { loadTermSet } from '../../termSets/TermSetActions';
import { getTermSetByIdFromState } from '../../termSets/TermSetUtils';
import { applyPredefinedFilter, applyPredefinedFilters } from '../SearchActions';
import { IPredefinedFiltersDispatchProps } from './IPredefinedFiltersDispatchProps';
import { IPredefinedFiltersProps } from './IPredefinedFiltersProps';
import { IPredefinedFiltersStateProps } from './IPredefinedFiltersStateProps';

class PredefinedFiltersContainer extends React.PureComponent<IPredefinedFiltersProps> {

  public componentDidMount(): void {
    this.props.loadTermSet(config.predefinedFilters.businessUnit.termSetId);
    this.props.loadTermSet(config.predefinedFilters.country.termSetId);
    this.props.loadTermSet(config.predefinedFilters.languages.termSetId);
  }

  // tslint:disable-next-line: no-big-function
  public render(): JSX.Element {
    return (
      <>
        <TaxonomySelector
          inputId="business-unit-predefined-filter"
          treeView={true}
          collapsible={true}
          expandOnlyFirstLevel={true}
          label={localizedStrings.businessUnitPredefinedFilterPlaceholder}
          onChangeSelection={this.handleBusinessUnitChange}
          termSet={this.props.businessUnitTermSet}
          selectedId={this.props.businessUnit}
        />
        <TaxonomySelector
          inputId="country-predefined-filter"
          treeView={false}
          label={localizedStrings.countryPredefinedFilterPlaceholder}
          onChangeSelection={this.handleCountryChange}
          termSet={this.props.countryTermSet}
          selectedId={this.props.country}
        />
        <TaxonomySelector
          inputId="languages-predefined-filter"
          treeView={false}
          label={localizedStrings.languagePredefinedFilterPlaceholder}
          multiSelect={true}
          onMultipleChangeSelection={this.handleLanguagesChange}
          termSet={this.props.languageTermSet}
          selectedIds={this.props.languages}
        />
      </>
    );
  }

  private readonly handleBusinessUnitChange = (selectedId: string) => {
    this.props.applyFilter(config.predefinedFilters.businessUnit.termSetId, selectedId);
  }

  private readonly handleCountryChange = (selectedId: string) => {
    this.props.applyFilter(config.predefinedFilters.country.termSetId, selectedId);
  }

  private readonly handleLanguagesChange = (selectedIds: string[]) => {
    this.props.applyFilters(config.predefinedFilters.languages.termSetId, selectedIds);
  }
}

const mapStateToProps = (state: RootState): IPredefinedFiltersStateProps => ({
  businessUnit: state.search.businessUnitTermId,
  businessUnitTermSet: getTermSetByIdFromState(state, config.predefinedFilters.businessUnit.termSetId),
  country: state.search.countryTermId,
  countryTermSet: getTermSetByIdFromState(state, config.predefinedFilters.country.termSetId),
  languageTermSet: getTermSetByIdFromState(state, config.predefinedFilters.languages.termSetId),
  languages: state.search.languageTermIds,
});

const dispatchProps: IPredefinedFiltersDispatchProps = {
  applyFilter: (termSetId, value) => applyPredefinedFilter({
    termSetId,
    value,
  }),
  applyFilters: (termSetId, values) => applyPredefinedFilters({
    termSetId,
    values,
  }),
  loadTermSet: loadTermSet.request,
};

const predefinedFiltersContainer = connect(
  mapStateToProps,
  dispatchProps,
)(PredefinedFiltersContainer);

export {
  predefinedFiltersContainer as PredefinedFiltersContainer,
};
