import React from 'react';
import { connect } from 'react-redux';
import { houseConfig } from '../../config/HouseConfig';
import { RootState } from '../../store/types/RootState';
import { loadTermSet } from '../../termSets/TermSetActions';
import { getTermSetByIdFromState } from '../../termSets/TermSetUtils';
import { HouseConfigurationMessage } from './HouseConfigurationMessage';
import { HousePage } from './HousePage';
import { getPredefinedUrlParameters } from './HousePageUtils';
import { IHousePageContainerDispatchProps } from './IHousePageContainerDispatchProps';
import { IHousePageContainerProps } from './IHousePageContainerProps';
import { IHousePageContainerStateProps } from './IHousePageContainerStateProps';
import { MobileHousePage } from './MobileHousePage';

class HousePageContainer extends React.PureComponent<IHousePageContainerProps> {

  public render(): JSX.Element | null {
    const { businessUnitTermId, countryTermId, languageTermIds, termSet } = this.props;
    if (termSet.id === '') {
      // tslint:disable-next-line:no-null-keyword
      return null;
    }
    if (this.arePredefinedFiltersSet()) {
      const urlParameters = getPredefinedUrlParameters(businessUnitTermId, countryTermId, languageTermIds);
      return (
        <div className="om-house">
          <div className="desktop-only">
            <HousePage termSet={termSet} predefinedUrlParameters={urlParameters} />
          </div>
          <div className="mobile-only">
            <MobileHousePage predefinedUrlParameters={urlParameters} />
          </div>
        </div>
      );
    }
    return <HouseConfigurationMessage />;
  }

  public componentDidMount(): void {
    this.props.onLoaded();
  }

  private arePredefinedFiltersSet(): boolean {
    const { businessUnitTermId, countryTermId } = this.props;
    return businessUnitTermId !== '' && countryTermId !== '';
  }
}

const mapStateToProps = (state: RootState): IHousePageContainerStateProps => ({
  businessUnitTermId: state.preferences.businessUnitTermId,
  countryTermId: state.preferences.countryTermId,
  languageTermIds: state.preferences.languageTermIds,
  termSet: getTermSetByIdFromState(state, houseConfig.termSetId),
});

const dispatchProps: IHousePageContainerDispatchProps = {
  onLoaded: () => loadTermSet.request(houseConfig.termSetId),
};

const housePageContainer = connect(
  mapStateToProps,
  dispatchProps,
)(HousePageContainer);

export {
  housePageContainer as HousePageContainer,
};
