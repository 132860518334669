import React from 'react';
import { IFloorProps } from '../common/IFloorProps';
import { FloorItem } from './FloorItem';

export class Floor extends React.PureComponent<IFloorProps> {

  public render(): JSX.Element {
    const { childTerms, columnClass, borderClass } = this.props;
    const className = `row om-bordered ${borderClass}`;

    return (
      <div className={className}>
        {childTerms.map(term => <FloorItem key={term.redirectUrl} {...term} columnClass={columnClass} />)}
      </div>
    );
  }
}
