// tslint:disable: max-file-line-count
import { combineReducers } from 'redux';
import { getType } from 'typesafe-actions';
import { config } from '../config/Config';
import { SortDirection } from '../lib/entities/SortDirection';
import { ITaxonomyMultiPreferenceInfo } from '../preferences/common/taxonomy/ITaxonomyMultiPreferenceInfo';
import { ITaxonomyPreferenceInfo } from '../preferences/common/taxonomy/ITaxonomyPreferenceInfo';
import { IDocumentDetails } from './documents/services/IDocumentDetails';
import { IDocumentRefinementFilter } from './documents/services/IDocumentRefinementFilter';
import { IDocumentsSearchResult } from './documents/services/IDocumentsSearchResult';
import { ISelectedDocumentInfo } from './documents/services/ISelectedDocumentInfo';
import { ISearchState } from './ISearchState';
import {
  getBusinessUnitFromLocation,
  getCountryFromLocation,
  getLanguagesFromLocation,
  getProcessTypeFromLocation,
  getQueryFromLocation,
  getRefinementFiltersFromLocation,
  getSelectedDocumentInfoFromLocation,
  getSortByFromLocation,
  getSortDirectionFromLocation,
} from './location/SearchLocation';
import { updateRefinementFilters } from './refiners/RefinerUtils';
import * as actions from './SearchActions';
import { SearchAction } from './types/SearchAction';

const defaultSearchResult: IDocumentsSearchResult = {
  documents: [],
  refinements: [],
  total: 0,
};

const defaultErrorMessage: string = '';

const defaultIsLoading: boolean = true;

const defaultIsFiltersPanelExpanded = false;

const defaultIsSortMenuOpened: boolean = false;

const defaultQueryText: string = '';

const defaultRefinementFilters: IDocumentRefinementFilter[] = [];

const defaultSortBy: string = '';

const defaultSortDirection: SortDirection = SortDirection.ascending;

const defaultPredefinedFilter: string = '';

const defaultPredefinedFilters: string[] = [];

const defaultProcessType: string = '';

const defaultSelectedDocumentDetails: IDocumentDetails = {
  approvalDate: '',
  approver: '',
  authors: [],
  businessUnits: [],
  comment: '',
  countries: [],
  documentType: '',
  errorMessage: '',
  id: 0,
  languages: [],
  listId: '',
  owner: '',
  processTypes: [],
  subProcesses: [],
  tags: [],
  title: '',
  version: '',
};

const defaultSelectedDocumentInfo: ISelectedDocumentInfo = {
  listId: '',
  listItemId: 0,
};

function getResult(
  searchResult: IDocumentsSearchResult = defaultSearchResult,
  action: SearchAction,
): IDocumentsSearchResult {
  switch (action.type) {
    case getType(actions.resetSearchParameters):
      return defaultSearchResult;
    case getType(actions.searchAsync.success):
      return action.payload;
    default:
      return searchResult;
  }
}

function getErrorMessage(message: string = defaultErrorMessage, action: SearchAction): string {
  switch (action.type) {
    case getType(actions.searchAsync.failure):
      return action.payload;
    case getType(actions.resetSearchParameters):
      return defaultErrorMessage;
    default:
      return message;
  }
}

// tslint:disable-next-line:mccabe-complexity
function getIsLoading(isLoading: boolean = defaultIsLoading, action: SearchAction): boolean {
  switch (action.type) {
    case getType(actions.searchAsync.request):
    case getType(actions.showSpinner):
      return true;
    case getType(actions.searchAsync.success):
    case getType(actions.searchAsync.failure):
      return false;
    case getType(actions.resetSearchParameters):
      return defaultIsLoading;
    default:
      return isLoading;
  }
}

function getIsFiltersPanelExpanded(
  isFiltersPanelExpanded: boolean = defaultIsFiltersPanelExpanded,
  action: SearchAction,
): boolean {
  return action.type === getType(actions.toggleFiltersPanel)
    ? !isFiltersPanelExpanded
    : isFiltersPanelExpanded;
}

function getIsSortMenuOpened(isSortMenuOpened: boolean = defaultIsSortMenuOpened, action: SearchAction): boolean {
  switch (action.type) {
    case getType(actions.openSortMenu):
      return true;
    case getType(actions.closeSortMenu):
    case getType(actions.sort):
      return false;
    case getType(actions.resetSearchParameters):
      return defaultIsSortMenuOpened;
    default:
      return isSortMenuOpened;
  }
}

// tslint:disable-next-line:cyclomatic-complexity mccabe-complexity max-union-size
function getLanguageTermIds(languages: string[] = defaultPredefinedFilters, action: SearchAction): string[] {
  switch (action.type) {
    case getType(actions.applyPredefinedFilter):
      return getLanguagesFromActionPayload(languages, action.payload);
    case getType(actions.applyPredefinedFilters):
      return getLanguagesFromMultiActionPayload(languages, action.payload);
    case getType(actions.updateStateFromUrl):
      return getLanguagesFromUrl();
    case getType(actions.resetSearchParameters):
    case getType(actions.resetFilters):
      return defaultPredefinedFilters;
    default:
      return languages;
  }
}

function getLanguagesFromActionPayload(languages: string[], payload: ITaxonomyPreferenceInfo): string[] {
  if (payload.termSetId !== config.predefinedFilters.languages.termSetId) {
    return languages;
  }
  return languages.filter(lang => lang !== payload.value);
}

function getLanguagesFromMultiActionPayload(languages: string[], payload: ITaxonomyMultiPreferenceInfo): string[] {
  return payload.termSetId === config.predefinedFilters.languages.termSetId ? payload.values : languages;
}

function getLanguagesFromUrl(): string[] {
  const languagesFromLocation = getLanguagesFromLocation();
  return languagesFromLocation !== undefined ? languagesFromLocation : defaultPredefinedFilters;
}

function getProcessTypeTermId(processType: string = defaultProcessType, action: SearchAction): string {
  switch (action.type) {
    case getType(actions.updateStateFromUrl):
      return getProcessTypeFromUrl();
    case getType(actions.resetSearchParameters):
      return defaultProcessType;
    default:
      return processType;
  }
}

function getProcessTypeFromUrl(): string {
  const processTypeFromLocation = getProcessTypeFromLocation();
  return processTypeFromLocation !== undefined ? processTypeFromLocation : defaultPredefinedFilter;
}

function getQueryText(queryText: string = defaultQueryText, action: SearchAction): string {
  switch (action.type) {
    case getType(actions.query):
      return action.payload;
    case getType(actions.updateStateFromUrl):
      return getQueryFromUrl();
    case getType(actions.resetSearchParameters):
      return defaultQueryText;
    default:
      return queryText;
  }
}

function getQueryFromUrl(): string {
  const query = getQueryFromLocation();
  return query !== undefined ? query : defaultQueryText;
}

function getBusinessUnitTermId(businessUnit: string = defaultPredefinedFilter, action: SearchAction): string {
  switch (action.type) {
    case getType(actions.applyPredefinedFilter):
      return getBusinessUnitFromActionPayload(businessUnit, action.payload);
    case getType(actions.updateStateFromUrl):
      return getBusinessUnitFromUrl();
    case getType(actions.resetSearchParameters):
    case getType(actions.resetFilters):
      return defaultProcessType;
    default:
      return businessUnit;
  }
}

function getBusinessUnitFromActionPayload(businessUnit: string, payload: ITaxonomyPreferenceInfo): string {
  return payload.termSetId === config.predefinedFilters.businessUnit.termSetId ? payload.value : businessUnit;
}

function getBusinessUnitFromUrl(): string {
  const businessUnitFromLocation = getBusinessUnitFromLocation();
  return businessUnitFromLocation !== undefined ? businessUnitFromLocation : defaultPredefinedFilter;
}

function getCountryTermId(country: string = defaultPredefinedFilter, action: SearchAction): string {
  switch (action.type) {
    case getType(actions.applyPredefinedFilter):
      return getCountryFromActionPayload(country, action.payload);
    case getType(actions.updateStateFromUrl):
      return getCountryFromUrl();
    case getType(actions.resetSearchParameters):
    case getType(actions.resetFilters):
      return defaultPredefinedFilter;
    default:
      return country;
  }
}

function getCountryFromActionPayload(country: string, payload: ITaxonomyPreferenceInfo): string {
  return payload.termSetId === config.predefinedFilters.country.termSetId ? payload.value : country;
}

function getCountryFromUrl(): string {
  const countryFromLocation = getCountryFromLocation();
  return countryFromLocation !== undefined ? countryFromLocation : defaultPredefinedFilter;
}

// tslint:disable-next-line:mccabe-complexity
function getRefinementFilters(
  filters: IDocumentRefinementFilter[] = defaultRefinementFilters,
  action: SearchAction,
): IDocumentRefinementFilter[] {
  switch (action.type) {
    case getType(actions.resetSearchParameters):
    case getType(actions.resetFilters):
      return defaultRefinementFilters;
    case getType(actions.applyRefinementFilter):
      return updateRefinementFilters(filters, action.payload);
    case getType(actions.updateStateFromUrl):
      return getRefinementFiltersFromUrl();
    default:
      return filters;
  }
}

function getRefinementFiltersFromUrl(): IDocumentRefinementFilter[] {
  const refinementFilters = getRefinementFiltersFromLocation();
  return refinementFilters !== undefined ? refinementFilters : defaultRefinementFilters;
}

function getSortBy(sortBy: string = defaultSortBy, action: SearchAction): string {
  switch (action.type) {
    case getType(actions.sort):
      return action.payload !== undefined ? action.payload.propertyName : defaultSortBy;
    case getType(actions.updateStateFromUrl):
      return getSortByFromUrl();
    case getType(actions.resetSearchParameters):
      return defaultSortBy;
    default:
      return sortBy;
  }
}

function getSortByFromUrl(): string {
  const sortByParameter = getSortByFromLocation();
  return sortByParameter !== undefined ? sortByParameter : defaultSortBy;
}

function getSortDirection(sortDirection: SortDirection = defaultSortDirection, action: SearchAction): SortDirection {
  switch (action.type) {
    case getType(actions.sort):
      return action.payload !== undefined ? action.payload.direction : defaultSortDirection;
    case getType(actions.updateStateFromUrl):
      return getSortDirectionFromUrl();
    case getType(actions.resetSearchParameters):
      return defaultSortDirection;
    default:
      return sortDirection;
  }
}

function getSortDirectionFromUrl(): SortDirection {
  const sortDirParameter = getSortDirectionFromLocation();
  return sortDirParameter !== undefined ? sortDirParameter : defaultSortDirection;
}

function getSelectedDocumentDetails(
  selectedDocumentDetails: IDocumentDetails = defaultSelectedDocumentDetails,
  action: SearchAction,
): IDocumentDetails {
  switch (action.type) {
    case getType(actions.selectDocument):
    case getType(actions.loadDocumentDetailsAsync.request):
      return defaultSelectedDocumentDetails;
    case getType(actions.loadDocumentDetailsAsync.success):
      return action.payload;
    default:
      return selectedDocumentDetails;
  }
}

// tslint:disable-next-line: mccabe-complexity
function getSelectedDocumentInfo(
  selectedDocumentInfo: ISelectedDocumentInfo = defaultSelectedDocumentInfo,
  action: SearchAction,
): ISelectedDocumentInfo {
  switch (action.type) {
    case getType(actions.selectDocument):
      return resolveSelectedDocumentInfo(selectedDocumentInfo, action.payload);
    case getType(actions.updateStateFromUrl):
      return getSelectedDocumentInfoFromUrl(selectedDocumentInfo);
    case getType(actions.applyPredefinedFilter):
    case getType(actions.applyPredefinedFilters):
    case getType(actions.applyRefinementFilter):
    case getType(actions.query):
    case getType(actions.resetFilters):
    case getType(actions.resetSearch):
    case getType(actions.resetSearchParameters):
    case getType(actions.sort):
      return defaultSelectedDocumentInfo;
    default:
      return selectedDocumentInfo;
  }
}

function resolveSelectedDocumentInfo(
  selectedDocumentInfo: ISelectedDocumentInfo,
  newSelectedDocumentInfo: ISelectedDocumentInfo | undefined,
): ISelectedDocumentInfo {
  if (newSelectedDocumentInfo !== undefined) {
    const { listId, listItemId } = selectedDocumentInfo;
    const { listId: newListId, listItemId: newListItemId } = newSelectedDocumentInfo;
    if (listId !== newListId || listItemId !== newListItemId) {
      return newSelectedDocumentInfo;
    }
  }
  return defaultSelectedDocumentInfo;
}

function getSelectedDocumentInfoFromUrl(currentSelectedDocumentInfo: ISelectedDocumentInfo): ISelectedDocumentInfo {
  const selectedDocumentInfoFromUrl = getSelectedDocumentInfoFromLocation();
  if (selectedDocumentInfoFromUrl === undefined) {
    return defaultSelectedDocumentInfo;
  }
  const { listId, listItemId } = currentSelectedDocumentInfo;
  const { listId: urlListId, listItemId: urlListItemId } = selectedDocumentInfoFromUrl;
  return listId === urlListId && listItemId === urlListItemId
    ? currentSelectedDocumentInfo
    : selectedDocumentInfoFromUrl;
}

export const searchReducer = combineReducers<ISearchState, SearchAction>({
  businessUnitTermId: getBusinessUnitTermId,
  countryTermId: getCountryTermId,
  errorMessage: getErrorMessage,
  isFiltersPanelExpanded: getIsFiltersPanelExpanded,
  isLoading: getIsLoading,
  isSortMenuOpened: getIsSortMenuOpened,
  languageTermIds: getLanguageTermIds,
  processTypeTermId: getProcessTypeTermId,
  queryText: getQueryText,
  refinementFilters: getRefinementFilters,
  result: getResult,
  selectedDocumentDetails: getSelectedDocumentDetails,
  selectedDocumentInfo: getSelectedDocumentInfo,
  sortBy: getSortBy,
  sortDirection: getSortDirection,
});
