import * as React from 'react';
import { SearchPageContainer } from './SearchPageContainer';

export class ResultsPageContainer extends React.PureComponent {

  // tslint:disable-next-line:prefer-function-over-method
  public render(): JSX.Element {
    return <SearchPageContainer hideSearchBox={true} {...this.props} />;
  }
}
