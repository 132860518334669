import { resultsRoute, subProcessRoute } from '../../entities/Routs';
import { ITaxonomyTerm } from '../../lib/taxonomy/ITaxonomyTerm';
import { ISearchUrlParameters } from '../../search/location/ISearchUrlParameters';
import { serializeSearchParameters } from '../../search/location/SearchLocation';

export function getHouseLinkUrl(term: ITaxonomyTerm, predefinedUrlParameters?: ISearchUrlParameters): string {
  return term.termsCount > 0 ? getSubProcessLinkUrl(term.id) : getSearchLinkUrl(term.id, predefinedUrlParameters);
}

export function getSearchLinkUrl(termId: string, predefinedUrlParameters: ISearchUrlParameters | undefined): string {
  const parameters = getSearchUrlParameters(termId, predefinedUrlParameters);
  const serializedParameters = serializeSearchParameters(parameters);
  return `${resultsRoute}?${serializedParameters}`;
}

export function getSubProcessLinkUrl(termId: string): string {
  return `${subProcessRoute}/${termId}`;
}

function getSearchUrlParameters(
  termId: string,
  predefinedUrlParameters: ISearchUrlParameters | undefined,
): ISearchUrlParameters {
  return {
    ...predefinedUrlParameters,
    process: termId,
  };
}
