export const searchRoute: string = '/search';

export const resultsRoute: string = '/results';

export const preferencesRoute: string = '/preferences';

export const subProcessRoute: string = '/process';

export const subProcessRouteWithParameters: string = `${subProcessRoute}/:termId`;

export const houseRoute: string = '/';
