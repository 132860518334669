import { getTermSetById } from './CachedTaxonomy';
import { ITaxonomySearchTerm } from './ITaxonomySearchTerm';
import { ITaxonomySearchValue } from './ITaxonomySearchValue';
import { findTermById, getTermLabel } from './Taxonomy';

const termSetLabelPrefix = 'GTSet|#';

const termLabelPrefix = 'L0|#0';

const valuesSeparator1 = ';';

const valuesSeparator2 = '\n\n';

const termIdIndex = 0;

const termLabelIndex = 1;

export async function getSingleTermLabel(value: string | undefined): Promise<string> {
  const labels = await getMultipleTermLabels(value);
  return labels.length > 0 ? labels[0] : '';
}

export async function getMultipleTermLabels(value: string | undefined): Promise<string[]> {
  const taxonomyValue = parseTaxonomySearchValue(value);
  return taxonomyValue !== undefined && taxonomyValue.terms.length > 0
    ? getTermLocalizedLabels(taxonomyValue.termSetId, taxonomyValue.terms)
    : [];
}

export function parseTaxonomySearchValue(value?: string): ITaxonomySearchValue | undefined {
  if (isValidTaxonomySearchValue(value)) {
    const stringValue = value as string;
    const valueParts = stringValue.indexOf(valuesSeparator1) !== -1
      ? stringValue.split(valuesSeparator1)
      : stringValue.split(valuesSeparator2);
    const termSetId = valueParts
      .filter(part => part.indexOf(termSetLabelPrefix) === 0)
      .map(part => part.substring(termSetLabelPrefix.length))[0];
    const termValues = valueParts
      .filter(part => part.indexOf(termLabelPrefix) === 0)
      .map(part => part.substring(termLabelPrefix.length));
    return {
      termSetId,
      terms: termValues.map(parseSearchTerm),
    };
  }
  return undefined;
}

export function isValidTaxonomySearchValue(value?: string): boolean {
  return value !== undefined
    && value.indexOf('GP0|#') === 0
    && value.indexOf(termSetLabelPrefix) > -1
    && value.indexOf(termLabelPrefix) > -1;
}

function parseSearchTerm(termValue: string): ITaxonomySearchTerm {
  const parts = termValue.split('|');
  return {
    id: parts[termIdIndex],
    label: parts[termLabelIndex],
  };
}

async function getTermLocalizedLabels(termSetId: string, searchTerms: ITaxonomySearchTerm[]): Promise<string[]> {
  const termSet = await getTermSetById(termSetId);
  if (termSet !== undefined) {
    return searchTerms.map(searchTerm => {
      const term = findTermById(termSet, searchTerm.id);
      return term !== undefined ? getTermLabel(term) : searchTerm.label;
    });
  }
  return searchTerms.map(searchTerm => searchTerm.label);
}
