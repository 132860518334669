import * as React from 'react';
import { Floor1AccordionContainer } from '../accordions/Floor1AccordionContainer';
import { Floor2AccordionContainer } from '../accordions/Floor2AccordionContainer';
import { Floor3AccordionContainer } from '../accordions/Floor3AccordionContainer';
import { RoofAccordionContainer } from '../accordions/RoofAccordionContainer';
import { IHousePageProps } from './IHousePageProps';

export class MobileHousePage extends React.PureComponent<IHousePageProps> {

  // tslint:disable-next-line:prefer-function-over-method
  public render(): JSX.Element {
    return (
      <div>
        <RoofAccordionContainer name="Accordion.Roof" />
        <Floor3AccordionContainer name="Accordion.Floor3" />
        <Floor2AccordionContainer name="Accordion.Floor2" />
        <Floor1AccordionContainer name="Accordion.Floor1" />
      </div>
    );
  }
}
