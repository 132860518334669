import * as React from 'react';
import { ITaxonomyTerm } from '../../lib/taxonomy/ITaxonomyTerm';
import { ITermSetInfo } from '../../lib/taxonomy/ITermSetInfo';
import { findTermById, getTermLabel } from '../../lib/taxonomy/Taxonomy';
import { ISearchUrlParameters } from '../../search/location/ISearchUrlParameters';
import { IFloorTerm } from '../common/IFloorTerm';
import { IHouseFloorConfig } from '../common/IHouseFloorConfig';
import { getHouseLinkUrl, getSearchLinkUrl } from '../common/Utils';
import { FloorHeader } from '../floorHeader/FloorHeader';

export function getFloorHeader(
  predefinedUrlParameters: ISearchUrlParameters | undefined,
  termSet: ITermSetInfo | undefined,
  termId: string,
  clickable: boolean,
  redirectToSearch: boolean = false,
  className?: string,
): JSX.Element {
  if (termSet !== undefined) {
    const term = findTermById(termSet, termId);
    if (term !== undefined) {
      const label = getTermLocalizedLabel(term);
      const url =  getFloorHeaderLinkUrl(term, predefinedUrlParameters, clickable, redirectToSearch);
      return <FloorHeader key={term.id} className={className} title={label} url={url} />;
    }
  }
  return <span />;
}

export function getFloorTerms(
  floorConfig: IHouseFloorConfig,
  termSet: ITermSetInfo | undefined,
  predefinedUrlParameters: ISearchUrlParameters | undefined,
): IFloorTerm[] {
  const children: IFloorTerm[] = [];
  if (termSet !== undefined && floorConfig.children !== undefined) {
    floorConfig.children.forEach(child => {
      const term = findTermById(termSet, child.termId);
      if (term !== undefined) {
        const floorTerm = getFloorTerm(termSet, term, child, predefinedUrlParameters);
        children.push(floorTerm);
      }
    });
  }
  return children;
}

export function getPredefinedUrlParameters(
  businessUnitTermId: string,
  countryTermId: string,
  languageTermIds: string[],
): ISearchUrlParameters {
  return {
    bu: businessUnitTermId,
    country: countryTermId,
    lang: languageTermIds,
  };
}

function getFloorTerm(
  termSet: ITermSetInfo,
  term: ITaxonomyTerm,
  floorConfig: IHouseFloorConfig,
  predefinedUrlParameters: ISearchUrlParameters | undefined,
): IFloorTerm {
  return {
    childTerms: getFloorTerms(floorConfig, termSet, predefinedUrlParameters),
    columnClass: 'col-md-3',
    icon: floorConfig.icon,
    label: getTermLocalizedLabel(term),
    redirectUrl: getHouseLinkUrl(term, predefinedUrlParameters),
  };
}

function getFloorHeaderLinkUrl(
  term: ITaxonomyTerm,
  predefinedUrlParameters: ISearchUrlParameters | undefined,
  clickable: boolean,
  redirectToSearch: boolean,
): string {
  if (clickable) {
    return redirectToSearch
      ? getSearchLinkUrl(term.id, predefinedUrlParameters)
      : getHouseLinkUrl(term, predefinedUrlParameters);
  }
  return '';
}

function getTermLocalizedLabel(term: ITaxonomyTerm | undefined): string {
  return term !== undefined ? getTermLabel(term) : '';
}
