import React from 'react';
// tslint:disable-next-line:no-submodule-imports
import { IndicatorProps } from 'react-select/lib/components/indicators';

export class IndicatorSeparator<TOption> extends React.PureComponent<IndicatorProps<TOption>> {

  // tslint:disable-next-line:prefer-function-over-method
  public render(): JSX.Element | null {
    // tslint:disable-next-line:no-null-keyword
    return null;
  }
}
