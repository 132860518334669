import React from 'react';
import { Link } from 'react-router-dom';
import { HouseTitle } from './HouseTitle';
import { IHouseTitleLinkProps } from './IHouseTitleLinkProps';

export class HouseTitleLink extends React.PureComponent<IHouseTitleLinkProps> {

  public render(): JSX.Element {
    const { title, url } = this.props;
    return (
      <Link to={url} className="house-header-link">
        <HouseTitle title={title} />
      </Link>
    );
  }
}
