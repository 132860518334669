import * as React from 'react';
import { connect } from 'react-redux';
import { ISortProperty } from '../../lib/entities/ISortProperty';
import { sort } from '../../search/SearchActions';
import { RootState } from '../../store/types/RootState';
import { HeadCell } from './HeadCell';
import { IHeadCellContainerDispatchProps } from './IHeadCellContainerDispatchProps';
import { IHeadCellContainerProps } from './IHeadCellContainerProps';
import { IHeadCellContainerStateProps } from './IHeadCellContainerStateProps';

class HeadCellContainer extends React.PureComponent<IHeadCellContainerProps> {

  public render(): JSX.Element {
    const { label, sortable, stateSortProperty, stateSortDirection, sortProperty } = this.props;
    return (
      <HeadCell
        className={this.props.className}
        label={label}
        sortable={sortable}
        sortProperty={sortProperty}
        sortDirection={stateSortProperty === sortProperty ? stateSortDirection : undefined}
        onSort={this.handleOnSort}
      />
    );
  }

  private readonly handleOnSort = (sortProperty: ISortProperty) => {
    this.props.onSort(sortProperty);
  }
}

const mapStateToProps = (state: RootState): IHeadCellContainerStateProps => ({
  stateSortDirection: state.search.sortDirection,
  stateSortProperty: state.search.sortBy,
});

const dispatchProps: IHeadCellContainerDispatchProps = {
  onSort: sort,
};

const headCellContainer = connect(
  mapStateToProps,
  dispatchProps,
)(HeadCellContainer);

export {
  headCellContainer as HeadCellContainer,
};
