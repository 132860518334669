import * as React from 'react';
import { Accordion } from '../../accordion/Accordion';
import { IAccordionContainerProps } from './IAccordionContainerProps';

export abstract class BaseHouseRowAccordionContainer<TProps extends IAccordionContainerProps>
  extends React.PureComponent<TProps> {

  public render(): JSX.Element {
    return (
      <Accordion
        header={this.getHeaderElement()}
        contents={this.getContentsElement()}
        expanded={this.props.expanded}
        onToggle={this.raiseOnToggle}
      />
    );
  }

  public componentDidMount(): void {
    // tslint:disable-next-line:strict-type-predicates
    if (this.props.onRender !== undefined) {
      this.props.onRender(this.props.name);
    }
  }

  protected abstract getHeaderElement(): JSX.Element;

  protected abstract getContentsElement(): JSX.Element;

  private readonly raiseOnToggle = () => {
    // tslint:disable-next-line:strict-type-predicates
    if (this.props.onToggle !== undefined) {
      this.props.onToggle(this.props.name);
    }
  }
}
