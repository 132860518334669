import { replacePlaceholder } from '../placeholder/Replacer';
import { queryPlaceholder } from './SearchPlaceholders';

export function replaceSearchPlaceholders(queryTemplate: string, query?: string): string {
  return replaceSearchPlaceholder(queryTemplate, queryPlaceholder, query);
}

export function replaceSearchPlaceholder(queryTemplate: string, placeholder: string, value?: string): string {
  const normalizedValue = value !== undefined && value !== '' ? value : '*';
  return replacePlaceholder(queryTemplate, placeholder, normalizedValue);
}
