import { Typography } from '@material-ui/core';
import * as React from 'react';
import { localizedStrings } from '../../localization/LocalizedStrings';
import { BusinessUnitPredefinedFilterContainer } from '../businessUnit/BusinessUnitPredefinedFilterContainer';
import { CountryPredefinedFilterContainer } from '../country/CountryPredefinedFilterContainer';
import { HousePageLinkContainer } from '../housePageLink/HousePageLinkContainer';
import { LanguagesPredefinedFilterContainer } from '../languages/LanguagesPredefinedFilterContainer';
import { ScopeOfServicePredefinedFilterContainer } from '../scopeOfService/ScopeOfServicePredefinedFilterContainer';
import './PredefinedFilter.scss';

export class PredefinedFilterPage extends React.PureComponent {

  // tslint:disable-next-line:prefer-function-over-method
  public render(): JSX.Element {
    return (
      <div className="predefined-filter-page-wrapper">
        <div className="predefined-filter-page-container">
          <div className="page-title">
            <Typography variant="h1">{localizedStrings.predefinedFilterNavigationLink}</Typography>
          </div>
          <div className="filters-container">
            <BusinessUnitPredefinedFilterContainer />
            <CountryPredefinedFilterContainer />
            <ScopeOfServicePredefinedFilterContainer />
            <LanguagesPredefinedFilterContainer />
            <HousePageLinkContainer />
          </div>
        </div>
      </div>
    );
  }
}
