import { IObjectPathBatch } from '@pnp/sp-clientsvc';
import { ITermSet, ITermSetData, ITermStore, taxonomy } from '@pnp/sp-taxonomy';
import {
  defaultLanguageFieldName,
  keywordsTermSetFieldName,
  termCustomPropertiesFieldName,
  termCustomSortOrderFieldName,
  termIdFieldName,
  termIsAvailableForTaggingFieldName,
  termLabelsFieldName,
  termParentFieldName,
  termPathFieldName,
  termStoreIdFieldName,
  termTermsCountFieldName,
} from '../sharePoint/FieldNames';
import { IRawTerm } from './IRawTerm';
import { IRawTermSet } from './IRawTermSet';
import { IRawTermStore } from './IRawTermStore';

const termIncludeProperties: string[] = [
  termIdFieldName,
  termLabelsFieldName,
  termCustomPropertiesFieldName,
  termIsAvailableForTaggingFieldName,
  termPathFieldName,
  termParentFieldName,
  termTermsCountFieldName,
  termCustomSortOrderFieldName,
];

const termStoreIncludeProperties: string[] = [
  termStoreIdFieldName,
  defaultLanguageFieldName,
  keywordsTermSetFieldName,
];

export async function retrieveRawTermStore(): Promise<IRawTermStore> {
  return getTermStore()
    .select(...termStoreIncludeProperties)
    .get() as Promise<IRawTermStore>;
}

export async function retrieveRawTermSets(termSetIds: string[]): Promise<IRawTermSet[]> {
  const batch = taxonomy.createBatch();
  const termSetsWithTerms = termSetIds.map(async (id: string) => {
    const termSet = getTermSet(id);
    const termSetPromise = queryTermSet(termSet, batch);
    const termsPromise = queryTermSetTerms(termSet, batch);
    return Promise.all([termSetPromise, termsPromise]);
  });
  await batch.execute();
  const data = await Promise.all(termSetsWithTerms);
  return data.map(([termSet, terms]) => ({
    termSet,
    terms,
  }));
}

export async function retrieveTermDescription(termSetId: string, termId: string, lcid: number): Promise<string> {
  const term = getTermSet(termSetId)
    .getTermById(termId);
  return term.getDescription(lcid);
}

function getTermSet(termSetId: string): ITermSet {
  return getTermStore()
    .getTermSetById(termSetId);
}

function getTermStore(): ITermStore {
  return taxonomy.getDefaultSiteCollectionTermStore();
}

async function queryTermSet(termSet: ITermSet, batch: IObjectPathBatch): Promise<ITermSetData & ITermSet> {
  return termSet
    .inBatch(batch)
    .get();
}

async function queryTermSetTerms(termSet: ITermSet, batch: IObjectPathBatch): Promise<IRawTerm[]> {
  return termSet.terms
    .select(...termIncludeProperties)
    .inBatch(batch)
    .get() as Promise<IRawTerm[]>;
}
