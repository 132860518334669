import { Chip } from '@material-ui/core';
import React from 'react';
import { localizedStrings } from '../../../../localization/LocalizedStrings';
import { IClearFiltersButtonProps } from './IClearFiltersButtonProps';

export class ClearFiltersButton extends React.PureComponent<IClearFiltersButtonProps> {

  public render(): JSX.Element {
    return (
      <Chip
        label={localizedStrings.clearAllFiltersButton}
        clickable={true}
        onClick={this.handleClick}
        className="clear-button"
      />
    );
  }

  private readonly handleClick = (): void => {
    this.props.onClear();
  }
}
