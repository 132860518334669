import { combineReducers } from 'redux';
import { getAccordions } from '../accordion/AccordionUtils';
import { headerReducer } from '../header/HeaderReducer';
import { menuReducer } from '../menu/MenuReducer';
import { notificationReducer } from '../notification/NotificationReducer';
import { preferencesReducer } from '../preferences/PreferencesReducer';
import { searchReducer } from '../search/SearchReducer';
import { getTermDescriptions, getTermSets } from '../termSets/TermSetUtils';

export const rootReducer = combineReducers({
  accordions: getAccordions,
  header: headerReducer,
  menu: menuReducer,
  notification: notificationReducer,
  preferences: preferencesReducer,
  search: searchReducer,
  termDescriptions: getTermDescriptions,
  termSets: getTermSets,
});
