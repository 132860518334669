import * as React from 'react';
import { config } from '../../config/Config';
// tslint:disable-next-line:match-default-export-name
import logo from '../../images/HelpIcon.svg';
import './HelpLink.scss';

export class HelpLink extends React.PureComponent {

  // tslint:disable-next-line:prefer-function-over-method no-big-function
  public render(): JSX.Element {
    const link: string = config.navigation.helpUrl;
    return (
      <div className="help-logo">
        <a href={link} target="_blank">
          <div className="img-wrapper">
            <img src={logo} />
          </div>
        </a>
      </div>
    );
  }
}
