import { connect } from 'react-redux';
import { store } from '../store/Store';
import { RootState } from '../store/types/RootState';
import { IMenuDispatchProps } from './IMenuDispatchProps';
import { IMenuStateProps } from './IMenuStateProps';
import { Menu } from './Menu';
import * as menuActions from './MenuActions';

const mapStateToProps = (state: RootState): IMenuStateProps => ({
  touched: state.menu.touched,
  visible: state.menu.visible,
});

const dispatchProps: IMenuDispatchProps = {
  onMenuItemClick: () => menuActions.clickOnMenuItem(store.getState().menu.touched),
  onMenuOutsideClick: () => menuActions.toggleVisibility(store.getState().menu.touched),
};

const menuContainer = connect(
  mapStateToProps,
  dispatchProps,
)(Menu);

export {
  menuContainer as MenuContainer,
};
