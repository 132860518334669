import { Typography } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { IFloor2ItemProps } from './IFloor2ItemProps';

export class Floor2Item extends React.PureComponent<IFloor2ItemProps> {

  public render(): JSX.Element {
    const { title, url } = this.props;
    return (
      <div className="col-md-2 floor2-item">
        <Link to={url} className="floor-link">
          <Typography className="floor-link-text">{title}</Typography>
        </Link>
      </div>
    );
  }
}
