/* tslint:disable:match-default-export-name */
import controlling from '../images/house/Icon_Controlling.svg';
import corporateManagement from '../images/house/Icon_Corporate_Management.svg';
import development from '../images/house/Icon_Development.svg';
import developmentEvaluation from '../images/house/Icon_Development_Evaluation.svg';
import enterpriseRiskManagement from '../images/house/Icon_Enterprise_Risk_Management.svg';
import healthSafety from '../images/house/Icon_Health_Safety.svg';
import legal from '../images/house/Icon_Legal.svg';
import marketingCommunications from '../images/house/Icon_Marketing_Communications.svg';
import operations from '../images/house/Icon_Operations.svg';
import procurement from '../images/house/Icon_Procurement.svg';
import quality from '../images/house/Icon_Quality.svg';
import sustainability from '../images/house/Icon_Sustainability.svg';
import technicalDesignOffice from '../images/house/Icon_Technical_Design_Office.svg';
/* tslint:enable:match-default-export-name */

export const houseConfig = {
  floor1: {
    children: [
      {
        icon: technicalDesignOffice,
        termId: process.env.REACT_APP_PROJECT_SERVICES_TERM_ID as string,
      },
      {
        icon: legal,
        termId: process.env.REACT_APP_LEGAL_TERM_ID as string,
      },
      {
        icon: procurement,
        termId: process.env.REACT_APP_PROCUREMENT_TERM_ID as string,
      },
      {
        icon: marketingCommunications,
        termId: process.env.REACT_APP_MARKETING_COMMUNICATIONS_TERM_ID as string,
      },
      {
        icon: developmentEvaluation,
        termId: process.env.REACT_APP_BUSINESS_PLANNING_TERM_ID as string,
      },
      {
        icon: operations,
        termId: process.env.REACT_APP_SUPPORT_OPERATIONS_TERM_ID as string,
      },
      {
        icon: controlling,
        termId: process.env.REACT_APP_FINANCE_TERM_ID as string,
      },
      {
        icon: development,
        termId: process.env.REACT_APP_OPERATIONS_SUPPORT_TERM_ID as string,
      },
    ],
    termId: process.env.REACT_APP_SUPPORT_PROCESS_TERM_ID as string,
  },
  floor2: {
    termCustomProperty: 'CoreProcessTermId',
    termId: process.env.REACT_APP_CORE_PROCESS_TERM_ID as string,
  },
  floor3: {
    children: [
      {
        icon: corporateManagement,
        termId: process.env.REACT_APP_CORPORATE_MANAGEMENT_TERM_ID as string,
      },
      {
        icon: enterpriseRiskManagement,
        termId: process.env.REACT_APP_MANAGEMENT_PROCESS_CORPORATE_TERM_ID as string,
      },
      {
        icon: healthSafety,
        termId: process.env.REACT_APP_SAFETY_TERM_ID as string,
      },
      {
        icon: quality,
        termId: process.env.REACT_APP_QUALITY_TERM_ID as string,
      },
      {
        icon: sustainability,
        termId: process.env.REACT_APP_SUSTAINABILITY_TERM_ID as string,
      },
    ],
    termId: process.env.REACT_APP_MANAGEMENT_PROCESS_TERM_ID as string,
  },
  roofTermId: process.env.REACT_APP_LEADERSHIP_BEHAVIOUR_TERM_ID as string,
  termSetId: process.env.REACT_APP_PROCESS_TYPE_TERM_SET_ID as string,
};
