import { applyMiddleware, compose, createStore } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { rootEpic } from './RootEpic';
import { rootReducer } from './RootReducer';
import { rootService } from './RootService';
import { RootAction } from './types/RootAction';
import { RootService } from './types/RootService';
import { RootState } from './types/RootState';

const epicMiddleware = createEpicMiddleware<
  RootAction,
  RootAction,
  RootState,
  RootService
>({
  dependencies: rootService,
});

const middlewares = [epicMiddleware];

const enhancer = compose(applyMiddleware(...middlewares));

export const store = createStore(rootReducer, {}, enhancer);

// tslint:disable:no-unsafe-any
epicMiddleware.run(rootEpic);
