import { Typography } from '@material-ui/core';
import * as React from 'react';
import './DocumentTag.scss';
import { IDocumentTagProps } from './IDocumentTagProps';

export class DocumentTag extends React.PureComponent<IDocumentTagProps> {

  public render(): JSX.Element {
    return (
      <div className="tag-wrapper">
        <Typography className="tag-label" variant="caption">{this.props.title}</Typography>
      </div>
    );
  }
}
