import * as React from 'react';
import { IconButton } from '../components/iconButton/IconButton';
import { AccordionExpansionPanel } from './AccordionExpansionPanel';
import { AccordionExpansionPanelDetails } from './AccordionExpansionPanelDetails';
import { AccordionExpansionPanelSummary } from './AccordionExpansionPanelSummary';
import { IAccordionProps } from './IAccordionProps';

export class Accordion extends React.PureComponent<IAccordionProps> {

  public render(): JSX.Element {
    const { header, contents, expanded, onToggle } = this.props;
    const expandIcon = <IconButton iconClass="icon-Arrow-Drop-Down" />;
    return (
      <AccordionExpansionPanel expanded={expanded}>
        <AccordionExpansionPanelSummary className="accordion-summary" expandIcon={expandIcon} onClick={onToggle}>
          {header}
        </AccordionExpansionPanelSummary>
        <AccordionExpansionPanelDetails className="accordion-details">
          {contents}
        </AccordionExpansionPanelDetails>
      </AccordionExpansionPanel>
    );
  }
}
