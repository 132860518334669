import { Chip } from '@material-ui/core';
import React from 'react';
import { IRefinementFilterChipProps } from './IRefinementFilterChipProps';

export class RefinementFilterChip extends React.PureComponent<IRefinementFilterChipProps> {

  public render(): JSX.Element | null {
    return (
      <Chip
        label={this.props.label}
        onDelete={this.handleDelete}
      />
    );
  }

  private readonly handleDelete = (): void => {
    this.props.onRemove(this.props.refinementFilter, this.props.valueIndex);
  }
}
