import React from 'react';

// tslint:disable-next-line:no-any
export class Input extends React.PureComponent<any> {

  public render(): JSX.Element {
    const { inputRef, ...props } = this.props;
    return (
      // tslint:disable-next-line:no-any
      <div ref={inputRef as React.Ref<any>} {...props} />
    );
  }
}
