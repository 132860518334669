import { Paper, StyledComponentProps } from '@material-ui/core';
import React from 'react';
// tslint:disable-next-line:no-submodule-imports
import { MenuProps } from 'react-select/lib/components/Menu';

export class Menu<TOption> extends React.PureComponent<MenuProps<TOption>> {

  public render(): JSX.Element {
    const { innerProps, selectProps, children } = this.props;
    const styledProps = selectProps as StyledComponentProps;
    const className = styledProps.classes !== undefined ? styledProps.classes.paper : undefined;
    return (
      <Paper square={true} className={className} {...innerProps}>
        {children}
      </Paper>
    );
  }
}
