import { getCurrentLanguageLcid } from '../localization/Localization';
import { areArraysEqual } from '../utils/Array';
import { ITaxonomyTerm } from './ITaxonomyTerm';
import { ITermSetInfo } from './ITermSetInfo';
import { ITermStoreInfo } from './ITermStoreInfo';
import { retrieveRawTermSets, retrieveRawTermStore } from './RawTaxonomy';
import { convertTermSets, convertTermStore } from './TaxonomyConverter';

export async function retrieveTermSetsByIds(termSetIds: string[], termStoreLcid: number): Promise<ITermSetInfo[]> {
  const rawTermSets = await retrieveRawTermSets(termSetIds);
  return convertTermSets(rawTermSets, termStoreLcid);
}

export async function retrieveTermStore(): Promise<ITermStoreInfo> {
  const termStore = await retrieveRawTermStore();
  return convertTermStore(termStore);
}

export function getTermLabel(term: ITaxonomyTerm, lcid?: number): string {
  const currentLcid = lcid === undefined ? getCurrentLanguageLcid() : lcid;
  const foundLabels = term.labels
    .filter(termLabel => termLabel.lcid === currentLcid)
    .map(termLabel => termLabel.value);
  return foundLabels.length > 0 ? foundLabels[0] : term.defaultLanguageLabel;
}

export function getTermLabelWithParent(term: ITaxonomyTerm, parentTerm: ITaxonomyTerm, lcid?: number): string {
  const termLabel = getTermLabel(term, lcid);
  const parentTermLabel = getTermLabel(parentTerm, lcid);
  return `${parentTermLabel}:${termLabel}`;
}

export function findTermById(termSet: ITermSetInfo, termId: string): ITaxonomyTerm | undefined {
  return termSet.terms.find(term => term.id === termId);
}

export function findSingleTermByLabel(termSet: ITermSetInfo, termLabel: string): ITaxonomyTerm | undefined {
  return findTermsByLabel(termSet, termLabel)[0];
}

export function findTermsByLabel(termSet: ITermSetInfo, termLabel: string): ITaxonomyTerm[] {
  return termSet.terms.filter(term => term.labels.some(label => label.value === termLabel));
}

export function findTermByDefaultLanguageLabel(termSet: ITermSetInfo, termLabel: string): ITaxonomyTerm | undefined {
  return termSet.terms.find(term => term.defaultLanguageLabel === termLabel);
}

export function findTermByPath(termSet: ITermSetInfo, pathOfTerm: string[]): ITaxonomyTerm | undefined {
  const term = findTermByExactPath(termSet, pathOfTerm);
  return term !== undefined ? term : findTermByLocalizedPath(termSet, pathOfTerm);
}

export function findTermChildren(termSet: ITermSetInfo, termId: string): ITaxonomyTerm[] {
  return termSet.terms.filter(term => term.parentId === termId);
}

export function getSortedTermChildren(termSet: ITermSetInfo, termId: string): ITaxonomyTerm[] {
  const parentTerm = findTermById(termSet, termId);
  const children = findTermChildren(termSet, termId);
  if (parentTerm === undefined || parentTerm.customSortOrder === undefined) {
    return children;
  }
  const sortedChildren: ITaxonomyTerm[] = [];
  parentTerm.customSortOrder.forEach(childId => {
    const foundChild = children.find(child => child.id === childId);
    if (foundChild !== undefined) {
      sortedChildren.push(foundChild);
      const index = children.indexOf(foundChild);
      children.splice(index, 1);
    }
  });
  return [
    ...sortedChildren,
    ...children,
  ];
}

export function getTermParents(termSet: ITermSetInfo, termId: string): ITaxonomyTerm[] {
  const hierarchTerms = getTermHierarchy(termSet, termId);
  if (hierarchTerms.length > 0) {
    hierarchTerms.splice(hierarchTerms.length - 1, 1);
  }
  return hierarchTerms;
}

export function getTermHierarchy(termSet: ITermSetInfo, termId: string): ITaxonomyTerm[] {
  const hierarchy: ITaxonomyTerm[] = [];
  let currentTermId: string | undefined = termId;
  while (currentTermId !== undefined) {
    const currentTerm = findTermById(termSet, currentTermId);
    if (currentTerm !== undefined) {
      hierarchy.push(currentTerm);
      currentTermId = currentTerm.parentId;
    } else {
      currentTermId = undefined;
    }
  }
  return hierarchy.reverse();
}

function findTermByExactPath(termSet: ITermSetInfo, pathOfTerm: string[]): ITaxonomyTerm | undefined {
  return termSet.terms.find(term => areArraysEqual(term.pathOfTerm, pathOfTerm));
}

function findTermByLocalizedPath(termSet: ITermSetInfo, pathOfTerm: string[]): ITaxonomyTerm | undefined {
  const termLabel = pathOfTerm[pathOfTerm.length - 1];
  const termsFoundByLabelAndHierarchyLevel = termSet.terms.filter(term =>
    term.labels.some(label => label.value === termLabel) &&
    term.pathOfTerm.length === pathOfTerm.length,
  );
  const hierarchies = termsFoundByLabelAndHierarchyLevel.map(term => getTermHierarchy(termSet, term.id));
  const hierarchy = getHierarchyByPathOfTerm(hierarchies, pathOfTerm);
  return hierarchy !== undefined ? hierarchy[hierarchy.length - 1] : undefined;
}

function getHierarchyByPathOfTerm(hierarchies: ITaxonomyTerm[][], pathOfTerm: string[]): ITaxonomyTerm[] | undefined {
  return hierarchies.find(hierarchy => {
    for (let i = 0; i < hierarchy.length; i++) {
      const term = hierarchy[i];
      const termLabel = pathOfTerm[i];
      const hasLabel = term.labels.some(label => label.value === termLabel);
      if (!hasLabel) {
        return false;
      }
    }
    return true;
  });
}
