import { combineReducers } from 'redux';
import { getType } from 'typesafe-actions';
import { IUserProfile } from '../lib/user/IUserProfile';
import * as actions from './HeaderActions';
import { IHeaderState } from './IHeaderState';
import { HeaderAction } from './types/HeaderAction';

const defaultUserProfile: IUserProfile = {
};

function getUserProfile(userProfile: IUserProfile = defaultUserProfile, action: HeaderAction): IUserProfile {
  if (action.type === getType(actions.getUserInfoAsync.success)) {
    return action.payload;
  }
  return userProfile;
}

export const headerReducer = combineReducers<IHeaderState, HeaderAction>({
  userProfile: getUserProfile,
});
