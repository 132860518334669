import { config } from '../../../config/Config';
import { getListItem } from '../../../lib/listItem/ListItem';
import { localizedStrings } from '../../../localization/LocalizedStrings';
import { RootState } from '../../../store/types/RootState';
import { convertToDocumentDetails } from './DocumentConverter';
import { IDocumentDetails } from './IDocumentDetails';
import { IDocumentDetailsQueryResult } from './IDocumentDetailsQueryResult';

const documentDetailsSelectFields = [
  'FileLeafRef',
  'OData__UIVersionString',
  'Document_x0020_Type',
  'Business_x0020_Unit',
  'Process_x0020_Type',
  'Sub_x002d_process',
  'Applicable_x0020_Country',
  'Document_x0020_Language',
  'Individual_x0020_tag',
  'Author1/Title',
  'Owner/Title',
  'Approver/Title',
  'Modified',
  'Comment1',
];

const documentDetailsExpandFields = [
  'Approver',
  'Author1',
  'Owner',
];

export async function getDocumentDetailsUsingState(state: RootState): Promise<IDocumentDetails> {
  const { listId, listItemId } = state.search.selectedDocumentInfo;
  const item = await getListItem<IDocumentDetailsQueryResult>(
    config.sharepointUrl,
    listId,
    listItemId,
    documentDetailsSelectFields,
    documentDetailsExpandFields)
    .catch(error => {
      // tslint:disable-next-line:no-console
      console.error(error);
    });
  if (item === undefined) {
    return createDetailsFromError(
      listId,
      listItemId,
      localizedStrings.detailsPanelErrorMessage);
  }
  return convertToDocumentDetails(item, listId, listItemId);
}

function createDetailsFromError(listId: string, listItemId: number, errorMessage: string): IDocumentDetails {
  return {
    approvalDate: '',
    approver: '',
    authors: [],
    businessUnits: [''],
    comment: '',
    countries: [''],
    documentType: '',
    errorMessage,
    id: listItemId,
    languages: [''],
    listId,
    owner: '',
    processTypes: [''],
    subProcesses: [''],
    tags: [''],
    title: '',
    version: '',
  };
}
