import { ExpansionPanel, withStyles } from '@material-ui/core';

const expansionPanel = withStyles({
  expanded: {
    margin: '0',
  },
  root: {
    '&:before': {
      display: 'none',
    },
    'border': 'none',
    'boxShadow': 'none',
  },
})(ExpansionPanel);

export {
  expansionPanel as AccordionExpansionPanel,
};
