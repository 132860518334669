import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { houseConfig } from '../../config/HouseConfig';
import { getCurrentLanguageLcid } from '../../lib/localization/Localization';
import { findTermById, getSortedTermChildren } from '../../lib/taxonomy/Taxonomy';
import { ISearchUrlParameters } from '../../search/location/ISearchUrlParameters';
import { RootState } from '../../store/types/RootState';
import { loadTermDescription, loadTermSet } from '../../termSets/TermSetActions';
import { getTermDescriptionFromState, getTermSetByIdFromState } from '../../termSets/TermSetUtils';
import { ISubProcessPageContainerDispatchProps } from './ISubProcessPageContainerDispatchProps';
import { ISubProcessPageContainerProps } from './ISubProcessPageContainerProps';
import { ISubProcessPageContainerStateProps } from './ISubProcessPageContainerStateProps';
import { ISubProcessPageUrlParams } from './ISubProcessPageUrlParams';
import { SubProcessPage } from './SubProcessPage';

class SubProcessPageContainer extends React.PureComponent<ISubProcessPageContainerProps> {

  public render(): JSX.Element | null {
    const { termSet, match } = this.props;
    const term = findTermById(termSet, match.params.termId);
    if (term === undefined) {
      // tslint:disable-next-line:no-null-keyword
      return null;
    }
    const termChildren = getSortedTermChildren(termSet, match.params.termId);
    const urlParameters = this.getPredefinedUrlParameters();
    return (
      <SubProcessPage
        description={this.getDescription()}
        predefinedUrlParameters={urlParameters}
        term={term}
        termChildren={termChildren}
        termSet={termSet}
      />
    );
  }

  public componentDidMount(): void {
    const termId = this.props.match.params.termId;
    const currentLcid = getCurrentLanguageLcid();
    this.props.loadTermDescription(termId, currentLcid);
    this.props.loadTermDescription(termId);
    this.props.loadTermSet();
  }

  private getPredefinedUrlParameters(): ISearchUrlParameters {
    return {
      bu: this.props.businessUnitTermId,
      country: this.props.countryTermId,
      lang: this.props.languageTermIds,
    };
  }

  private getDescription(): string {
    const { termDescription, defaultTermDescription } = this.props;
    if (termDescription !== undefined && termDescription.value !== '') {
      return termDescription.value;
    }
    return defaultTermDescription !== undefined && defaultTermDescription.value !== ''
      ? defaultTermDescription.value
      : '';
  }
}

const mapStateToProps = (
  state: RootState,
  ownProps: RouteComponentProps<ISubProcessPageUrlParams>,
): ISubProcessPageContainerStateProps => {
  const lcid = getCurrentLanguageLcid();
  const termId = ownProps.match.params.termId;
  const { businessUnitTermId, countryTermId, languageTermIds } = state.preferences;
  return {
    businessUnitTermId,
    countryTermId,
    defaultTermDescription: getTermDescriptionFromState(state, termId),
    languageTermIds,
    termDescription: getTermDescriptionFromState(state, termId, lcid),
    termSet: getTermSetByIdFromState(state, houseConfig.termSetId),
  };
};

const dispatchProps: ISubProcessPageContainerDispatchProps = {
  loadTermDescription: (termId: string, lcid?: number) => loadTermDescription.request({
    lcid,
    termId,
    termSetId: houseConfig.termSetId,
  }),
  loadTermSet: () => loadTermSet.request(houseConfig.termSetId),
};

const subProcessPageContainer = connect(
  mapStateToProps,
  dispatchProps,
)(SubProcessPageContainer);

export {
  subProcessPageContainer as SubProcessPageContainer,
};
