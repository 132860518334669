import { createAction, createAsyncAction, createStandardAction } from 'typesafe-actions';
import { ISortProperty } from '../lib/entities/ISortProperty';
import { ITaxonomyMultiPreferenceInfo } from '../preferences/common/taxonomy/ITaxonomyMultiPreferenceInfo';
import { ITaxonomyPreferenceInfo } from '../preferences/common/taxonomy/ITaxonomyPreferenceInfo';
import { IDocumentDetails } from './documents/services/IDocumentDetails';
import { IDocumentRefinementFilter } from './documents/services/IDocumentRefinementFilter';
import { IDocumentsSearchResult } from './documents/services/IDocumentsSearchResult';
import { ISelectedDocumentInfo } from './documents/services/ISelectedDocumentInfo';

export const searchAsync = createAsyncAction(
  'SEARCH_REQUEST',
  'SEARCH_SUCCESS',
  'SEARCH_FAILURE',
)<void, IDocumentsSearchResult, string>();

export const loadDocumentDetailsAsync = createAsyncAction(
  'LOAD_DOCUMENT_DETAILS_REQUEST',
  'LOAD_DOCUMENT_DETAILS_SUCCESS',
  'LOAD_DOCUMENT_DETAILS_FAILURE',
)<void, IDocumentDetails, string>();

export const updateStateFromUrl = createStandardAction('UPDATE_STATE_FROM_URL')<boolean>();

export const selectDocument = createStandardAction('SELECT_DOCUMENT')<ISelectedDocumentInfo | undefined>();

export const sort = createStandardAction('SORT')<ISortProperty | undefined>();

export const query = createStandardAction('QUERY')<string>();

export const applyRefinementFilter = createStandardAction('APPLY_REFINEMENT_FILTER')<IDocumentRefinementFilter>();

export const applyPredefinedFilter = createStandardAction('APPLY_PREDEFINED_FILTER')<ITaxonomyPreferenceInfo>();

export const applyPredefinedFilters = createStandardAction('APPLY_PREDEFINED_FILTERS')<ITaxonomyMultiPreferenceInfo>();

export const resetSearchParameters = createStandardAction('RESET_SEARCH_PARAMETERS')();

export const resetSearch = createStandardAction('RESET_SEARCH')();

export const resetFilters = createStandardAction('RESET_FILTERS')();

export const openSortMenu = createAction('SORT_MENU_OPEN');

export const closeSortMenu = createAction('SORT_MENU_CLOSE');

export const showSpinner = createAction('SHOW_SEARCH_SPINNER');

export const toggleFiltersPanel = createAction('EXPAND_FILTERS_PANEL');
