import React from 'react';
import { Link } from 'react-router-dom';
import { IHouseIconLinkProps } from './IHouseIconLinkProps';

export class HouseIconLink extends React.PureComponent<IHouseIconLinkProps> {

  public render(): JSX.Element {
    const { icon, title, url } = this.props;
    return (
      <Link to={url} className="row" title={title}>
        <div className="col-md">
          <img src={icon} />
        </div>
        <div className="col-md">{title}</div>
      </Link>
    );
  }
}
