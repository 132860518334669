import { getType } from 'typesafe-actions';
import { houseConfig } from '../config/HouseConfig';
import { IAccordionContainerStateProps } from '../house/accordions/IAccordionContainerStateProps';
import { RootState } from '../store/types/RootState';
import { getTermSetByIdFromState } from '../termSets/TermSetUtils';
import { createAccordion, toggleCollapsedState } from './AccordionActions';
import { IAccordionInfo } from './IAccordionInfo';
import { AccordionAction } from './types/AccordionAction';

export function mapAccordionStateToProps(
  accordionName: string,
  state: RootState,
): IAccordionContainerStateProps {
  return {
    expanded: isAccordionExpanded(state.accordions, accordionName),
    predefinedUrlParameters: {
      bu: state.preferences.businessUnitTermId,
      country: state.preferences.countryTermId,
      lang : state.preferences.languageTermIds,
    },
    termSet: getTermSetByIdFromState(state, houseConfig.termSetId),
  };
}

export function getAccordions(accordions: IAccordionInfo[] = [], action: AccordionAction): IAccordionInfo[] {
  switch (action.type) {
    case getType(createAccordion):
      return addAccordion(accordions, action.payload);
    case getType(toggleCollapsedState):
      return toggleAccordion(accordions, action.payload);
    default:
      return accordions;
  }
}

function toggleAccordion(accordions: IAccordionInfo[], name: string): IAccordionInfo[] {
  return accordions.map(accordion => ({
    expanded: accordion.name === name ? !accordion.expanded : false,
    name: accordion.name,
  }));
}

function addAccordion(accordions: IAccordionInfo[], name: string): IAccordionInfo[] {
  const newAccordion = {
    expanded: false,
    name,
  };
  return [...accordions, newAccordion];
}

function isAccordionExpanded(accordions: IAccordionInfo[], name: string): boolean {
  const foundAccordion = accordions.find(accordion => accordion.name === name);
  return foundAccordion !== undefined ? foundAccordion.expanded : false;
}
