import { AppBar, Typography } from '@material-ui/core';
import * as React from 'react';
// tslint:disable-next-line:match-default-export-name
import logo from '../images/Implenia-Logo.jpg';
import { localizedStrings } from '../localization/LocalizedStrings';
import './Header.scss';
import { HelpLink } from './helpLink/HelpLink';
import { MenuButtonContainer } from './menuButton/MenuButtonContainer';
import { UserInfoContainer } from './userInfo/UserInfoContainer';

export class Header extends React.PureComponent {

  // tslint:disable-next-line:prefer-function-over-method no-big-function
  public render(): JSX.Element {
    return (
      <div className="top-navigation">
        <AppBar position="static" color="default">
          <div className="top-navigation-container">
            <div className="top-navigation-panel-before desktop-only" />
            <div className="top-navigation-panel left mobile-only">
              <MenuButtonContainer />
            </div>
            <div className="top-navigation-panel center">
              <div className="logo-main-container">
                <img src={logo} alt="logo" className="logo-container" />
              </div>
              <Typography className="title-container">{localizedStrings.processesNavigationLink}</Typography>
            </div>
            <div className="top-navigation-panel right">
              <HelpLink />
              <UserInfoContainer />
            </div>
          </div>
        </AppBar>
      </div>
    );
  }
}
