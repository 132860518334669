export async function convertPhotoToDataUrl(payload: Blob): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (typeof(reader.result) === 'string') {
        resolve(reader.result);
      }
      reject();
    };
    reader.readAsDataURL(payload);
  });
}

export function getFileNameWithoutExtension(fileName: string): string {
  return fileName.replace(/\.[^/.]+$/, '');
}
