import { Chip } from '@material-ui/core';
import React from 'react';
import { getFilterKeyWith } from '../../../entities/PredefinedFilterUtils' ;
import { IPredefinedFilterChipProps } from './IPredefinedFilterChipProps';
import { getChipLabel } from './PredefinedFilterChipUtils';

export class PredefinedFilterChip extends React.PureComponent<IPredefinedFilterChipProps> {

  public render(): JSX.Element {
    const { termSet, term, showHierarchyInLabel } = this.props;
    const label = getChipLabel(term, termSet, showHierarchyInLabel);
    return (
      <Chip
        key={getFilterKeyWith(this.props.termSetId, this.props.term.id)}
        label={label}
        onDelete={this.handleDelete}
      />
    );
  }

  private readonly handleDelete = (): void => {
    if (this.props.multiple) {
      this.props.onRemove(this.props.termSetId, this.props.term.id);
    } else {
      this.props.onRemove(this.props.termSetId, '');
    }
  }
}
