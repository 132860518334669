import { IDictionary } from '../shared/IDictionary';

// tslint:disable-next-line:no-any
const cache: IDictionary<any> = {};

export function getValue<TValue>(key: string): TValue | undefined {
  // tslint:disable
  return cache[key];
}

export function setValue<TValue>(key: string, value: TValue): void {
  cache[key] = value;
}

export function removeValue(key: string): void {
  // tslint:disable-next-line:no-dynamic-delete
  delete cache[key];
}

export function hasValue(key: string): boolean {
  return key in cache;
}
