import { config } from '../../config/Config';
import { getListItems, getMetaInfoValue, parseUrlFieldValue } from '../listItem/ListItem';
import { ISearchItem } from '../search/ISearchItem';
import { ISearchItemLink } from './ISearchItemLink';
import { IUrlFileListItem } from './IUrlFileListItem';

export async function getDocumentLinks(items: ISearchItem[]): Promise<ISearchItemLink[]> {
  const listItems = await getUrlFileListItems(items);
  return items.map(searchItem => {
    const itemId = parseInt(searchItem.ListItemID as string, 10);
    const listItem = listItems.find(item => item.Id === itemId);
    const url = listItem !== undefined ? getLinkFromListItem(listItem) : getDocumentLink(searchItem);
    return {
      item: searchItem,
      url,
    };
  });
}

async function getUrlFileListItems(items: ISearchItem[]): Promise<IUrlFileListItem[]> {
  const urlItems = items.filter(item => isDocumentOfType(item, 'url'));
  if (urlItems.length === 0) {
    return [];
  }
  const listId = urlItems[0].ListID as string;
  const ids = urlItems.map(item => item.ListItemID as number);
  return getListItems<IUrlFileListItem>(
    config.sharepointUrl,
    listId,
    ids,
    ['Id', 'FieldValuesAsText/MetaInfo'],
    ['FieldValuesAsText'],
  );
}

function getLinkFromListItem(item: IUrlFileListItem): string {
  const urlValue = getMetaInfoValue(item.FieldValuesAsText.MetaInfo, 'vti_shortcuturl');
  if (urlValue !== undefined) {
    const value = parseUrlFieldValue(urlValue);
    return value.url;
  }
  return '';
}

function getDocumentLink(item: ISearchItem): string {
  if (isDocumentOfType(item, 'xltx')) {
    return item.DefaultEncodingURL as string;
  }
  if (item.DlcDocId !== undefined) {
    return `${item.SPSiteURL}/_layouts/15/DocIdRedir.aspx?ID=${item.DlcDocId}`;
  }
  return `${item.DefaultEncodingURL}?web=1`;
}

function isDocumentOfType(item: ISearchItem, type: string): boolean {
  return typeof item.ImplFileTypeSortable === 'string' &&
    item.ImplFileTypeSortable.toLowerCase() === type.toLowerCase();
}
