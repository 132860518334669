import AuthenticationContext from 'adal-angular';
import { config } from '../../config/Config';

export class Authentication {

  public renewIframe: boolean = false;

  public errorMessage: string = '';

  public hasError: boolean = false;

  private authenticated: boolean = false;

  private readonly adalConfig: AuthenticationContext.Options = {
    clientId: config.clientId,
    tenant: config.tenantId,
  };

  private readonly authContext: AuthenticationContext = new AuthenticationContext(this.adalConfig);

  // tslint:disable-next-line
  public init(): void {
    this.authContext.handleWindowCallback();

    // we are inside renew iframe, thus do nothing
    if (this.authContext.isCallback(window.location.hash)) {
        this.renewIframe = true;
        return;
    }
    // no user context, let's redirect to authentication page
    // tslint:disable-next-line
    if (!this.authContext.getCachedToken(this.adalConfig.clientId) || !this.authContext.getCachedUser()) {
        this.authContext.login();
    } else { // show error if there is an error response from Office 365
        // tslint:disable-next-line
        if (this.authContext.getLoginError()) {
            this.hasError = true;
            this.errorMessage = this.authContext.getLoginError();
        } else {
            // user was authenticated, initialize pnp.js
            this.authenticated = true;
        }
    }
}

  public get authenticationContext(): AuthenticationContext {
    return this.authContext;
  }

  public get isAuthenticated(): boolean {
    return this.authenticated;
  }
}
