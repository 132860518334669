import React from 'react';
import { getSortedTermChildren, getTermLabel } from '../../lib/taxonomy/Taxonomy';
import { getHouseLinkUrl } from '../common/Utils';
import { Floor2Item } from './Floor2Item';
import { Floor2NoConfigMessage } from './Floor2NoConfigMessage';
import { getNavigationTermId } from './Floor2Utils';
import { Floor2WrongConfigMessage } from './Floor2WrongConfigMessage';
import { IFloor2Props } from './IFloor2Props';

export class Floor2 extends React.PureComponent<IFloor2Props> {

  public render(): JSX.Element {
    return (
      <div className="floor2 row bg-l align-items-center om-bordered">
        {this.getContent()}
      </div>
    );
  }

  private getContent(): JSX.Element | JSX.Element[] {
    const { configurationTermId } = this.props;
    if (configurationTermId === '') {
      return <Floor2NoConfigMessage className="col" />;
    }
    const { configurationTermSet, navigationTermPropertyName } = this.props;
    const navigationTermId = getNavigationTermId(configurationTermId, configurationTermSet, navigationTermPropertyName);
    return navigationTermId === undefined
      ? <Floor2WrongConfigMessage className="col" />
      : this.getFloorItems(navigationTermId);
  }

  private getFloorItems(navigationTermId: string): JSX.Element[] {
    const { predefinedUrlParameters, navigationTermSet } = this.props;
    const children = getSortedTermChildren(navigationTermSet, navigationTermId);
    return children.map(term => {
      const redirectUrl = getHouseLinkUrl(term, predefinedUrlParameters);
      const label = getTermLabel(term);
      return <Floor2Item key={label} title={label} url={redirectUrl} />;
    });
  }
}
