import { connect } from 'react-redux';
import * as accordionActions from '../../accordion/AccordionActions';
import { mapAccordionStateToProps } from '../../accordion/AccordionUtils';
import { houseConfig } from '../../config/HouseConfig';
import { getFloorHeader } from '../../house/housePage/HousePageUtils';
import { RootState } from '../../store/types/RootState';
import { BaseHouseRowAccordionContainer } from './BaseHouseRowAccordionContainer';
import { IAccordionContainerDispatchProps } from './IAccordionContainerDispatchProps';
import { IAccordionContainerProps } from './IAccordionContainerProps';
import { IAccordionContainerStateProps } from './IAccordionContainerStateProps';

class RoofAccordionContainer extends BaseHouseRowAccordionContainer<IAccordionContainerProps> {

  protected getHeaderElement(): JSX.Element {
    const { termSet, predefinedUrlParameters } = this.props;
    return getFloorHeader(predefinedUrlParameters, termSet, houseConfig.roofTermId, false);
  }

  protected getContentsElement(): JSX.Element {
    const { termSet, predefinedUrlParameters } = this.props;
    return getFloorHeader(
      predefinedUrlParameters,
      termSet,
      houseConfig.roofTermId,
      true,
      false,
      'house-roof single-node',
    );
  }
}

const mapStateToProps = (state: RootState, ownProps: IAccordionContainerProps): IAccordionContainerStateProps =>
  mapAccordionStateToProps(ownProps.name, state);

const dispatchProps: IAccordionContainerDispatchProps = {
  onRender: accordionActions.createAccordion,
  onToggle: accordionActions.toggleCollapsedState,
};

const roofAccordionContainer = connect(
  mapStateToProps,
  dispatchProps,
)(RoofAccordionContainer);

export {
  roofAccordionContainer as RoofAccordionContainer,
};
