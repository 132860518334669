import { Typography } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { getSearchLinkUrl, getSubProcessLinkUrl } from '../../house/common/Utils';
import { getTermLabel } from '../../lib/taxonomy/Taxonomy';
import { ITermTileParams } from './ITermTileParams';
import './TermTile.scss';

export class TermTile extends React.PureComponent<ITermTileParams> {

  public render(): JSX.Element {
    const { label, navigateToSearch, predefinedUrlParameters, term } = this.props;
    const url = term.termsCount > 0 && navigateToSearch !== true
      ? getSubProcessLinkUrl(term.id)
      : getSearchLinkUrl(term.id, predefinedUrlParameters);
    const tileLabel = label !== undefined ? label : getTermLabel(term);
    const className = this.getLinkClassName();
    return (
      <Link className={className} to={url}>
        <Typography className="term-tile-text">{tileLabel}</Typography>
      </Link>
    );
  }

  private getLinkClassName(): string {
    const { highlighted } = this.props;
    const linkClassName = 'term-tile';
    return highlighted === true ? `${linkClassName} highlighted` : linkClassName;
  }
}
