import { Typography } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { getSubProcessLinkUrl } from '../../house/common/Utils';
import { getTermLabel } from '../../lib/taxonomy/Taxonomy';
import './Breadcrumb.scss';
import { BreadcrumbArrow } from './BreadcrumbArrow';
import { BreadcrumbHouse } from './BreadcrumbHouse';
import { IBreadcrumbProps } from './IBreadcrumbProps';

export class Breadcrumb extends React.PureComponent<IBreadcrumbProps> {

  public render(): JSX.Element | null {
    const { terms } = this.props;
    return terms.length > 0
      ? <div className="breadcrumb">{this.getBreadcrumbElements()}</div>
      : <></>;
  }

  private getBreadcrumbElements(): JSX.Element[] {
    const { terms } = this.props;
    const elements: JSX.Element[] = [
      <BreadcrumbHouse key="house" />,
    ];
    terms.forEach(term => {
      const label = getTermLabel(term);
      elements.push(<BreadcrumbArrow key={`arrow_${term.id}`} />);
      if (term.termsCount > 0) {
        const url = getSubProcessLinkUrl(term.id);
        elements.push(
          <Link key={term.id} to={url}>
            <Typography component="span" className="breadcrumb-text">{label}</Typography>
          </Link>,
        );
      } else {
        elements.push(<Typography key={term.id} component="span" className="breadcrumb-text">{label}</Typography>);
      }
    });
    return elements;
  }
}
