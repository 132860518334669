import { StyledComponentProps, TextField } from '@material-ui/core';
// tslint:disable-next-line:no-submodule-imports
import { InputProps } from '@material-ui/core/Input';
// tslint:disable-next-line:no-submodule-imports
import { InputLabelProps } from '@material-ui/core/InputLabel';
import React from 'react';
// tslint:disable-next-line:no-submodule-imports
import { ControlProps } from 'react-select/lib/components/Control';
import { Input } from './Input';

export class Control<TOption> extends React.PureComponent<ControlProps<TOption>> {

  public render(): JSX.Element {
    const { selectProps } = this.props;
    return (
      <TextField
        fullWidth={true}
        InputProps={this.getInputProps()}
        InputLabelProps={this.getInputLabelProps()}
        className="searchable-select-input"
        label={selectProps.label as string}
      />
    );
  }

  private getInputProps(): Partial<InputProps> {
    const { children, selectProps, innerRef, innerProps } = this.props;
    const styledProps = selectProps as StyledComponentProps;
    const className = styledProps.classes !== undefined ? styledProps.classes.input : undefined;
    return {
      inputComponent: Input,
      inputProps: {
        children,
        className,
        inputRef: innerRef,
        ...innerProps,
      },
    };
  }

  private getInputLabelProps(): Partial<InputLabelProps> {
    const { hasValue, selectProps } = this.props;
    return {
      shrink: hasValue || selectProps.menuIsOpen === true,
    };
  }
}
