import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
// tslint:disable-next-line: match-default-export-name no-submodule-imports
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import * as React from 'react';
import { HeadCellContainer } from '../../../components/table/HeadCellContainer';
import { IColumnConfig } from '../../../components/table/IColumnConfig';
import { localizedStrings } from '../../../localization/LocalizedStrings';
import { documentTypeColumn, iconColumn, titleColumn } from '../entities/SortColumns';
import { IDocument } from '../services/IDocument';
import { DocumentRow } from './DocumentRow';
import './Documents.scss';
import { IDocumentsProps } from './IDocumentsProps';

export class Documents extends React.PureComponent<IDocumentsProps> {

  private readonly columns: IColumnConfig[] = [
    {
      className: 'document-icon-cell',
      id: 'icon',
      label: iconColumn.title,
      sortProperty: iconColumn.sortProperty,
      sortable: true,
    },
    {
      className: 'document-title-cell',
      id: 'title',
      label: titleColumn.title,
      sortProperty: titleColumn.sortProperty,
      sortable: true,
    },
    {
      className: 'document-type-cell',
      id: 'documentType',
      label: documentTypeColumn.title,
      sortProperty: documentTypeColumn.sortProperty,
      sortable: true,
    },
    {
      className: 'document-tags-cell',
      id: 'tags',
      label: localizedStrings.tagsColumnName,
      sortable: false,
    },
    {
      className: 'document-language-cell',
      id: 'languages',
      label: localizedStrings.languagesColumnName,
      sortable: false,
    },
  ];

  public render(): JSX.Element {
    const { documents } = this.props;
    if (documents.length === 0) {
      return <Typography className="nothing-found-text">{localizedStrings.nothingFound}</Typography>;
    }
    return (
      <Table className="documents-table" padding="none">
        <TableHead className="documents-table-head">
          <TableRow>
            <TableCell key="info" className="document-info-cell">
              <InfoOutlinedIcon />
            </TableCell>
            {this.getHeadCells()}
          </TableRow>
        </TableHead>
        <TableBody>
          {documents.map(this.getDocumentRow)}
        </TableBody>
      </Table>
    );
  }

  private readonly getDocumentRow = (document: IDocument): JSX.Element => {
    const { selectedDocumentInfo, onToggleSelect } = this.props;
    const selected = document.listId === selectedDocumentInfo.listId
      && document.listItemId === selectedDocumentInfo.listItemId;
    return (
      <DocumentRow
        key={document.id}
        document={document}
        selected={selected}
        onToggleSelect={onToggleSelect}
      />
    );
  }

  private getHeadCells(): JSX.Element[] {
    return this.columns.map(column => (
      <HeadCellContainer
        key={column.id}
        className={column.className}
        label={column.label}
        sortable={column.sortable}
        sortProperty={column.sortProperty}
      />
    ));
  }
}
