import * as React from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import './App.scss';
import {
  houseRoute,
  preferencesRoute,
  resultsRoute,
  searchRoute,
  subProcessRouteWithParameters,
} from './entities/Routs';
import { Header } from './header/Header';
import { history } from './History';
import { HousePageContainer } from './house/housePage/HousePageContainer';
import { MenuContainer } from './menu/MenuContainer';
import { NotificationContainer } from './notification/NotificationContainer';
import { PredefinedFilterPage } from './preferences/page/PredefinedFilterPage';
import { ResultsPageContainer } from './search/searchPage/ResultsPageContainer';
import { SearchPageContainer } from './search/searchPage/SearchPageContainer';
import { SubProcessPageContainer } from './subProcess/subProcessPage/SubProcessPageContainer';

export class App extends React.PureComponent {

  // tslint:disable-next-line:prefer-function-over-method no-big-function
  public render(): JSX.Element {
    return (
      <Router history={history}>
        <div className="app">
          <Header />
          <div className="app-navigation">
            <MenuContainer />
            <div className="app-wrapper">
              <div className="container">
                <Switch>
                  <Route exact={true} path={houseRoute} component={HousePageContainer} />
                  <Route path={subProcessRouteWithParameters} component={SubProcessPageContainer} />
                  <Route path={searchRoute} component={SearchPageContainer} />
                  <Route path={resultsRoute} component={ResultsPageContainer} />
                  <Route path={preferencesRoute} component={PredefinedFilterPage} />
                  <Redirect to="/" />
                </Switch>
              </div>
            </div>
          </div>
          <NotificationContainer />
        </div>
      </Router>
    );
  }
}
