import { Typography } from '@material-ui/core';
import React from 'react';
import { localizedStrings } from '../../localization/LocalizedStrings';
import { IFloor2MessageProps } from './IFloor2MessageProps';

export class Floor2WrongConfigMessage extends React.PureComponent<IFloor2MessageProps> {

  public render(): JSX.Element {
    const emailLink = `mailto:${localizedStrings.wrongCoreProcessConfigPart2}`;
    return (
      <Typography className={this.props.className}>
        {localizedStrings.wrongCoreProcessConfigPart1}
        <a href={emailLink}>{localizedStrings.wrongCoreProcessConfigPart2}</a>
        {localizedStrings.wrongCoreProcessConfigPart3}
      </Typography>
    );
  }
}
