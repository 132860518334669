import { IDictionary } from '../shared/IDictionary';
import { CacheService } from './CacheService';
import { ICacheConfig } from './ICacheConfig';
import { ICacheService } from './ICacheService';

const services: IDictionary<ICacheService> = {
};

export function getCacheService(key: string, cacheConfig: ICacheConfig, createNew: boolean = false): ICacheService {
  if (createNew) {
    // tslint:disable-next-line:no-dynamic-delete
    delete services[key];
  }
  if (!services.hasOwnProperty(key)) {
    services[key] = new CacheService(cacheConfig);
  }
  return services[key];
}
