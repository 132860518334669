import React from 'react';
import { config } from '../../config/Config';
import { localizedStrings } from '../../localization/LocalizedStrings';
import { MenuLink } from '../menuLink/MenuLink';
import { IProcessMapMenuLinkProps } from './IProcessMapMenuLinkProps';

export class ProcessMapMenuLink extends React.PureComponent<IProcessMapMenuLinkProps> {

  public render(): JSX.Element | null {
    const link = this.getLink();
    if (link !== '') {
      return (
        <MenuLink
          iconClassName="icon-OM-House"
          linkUrl={link}
          linkTitleLocalizationKey={localizedStrings.processMapNavigationLink}
          isExternal={true}
        />
      );
    }
    // tslint:disable-next-line: no-null-keyword
    return null;
  }

  private getLink(): string {
    const { term: scopeOfServiceTerm } = this.props;
    if (scopeOfServiceTerm !== undefined) {
      const linkPropertyName = config.navigation.processMapCustomProperty;
      if (scopeOfServiceTerm.customProperties.hasOwnProperty(linkPropertyName)) {
        return scopeOfServiceTerm.customProperties[linkPropertyName];
      }
    }
    return '';
  }
}
