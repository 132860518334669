import { Typography } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { preferencesRoute } from '../../entities/Routs';
import { localizedStrings } from '../../localization/LocalizedStrings';

export class HouseConfigurationMessage extends React.PureComponent {

  // tslint:disable-next-line:prefer-function-over-method
  public render(): JSX.Element {
    return (
      <Typography align="center">
        {localizedStrings.houseConfigMessagePart1}
        <Link to={preferencesRoute}>{localizedStrings.houseConfigMessagePart2}</Link>
        {localizedStrings.houseConfigMessagePart3}
      </Typography>
    );
  }
}
