import { ListItem, TableSortLabel, Typography } from '@material-ui/core';
import React from 'react';
import { SortDirection } from '../../../lib/entities/SortDirection';
import { ISortMenuItemProps } from './ISortMenuItemProps';

export class SortMenuItem extends React.PureComponent<ISortMenuItemProps> {

  public render(): JSX.Element {
    const { label, sortProperty, sortDirection } = this.props;
    return (
      <ListItem button={true} key={sortProperty} onClick={this.handleOnSort}>
        <TableSortLabel active={sortDirection !== undefined} direction={sortDirection}>
          <Typography>{label}</Typography>
        </TableSortLabel>
      </ListItem>
    );
  }

  private readonly handleOnSort = () => {
    const { sortProperty, sortDirection, onSort } = this.props;
    if (onSort !== undefined) {
      if (sortDirection === undefined) {
        onSort({
          direction: SortDirection.ascending,
          propertyName: sortProperty,
        });
        return;
      }
      if (sortDirection === SortDirection.ascending) {
        onSort({
          direction: SortDirection.descending,
          propertyName: sortProperty,
        });
        return;
      }
      onSort(undefined);
    }
  }
}
