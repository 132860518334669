import * as React from 'react';
import { config } from '../../../config/Config';
import { IRefinerContainerOwnProps } from '../../refiners/IRefinerContainerOwnProps';
import { RefinerContainer } from '../../refiners/RefinerContainer';
import { getRefinerName } from '../../refiners/RefinerUtils';
import { IRefinersProps } from './IRefinersProps';

export class Refiners extends React.PureComponent<IRefinersProps> {

  public render(): JSX.Element {
    const refinerElements = this.getRefinerElements();
    return <>{refinerElements}</>;
  }

  private readonly getRefinerElements = (): JSX.Element[] => {
    const refinerContainersProps = this.getRefinerContainersProps();
    return refinerContainersProps.map(props => {
      const key = getRefinerName(props.refinement, props.refinementFilter);
      return <RefinerContainer key={key} {...props} />;
    });
  }

  private getRefinerContainersProps(): IRefinerContainerOwnProps[] {
    const { refinements, refinementFilters } = this.props;
    const refinersProps: IRefinerContainerOwnProps[] = [];
    config.search.refiners.forEach(refinerConfig => {
      const foundRefinement = refinements.find(refinement => refinement.refinerName === refinerConfig.name);
      const foundRefinementFilter = refinementFilters.find(filter => filter.name === refinerConfig.name);
      if (foundRefinement !== undefined || foundRefinementFilter !== undefined) {
        refinersProps.push({
          refinement: foundRefinement,
          refinementFilter: foundRefinementFilter,
        });
      }
    });
    return refinersProps;
  }
}
