import { connect } from 'react-redux';
import { RootState } from '../../store/types/RootState';
import { getUserInfoAsync } from '../HeaderActions';
import { IUserInfoDispatchProps } from './IUserInfoDispatchProps';
import { IUserInfoStateProps } from './IUserInfoStateProps';
import { UserInfo } from './UserInfo';

const mapStateToProps = (state: RootState): IUserInfoStateProps => ({
  displayName: state.header.userProfile.displayName,
  pictureUrl: state.header.userProfile.pictureUrl,
});

const dispatchProps: IUserInfoDispatchProps = {
  onLoad: getUserInfoAsync.request,
};

const userInfoContainer = connect(
  mapStateToProps,
  dispatchProps,
)(UserInfo);

export {
  userInfoContainer as UserInfoContainer,
};
