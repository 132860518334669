import { config } from '../../../config/Config';
import { getTermHierarchy } from '../../../lib/taxonomy/Taxonomy';
import { RootState } from '../../../store/types/RootState';
import { getTermSetByIdFromState } from '../../../termSets/TermSetUtils';

export function getBusinessUnitWithParentsQuery(businessUnitTermId: string, rootState: RootState): string | undefined {
  if (businessUnitTermId === '') {
    return undefined;
  }
  const businessUnitTermSet = getTermSetByIdFromState(rootState, config.predefinedFilters.businessUnit.termSetId);
  const businessUnitHierarchyTerms = getTermHierarchy(businessUnitTermSet, businessUnitTermId);
  const businessUnitQueryParts = businessUnitHierarchyTerms
    .map(hierarchyTerm => `${config.businessUnitManagedProperty}:"GP0|#${hierarchyTerm.id}"`);
  businessUnitQueryParts.push(`${config.businessUnitManagedProperty}:"GPP|#${businessUnitTermId}"`);
  const query = businessUnitQueryParts.join(' OR ');
  return `(${query})`;
}
