import { sp } from '@pnp/sp';
import { ISearchQuery } from './ISearchQuery';
import { ISearchResult } from './ISearchResult';
import { convertSearchResult, convertToPnpSearchQuery } from './SearchResultConverter';

export async function search(query: ISearchQuery): Promise<ISearchResult> {
  const pnpQuery = convertToPnpSearchQuery(query);
  const searchResults = await sp.search(pnpQuery);
  return convertSearchResult(searchResults);
}
