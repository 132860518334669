import { CircularProgress, Drawer, IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import * as React from 'react';
import { localizedStrings } from '../../localization/LocalizedStrings';
import { DocumentTag } from '../documents/list/DocumentTag';
import { DocumentField } from './DocumentField';
import './DocumentPanel.scss';
import { IDocumentPanelProps } from './IDocumentPanelProps';
import { IDocumentPanelState } from './IDocumentPanelState';

export class DocumentPanel extends React.PureComponent<IDocumentPanelProps, IDocumentPanelState> {

  public constructor(props: IDocumentPanelProps) {
    super(props);
    // fix issue when Drawer by default visible even when closed.
    this.state = {
      fixStyles: {
        width: `${window.innerWidth - 1}px`,
      },
    };
  }

  // tslint:disable-next-line:no-big-function
  public render(): JSX.Element {
    const documentTitle = this.props.document === undefined ? '' : this.props.document.title;
    const fixStyles = this.props.open ? undefined : this.state.fixStyles;
    return (
      <Drawer
        variant="persistent"
        anchor="right"
        open={this.props.open}
        className="document-panel"
        classes={{ paper: 'document-panel-paper' }}
      >
        <div style={fixStyles}>
          <div className="document-panel-header">
            <div className="document-panel-header-title">
              <Typography className="document-panel-header-title-text">{documentTitle}</Typography>
            </div>
            <IconButton
              aria-label="Close"
              onClick={this.handleClose}
            >
              <Close />
            </IconButton>
          </div>
          <div className="document-panel-content">
            {this.getPanelContent()}
          </div>
        </div>
      </Drawer>
    );
  }

  public componentDidMount(): void {
    this.setState({
      fixStyles: undefined,
    });
  }

  // tslint:disable-next-line: no-big-function
  private getPanelContent(): JSX.Element {
    if (!this.props.open) {
      return <></>;
    }
    if (this.props.document === undefined) {
      return (
        <div className="progress-container">
          <CircularProgress className="progress" />
        </div>
      );
    }
    const {
      approvalDate,
      documentType,
      businessUnits,
      processTypes,
      subProcesses,
      comment,
      countries,
      languages,
      tags,
      authors,
      owner,
      approver,
      id,
      errorMessage,
    } = this.props.document;
    if (errorMessage !== '') {
      return (
        <div className="error">
          {errorMessage}
        </div>
      );
    }
    let commentField = <></>;
    if (comment.length > 0) {
      commentField = <DocumentField title={localizedStrings.commentFieldName} value={comment} />;
    }
    return (
      <div className="fields-containers">
        <DocumentField title={localizedStrings.approvalDateFieldName} value={approvalDate} />
        <DocumentField title={localizedStrings.documentTypeColumnName} value={documentType} />
        <DocumentField title={localizedStrings.businessUnitFieldName} value={this.getFieldTextValue(businessUnits)} />
        <DocumentField title={localizedStrings.processTypeFieldName} value={this.getFieldTextValue(processTypes)} />
        <DocumentField title={localizedStrings.subProcessFieldName} value={this.getFieldTextValue(subProcesses)} />
        <DocumentField title={localizedStrings.countryFieldName} value={this.getFieldTextValue(countries)} />
        <DocumentField title={localizedStrings.languagesFieldName} value={this.getFieldTextValue(languages)} />
        <DocumentField title={localizedStrings.tagsFieldName}>
          {tags.map(tag => <DocumentTag title={tag} key={tag} />)}
        </DocumentField>
        <DocumentField title={localizedStrings.authorFieldName}  value={this.getFieldTextValue(authors)} />
        <DocumentField title={localizedStrings.ownerFieldName} value={owner} />
        <DocumentField title={localizedStrings.approverFieldName} value={approver} />
        <DocumentField title="ID" value={id.toString()} />
        {commentField}
      </div>
    );
  }

  private readonly getFieldTextValue = (values: string[]): string => values.join(', ');

  private readonly handleClose = (): void => {
    this.props.onClose();
  }
}
