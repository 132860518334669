import { FormControl, IconButton, InputLabel, MenuItem, Select } from '@material-ui/core';
// tslint:disable-next-line: match-default-export-name no-submodule-imports
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
// tslint:disable-next-line: match-default-export-name no-submodule-imports
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';
import ReactDOM from 'react-dom';
import { ITaxonomyTerm } from '../../lib/taxonomy/ITaxonomyTerm';
import { getTermLabel } from '../../lib/taxonomy/Taxonomy';
import { ITaxonomySelectorProps } from './ITaxonomySelectorProps';
import { ITaxonomySelectorState } from './ITaxonomySelectorState';
import { ITaxonomyTreeItem } from './ITaxonomyTreeItem';
import './TaxonomySelector.scss';
import {
  buildTermSetTree,
  collapseAllItems,
  disableItemsNotAvailableForTagging,
  updateLabelWithParent,
} from './TaxonomySelectorUtils';

export class TaxonomySelector extends React.Component<ITaxonomySelectorProps, ITaxonomySelectorState> {

  private readonly termTreeOffset: number = 15;

  public constructor(props: ITaxonomySelectorProps) {
    super(props);
    this.state = {
      items: [],
    };
  }

  public componentDidMount(): void {
    this.setState({
      items: this.getItems(),
      menuBottomPosition: this.getMenuBottomPosition(),
    });
  }

  public componentDidUpdate(prevProps: ITaxonomySelectorProps, prevState: ITaxonomySelectorState): void {
    if (this.props !== prevProps) {
      this.setState({
        ...this.state,
        items: this.getItems(),
      });
    }
  }

  public render(): JSX.Element {
    return this.props.readOnly === true ? this.getReadOnlyElement() : this.getSelectElement();
  }

  // tslint:disable-next-line:cyclomatic-complexity mccabe-complexity max-union-size
  private getItems(): ITaxonomyTreeItem[] {
    const { termSet, allowOnlyTags, collapseByDefault, expandOnlyFirstLevel, showParentInLabel } = this.props;
    if (termSet === undefined) {
      return [];
    }
    const items = buildTermSetTree(termSet);
    if (allowOnlyTags === true) {
      disableItemsNotAvailableForTagging(items);
    }
    if (collapseByDefault === true) {
      collapseAllItems(items);
    }
    if (expandOnlyFirstLevel === true) {
      collapseAllItems(items);
      items.forEach(item => item.collapsed = false);
    }
    if (showParentInLabel === true) {
      updateLabelWithParent(items);
    }
    return items;
  }

  private getMenuBottomPosition(): number | undefined {
    if (this.props.collapsible === true) {
      const node = ReactDOM.findDOMNode(this) as Element | null;
      if (node !== null) {
        const rect = node.getBoundingClientRect() as DOMRect;
        return rect.top;
      }
    }
    return undefined;
  }

  private readonly handleDropdownChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    if (this.props.onMultipleChangeSelection !== undefined && this.props.multiSelect === true) {
      this.props.onMultipleChangeSelection((event.target.value as unknown as string[]).filter(id => id !== ''));
    } else {
      if (this.props.onChangeSelection !== undefined) {
        this.props.onChangeSelection(event.target.value);
      }
    }
  }

  private getSelectElement(): JSX.Element {
    const { multiSelect, selectedId, selectedIds, inputId, label } = this.props;
    const options = this.getMenuItems();
    const style = this.getFixStyleForTreeView();
    const value = multiSelect === true ? selectedIds : selectedId;
    return (
      <FormControl className="taxonomy-selector-container">
        <InputLabel htmlFor={inputId}>{label}</InputLabel>
        <Select
          required={false}
          value={value}
          multiple={multiSelect}
          onChange={this.handleDropdownChange}
          id={inputId}
          MenuProps={{style}}
        >
          {options}
        </Select>
      </FormControl>
    );
  }

  private getFixStyleForTreeView(): React.CSSProperties | undefined {
    if (this.props.treeView === true) {
      const { menuBottomPosition } = this.state;
      if (menuBottomPosition !== undefined) {
        return {
          bottom: menuBottomPosition,
        };
      }
    }
    return undefined;
  }

  private getReadOnlyElement(): JSX.Element {
    return <span>{this.getTermsReadOnlyValue()}</span>;
  }

  private getTermsReadOnlyValue(): string {
    return this.getTerms()
      .map(getTermLabel)
      .join(', ');
  }

  private getTerms(): ITaxonomyTerm[] {
    if (this.props.termSet !== undefined) {
      if (this.props.multiSelect === true) {
        return this.getTermsByIds(this.props.selectedIds);
      }
      if (this.props.selectedId !== '') {
        return this.getTermsByIds([this.props.selectedId as string]);
      }
    }
    return [];
  }

  private getTermsByIds(ids?: string[]): ITaxonomyTerm[] {
    return this.props.termSet !== undefined && ids !== undefined && ids.length > 0
      ? this.props.termSet.terms.filter(term => ids.indexOf(term.id) > -1)
      : [];
  }

  private getMenuItems(): JSX.Element[] {
    const { hasEmptyMenuItem } = this.props;
    const dropdownOptions: JSX.Element[] = [];
    this.addMenuItemsRecursive(this.state.items, dropdownOptions, false);
    return hasEmptyMenuItem === true ? [<MenuItem key="0" value="" />, ...dropdownOptions] : dropdownOptions;
  }

  private addMenuItemsRecursive(items: ITaxonomyTreeItem[], options: JSX.Element[], collapsed: boolean): void {
    items.forEach(item => {
      const menuItem = this.getMenuItem(item, collapsed);
      options.push(menuItem);
      if (item.children.length > 0) {
        this.addMenuItemsRecursive(item.children, options, item.collapsed || collapsed);
      }
    });
  }

  // tslint:disable-next-line:no-big-function
  private getMenuItem(item: ITaxonomyTreeItem, collapsed: boolean): JSX.Element {
    const className = `taxonomy-select-option ${collapsed ? 'collapsed' : ''}`;
    const style = this.getMenuItemStyle(item);
    if (!this.hasExpandButton()) {
      return (
        <MenuItem
          className={className}
          key={item.term.id}
          disabled={item.disabled}
          style={style}
          value={item.term.id}
        >
          {item.label}
        </MenuItem>
      );
    }

    const button = this.getExpandButton(item);
    return (
      <MenuItem
        className={className}
        key={item.term.id}
        disabled={item.disabled}
        style={style}
        value={item.term.id}
      >
        {button}
        {item.label}
      </MenuItem>
    );
  }

  private hasExpandButton(): boolean {
    return this.props.collapsible === true && this.props.treeView === true;
  }

  private getExpandButton(item: ITaxonomyTreeItem): JSX.Element {
    if (this.props.collapsible !== true || this.props.treeView !== true) {
      return <></>;
    }
    const hiddenClass = item.children.length === 0 ? 'button-hidden' : '';
    const icon = item.collapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />;
    return (
      <IconButton
        className={`expand-button ${hiddenClass}`}
        onClick={this.hideChildren.bind(this, item)}
      >
        {icon}
      </IconButton>
    );
  }

  private getMenuItemStyle(item: ITaxonomyTreeItem): React.CSSProperties | undefined {
    return this.props.treeView === true
        ? {
          marginLeft: `${(item.term.pathOfTerm.length - 1) * this.termTreeOffset}px`,
        }
        : undefined;
  }

  private hideChildren(item: ITaxonomyTreeItem, event: React.MouseEvent<HTMLElement, MouseEvent>): void {
    event.stopPropagation();
    event.preventDefault();
    item.collapsed = !item.collapsed;
    this.setState({
      items: [...this.state.items],
    });
  }
}
