import { IconButton } from '@material-ui/core';
import { Menu } from '@material-ui/icons';
import * as React from 'react';
import { IMenuButtonProps } from './IMenuButtonProps';

export class MenuButton extends React.PureComponent<IMenuButtonProps> {

  public render(): JSX.Element {
    return (
      <div>
        <IconButton
          id="menu-icon"
          color="inherit"
          aria-label="Menu"
          className="icon-container"
          onClick={this.props.onToggleButtonClick}
        >
          <Menu />
        </IconButton>
      </div>
    );
  }
}
