import { Typography } from '@material-ui/core';
import React from 'react';
import './DocumentField.scss';
import { IDocumentFieldProps } from './IDocumentFieldProps';

export class DocumentField extends React.PureComponent<IDocumentFieldProps> {

  public render(): JSX.Element {
    return (
      <div className="document-field">
        <Typography className="title">{this.props.title}</Typography>
        {this.getValueElement()}
      </div>
    );
  }

  private getValueElement(): React.ReactNode {
    const { value, children } = this.props;
    if (children !== undefined) {
      return children;
    }
    const displayValue = value !== undefined ? value : '';
    return <Typography>{displayValue}</Typography>;
  }
}
