import { AnyAction } from 'redux';
import { Epic } from 'redux-observable';
import { EMPTY, from, of } from 'rxjs';
// tslint:disable-next-line:no-submodule-imports
import { catchError, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { RootService } from '../store/types/RootService';
import { RootState } from '../store/types/RootState';
import { doesUrlContainParameters } from './location/SearchLocation';
import {
  applyPredefinedFilter,
  applyPredefinedFilters,
  applyRefinementFilter,
  loadDocumentDetailsAsync,
  query,
  resetFilters,
  resetSearch,
  searchAsync,
  selectDocument,
  sort,
  updateStateFromUrl,
} from './SearchActions';
import { SearchAction } from './types/SearchAction';

export const resetSearchEpic: Epic<AnyAction | SearchAction, SearchAction, RootState, RootService> =
  (action$, state$, { searchLocationService }) =>
    action$.pipe(
      filter(isActionOf([resetSearch])),
      tap(() => {
        searchLocationService.resetSearch();
      }),
      mergeMap(() => EMPTY),
    );

export const filterEpic: Epic<AnyAction | SearchAction, SearchAction, RootState, RootService> =
  (action$, state$, { searchLocationService }) =>
    action$.pipe(
      filter(isActionOf([
        query,
        sort,
        applyRefinementFilter,
        applyPredefinedFilter,
        applyPredefinedFilters,
        resetFilters,
        selectDocument])),
      tap(() => {
        searchLocationService.updateHistory(state$.value);
      }),
      mergeMap(() => EMPTY),
    );

export const updateStateFromUrlEpic: Epic<SearchAction, SearchAction, RootState, RootService> =
  (action$, state$) =>
    action$.pipe(
      filter(isActionOf(updateStateFromUrl)),
      filter(action => action.payload),
      filter(doesUrlContainParameters),
      filter(() => state$.value.termSets.length !== 0),
      map(searchAsync.request),
    );

export const searchEpic: Epic<SearchAction, SearchAction, RootState, RootService> =
  (action$, state$, { documentsSearchService }) =>
    action$.pipe(
      filter(isActionOf(searchAsync.request)),
      switchMap(() =>
        from(documentsSearchService.searchDocumentsUsingState(state$.value))
          .pipe(
            map(searchAsync.success),
            catchError((message: string) => of(searchAsync.failure(message))),
          ),
      ),
    );

export const loadDocumentDetailsEpic: Epic<SearchAction, SearchAction, RootState, RootService> =
  (action$, state$, { documentDetailsService }) =>
    action$.pipe(
      filter(isActionOf(loadDocumentDetailsAsync.request)),
      switchMap(() =>
        from(documentDetailsService.getDocumentDetailsUsingState(state$.value))
          .pipe(
            map(loadDocumentDetailsAsync.success),
            catchError((message: string) => of(loadDocumentDetailsAsync.failure(message))),
          ),
      ),
    );
