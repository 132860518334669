import { Typography } from '@material-ui/core';
import * as React from 'react';
import { Accordion } from '../../../accordion/Accordion';
import { localizedStrings } from '../../../localization/LocalizedStrings';
import { PredefinedFiltersContainer } from '../../predefinedFilters/PredefinedFiltersContainer';
import { RefinersContainer } from '../refiners/RefinersContainer';
import './DocumentFilters.scss';
import { IDocumentFiltersDispatchProps } from './IDocumentFiltersDispatchProps';
import { IDocumentFiltersStateProps } from './IDocumentFiltersStateProps';

export class DocumentFilters extends React.PureComponent<IDocumentFiltersStateProps & IDocumentFiltersDispatchProps> {

  public render(): JSX.Element {
    return (
      <>
        <div className="mobile-document-filters">
          <Accordion
            header={this.getFiltersPanelHeader()}
            contents={this.getMobileFilters()}
            expanded={this.props.isFilterPanelExpanded}
            onToggle={this.handleFiltersPanelToggle}
          />
        </div>
        <div className="document-filters">
          <div className="document-filters-wrapper">
            <RefinersContainer />
            <PredefinedFiltersContainer />
          </div>
        </div>
      </>
    );
  }

  private readonly getFiltersPanelHeader = (): JSX.Element =>
    <Typography>{localizedStrings.documentFiltersPanelHeader}</Typography>

  private readonly getMobileFilters = (): JSX.Element =>
    (
      <div className="document-filters-wrapper">
        <RefinersContainer />
        <PredefinedFiltersContainer />
      </div>
    )

  private readonly handleFiltersPanelToggle = (): void => {
    this.props.onTogglePanel();
  }
}
