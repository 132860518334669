import * as React from 'react';
import { config } from '../config/Config';
import { houseRoute, preferencesRoute, searchRoute } from '../entities/Routs';
import { localizedStrings } from '../localization/LocalizedStrings';
import { IMenuProps } from './IMenuProps';
import { IMenuState } from './IMenuState';
import './Menu.scss';
import { MenuLink } from './menuLink/MenuLink';
import { ProcessMapMenuLinkContainer } from './processMap/ProcessMapMenuLinkContainer';

export class Menu extends React.PureComponent<IMenuProps, IMenuState> {

  // tslint:disable-next-line:no-big-function
  public render(): JSX.Element {
    return (
      <div id="menu-wrapper" className={`menu-wrapper ${this.getMenuVisibilityClassName()}`}>
        <div id="menu-container" className="menu-container">
          <div className="menu top">
            <MenuLink
              iconClassName="icon-Processes"
              linkUrl={houseRoute}
              linkTitleLocalizationKey={localizedStrings.processesNavigationLink}
              isExternal={false}
              onLinkClick={this.props.onMenuItemClick}
            />
            <MenuLink
              iconClassName="icon-Projects"
              linkUrl={config.navigation.projectsLinkUrl}
              linkTitleLocalizationKey={localizedStrings.projectsNavigationLink}
              isExternal={true}
            />
            <MenuLink
              iconClassName="icon-Customer-Satisfaction"
              linkUrl={config.navigation.customerSatisfactionLinkUrl}
              linkTitleLocalizationKey={localizedStrings.customerSatisfactionNavigationLink}
              isExternal={true}
            />
            <ProcessMapMenuLinkContainer />
            <MenuLink
              iconClassName="icon-Search"
              linkUrl={searchRoute}
              linkTitleLocalizationKey={localizedStrings.searchNavigationLink}
              isExternal={false}
              onLinkClick={this.props.onMenuItemClick}
            />
            <MenuLink
              iconClassName="icon-Predefined-Filter"
              linkUrl={preferencesRoute}
              linkTitleLocalizationKey={localizedStrings.predefinedFilterNavigationLink}
              isExternal={false}
              onLinkClick={this.props.onMenuItemClick}
            />
          </div>
        </div>
        <div className="mobile-background" onClick={this.props.onMenuOutsideClick}/>
      </div>
    );
  }

  private readonly getMenuVisibilityClassName = (): string =>
    `${this.props.touched && this.props.visible ? 'visible' : 'initial'}`
}
