import { getCacheService } from '../cache/CacheServiceFactory';
import { ICacheConfig } from '../cache/ICacheConfig';
import { ICacheService } from '../cache/ICacheService';
import { ITaxonomyTerm } from './ITaxonomyTerm';
import { ITermSetInfo } from './ITermSetInfo';
import { ITermStoreInfo } from './ITermStoreInfo';
import { retrieveRawTermSets, retrieveTermDescription } from './RawTaxonomy';
import { findSingleTermByLabel, retrieveTermStore } from './Taxonomy';
import { convertTermSets } from './TaxonomyConverter';

let config: ICacheConfig = {
  useMemoryCache: false,
  useStorageCache: false,
};

let createNewServiceOnNextCall: boolean = false;

export function configTaxonomyCache(cacheConfig: ICacheConfig): void {
  config = {
    ...cacheConfig,
  };
  createNewServiceOnNextCall = true;
}

export async function getTermSetById(termSetId: string, force: boolean = false): Promise<ITermSetInfo | undefined> {
  const termSets = await getTermSetsByIds([termSetId], force);
  return termSets.find(termSet => termSet.id === termSetId);
}

export async function getTermSetsByIds(termSetIds: string[], force: boolean = false): Promise<ITermSetInfo[]> {
  return ensureTermSetsByIds(termSetIds, force);
}

export async function getTermByLabel(termSetId: string, termLabel: string): Promise<ITaxonomyTerm | undefined> {
  const termSet = await getTermSetById(termSetId);
  return termSet !== undefined ? findSingleTermByLabel(termSet, termLabel) : undefined;
}

export async function getTermDescription(termSetId: string, termId: string, lcid: number): Promise<string> {
  return ensureTermDescription(termSetId, termId, lcid);
}

export async function getTermStoreDefaultLcid(): Promise<number> {
  const termStore = await ensureTermStore();
  return termStore.defaultLcid;
}

async function ensureTermStore(): Promise<ITermStoreInfo> {
  const cacheService: ICacheService = getOrCreateCacheService();
  return cacheService.ensureValue('termStore', retrieveTermStore);
}

async function ensureTermSetsByIds(termSetIds: string[], force: boolean = false): Promise<ITermSetInfo[]> {
  const termSetIdsToLoad = force ? termSetIds : getNotLoadedTermSetIds(termSetIds);
  if (termSetIdsToLoad.length > 0) {
    const promise: Promise<ITermSetInfo[]> = Promise.all([
      retrieveRawTermSets(termSetIds),
      ensureTermStore(),
    ])
      .then(([rawTermSets, termStore]) => convertTermSets(rawTermSets, termStore.defaultLcid));
    ensureTermSetsInCache(termSetIdsToLoad, promise);
  }
  return getTermSetsFromCache(termSetIds);
}

async function getTermSetsFromCache(termSetIds: string[]): Promise<ITermSetInfo[]> {
  const cacheService: ICacheService = getOrCreateCacheService();
  const promises = termSetIds.map(async id => {
    const cacheKey: string = getTermSetCacheKey(id);
    return cacheService.getValue(cacheKey);
  });
  const termSets = await Promise.all(promises);
  return termSets.filter(termSet => termSet !== undefined) as ITermSetInfo[];
}

async function ensureTermDescription(termSetId: string, termId: string, lcid: number): Promise<string> {
  const cacheService: ICacheService = getOrCreateCacheService();
  const cacheKey = getTermDescriptionCacheKey(termId, lcid);
  return cacheService.hasValue(cacheKey)
    ? cacheService.getValue<string>(cacheKey) as Promise<string>
    : cacheService.ensureValue(cacheKey, async () => retrieveTermDescription(termSetId, termId, lcid));
}

function getNotLoadedTermSetIds(requestedTermSetIds: string[]): string[] {
  const cacheService: ICacheService = getOrCreateCacheService();
  return requestedTermSetIds.filter(id => {
    const cacheKey: string = getTermSetCacheKey(id);
    return !cacheService.hasValue(cacheKey);
  });
}

function ensureTermSetsInCache(termSetIds: string[], termSetsRetrivePromise: Promise<ITermSetInfo[]>): void {
  const cacheService: ICacheService = getOrCreateCacheService();
  termSetIds.forEach(id => {
    const cacheKey: string = getTermSetCacheKey(id);
    cacheService.ensureValue(cacheKey, async (): Promise<ITermSetInfo | undefined> =>
      termSetsRetrivePromise.then(termSets =>
        termSets.find(termSet => termSet.id === id),
      ),
    )
      .catch((reason: string) => {
        throw new Error(reason);
      });
  });
}

function getTermSetCacheKey(termSetId: string): string {
  return `termSet.${termSetId.toLowerCase()}`;
}

function getTermDescriptionCacheKey(termId: string, lcid: number): string {
  return `term.description.${termId.toLowerCase()}.${lcid}`;
}

function getOrCreateCacheService(): ICacheService {
  const cacheService = getCacheService('taxonomy', config, createNewServiceOnNextCall);
  createNewServiceOnNextCall = false;
  return cacheService;
}
