import { getCacheService } from '../cache/CacheServiceFactory';
import { ICacheConfig } from '../cache/ICacheConfig';
import { ICacheService } from '../cache/ICacheService';
import { IUserProfile } from './IUserProfile';
import { retrieveCurrentUserProfile } from './User';

let config: ICacheConfig = {
  useMemoryCache: false,
  useStorageCache: false,
};

let createNewServiceOnNextCall: boolean = false;

export function configUserCache(cacheConfig: ICacheConfig): void {
  config = {
    ...cacheConfig,
  };
  createNewServiceOnNextCall = true;
}

export async function getCurrentUserProfile(): Promise<IUserProfile> {
  return ensureCurrentUserProfile();
}

async function ensureCurrentUserProfile(): Promise<IUserProfile> {
  const cacheService: ICacheService = getOrCreateCacheService();
  return cacheService.ensureValue('myProperties', retrieveCurrentUserProfile);
}

function getOrCreateCacheService(): ICacheService {
  const cacheService = getCacheService('user', config, createNewServiceOnNextCall);
  createNewServiceOnNextCall = false;
  return cacheService;
}
