import { StyledComponentProps, Typography } from '@material-ui/core';
// tslint:disable-next-line:no-submodule-imports
import { TypographyProps } from '@material-ui/core/Typography';
import React from 'react';
// tslint:disable-next-line:no-submodule-imports
import { SingleValueProps } from 'react-select/lib/components/SingleValue';

export class SingleValue<TOption> extends React.PureComponent<SingleValueProps<TOption>> {

  public render(): JSX.Element {
    const styledProps = this.props.selectProps as StyledComponentProps;
    const className = styledProps.classes !== undefined ? styledProps.classes.singleValue : undefined;
    const innerProps = this.props.innerProps as TypographyProps;
    return (
      <Typography {...innerProps} className={className}>
        {this.props.children}
      </Typography>
    );
  }
}
