import { IconButton as MuiIconButton } from '@material-ui/core';
import * as React from 'react';
import './IconButton.scss';
import { IIconButtonProps } from './IIconButtonProps';

export class IconButton extends React.PureComponent<IIconButtonProps> {

  public render(): JSX.Element {
    const { onClick, iconClass } = this.props;
    return (
      <MuiIconButton
        id="icon-button"
        color="inherit"
        aria-label="Menu"
        className="icon-container"
        onClick={onClick}
      >
        <span className={iconClass} />
      </MuiIconButton>
    );
  }
}
