import { ITaxonomyTerm } from '../../lib/taxonomy/ITaxonomyTerm';
import { ITermSetInfo } from '../../lib/taxonomy/ITermSetInfo';
import { getTermLabel } from '../../lib/taxonomy/Taxonomy';
import { ITaxonomyTreeItem } from './ITaxonomyTreeItem';

export function buildTermSetTree(termSet: ITermSetInfo): ITaxonomyTreeItem[] {
  return getTermsTree(termSet.terms, termSet.customSortOrder);
}

export function disableItemsNotAvailableForTagging(items: ITaxonomyTreeItem[]): void {
  items.forEach(item => {
    item.disabled = item.term.isAvailableForTagging === false;
    disableItemsNotAvailableForTagging(item.children);
  });
}

export function collapseAllItems(items: ITaxonomyTreeItem[]): void {
  items.forEach(item => {
    item.collapsed = true;
    collapseAllItems(item.children);
  });
}

export function updateLabelWithParent(items: ITaxonomyTreeItem[], parentLabel: string = ''): void {
  items.forEach(item => {
    updateLabelWithParent(item.children, item.label);
    if (parentLabel !== '') {
      item.label = `${parentLabel}:${item.label}`;
    }
  });
}

function getTermsTree(terms: ITaxonomyTerm[], customSortOrder?: string[]): ITaxonomyTreeItem[] {
  const treeItems = terms.map(getTreeItem);
  let roots = treeItems.filter(item => item.term.parentId === undefined);
  roots = sortTreeItems(roots, customSortOrder);
  sortRecursive(roots, treeItems);
  return roots;
}

function getTreeItem(term: ITaxonomyTerm): ITaxonomyTreeItem {
  return {
    children: [],
    collapsed: false,
    disabled: false,
    hidden: false,
    label: getTermLabel(term),
    term,
  };
}

function sortRecursive(parents: ITaxonomyTreeItem[], allItems: ITaxonomyTreeItem[]): void {
  parents.forEach(parent => {
    let children: ITaxonomyTreeItem[] = allItems.filter(item => item.term.parentId === parent.term.id);
    children = parent.term.customSortOrder !== undefined
      ? sortUsingCustomOrder(children, parent.term.customSortOrder)
      : sortAlphabetically(children);
    parent.children = children;
    sortRecursive(children, allItems);
  });
}

function sortTreeItems(treeItems: ITaxonomyTreeItem[], customSortOrder?: string[]): ITaxonomyTreeItem[] {
  return customSortOrder !== undefined
    ? sortUsingCustomOrder(treeItems, customSortOrder)
    : sortAlphabetically(treeItems);
}

function sortUsingCustomOrder(items: ITaxonomyTreeItem[], customSortOrder: string[]): ITaxonomyTreeItem[] {
  let sortedChildren: ITaxonomyTreeItem[] = [...items];
  sortedChildren = sortAlphabetically(sortedChildren);
  customSortOrder.forEach(childId => {
    const existingItem = sortedChildren.find(item => item.term.id === childId);
    if (existingItem !== undefined) {
      const index = sortedChildren.indexOf(existingItem);
      sortedChildren.splice(index, 1);
      sortedChildren.unshift(existingItem);
    }
  });
  sortedChildren.reverse();
  return sortedChildren;
}

function sortAlphabetically(items: ITaxonomyTreeItem[]): ITaxonomyTreeItem[] {
  return items.sort((item1, item2) => item1.label.localeCompare(item2.label));
}
