import { ICacheConfig } from './ICacheConfig';
import { ICacheService } from './ICacheService';
import * as memoryCache from './MemoryCache';
import * as storageCache from './StorageCache';

export class CacheService implements ICacheService {

  public constructor(
    private readonly config: ICacheConfig,
  ) {
  }

  public async ensureValue<TValue>(key: string, retrieveFn: () => Promise<TValue>): Promise<TValue> {
    const { useMemoryCache, useStorageCache } = this.config;
    if (this.hasValueInMemoryCache(key)) {
      return memoryCache.getValue<Promise<TValue>>(key) as Promise<TValue>;
    }
    const valuePromise: Promise<TValue> = useStorageCache
      ? this.ensureValueInStorage(key, retrieveFn)
      : retrieveFn();
    if (useMemoryCache) {
      memoryCache.setValue(key, valuePromise);
    }
    return valuePromise;
  }

  public removeValue(key: string): void {
    storageCache.removeValue(key, this.config.storageType);
    memoryCache.removeValue(key);
  }

  public async getValue<TValue>(key: string): Promise<TValue | undefined> {
    const { useStorageCache, storageType } = this.config;
    if (this.hasValueInMemoryCache(key)) {
      return memoryCache.getValue(key);
    }
    const value: TValue | undefined = useStorageCache
      ? storageCache.getValue(key, storageType)
      : undefined;
    return Promise.resolve(value);
  }

  public setValue<TValue>(key: string, value: TValue): void {
    const { useMemoryCache, useStorageCache, storageType } = this.config;
    if (useMemoryCache) {
      memoryCache.setValue(key, Promise.resolve(value));
    }
    if (useStorageCache) {
      storageCache.setValue(key, value, storageType);
    }
  }

  public hasValue(key: string): boolean {
    return this.hasValueInMemoryCache(key) || this.hasValueInStorageCache(key);
  }

  private hasValueInMemoryCache(key: string): boolean {
    return this.config.useMemoryCache && memoryCache.hasValue(key);
  }

  private hasValueInStorageCache(key: string): boolean {
    return this.config.useStorageCache && storageCache.hasValue(key);
  }

  private async ensureValueInStorage<TValue>(key: string, retrieveFn: () => Promise<TValue>): Promise<TValue> {
    const { storageType, storageCacheExpirationDays } = this.config;
    const storedValue: TValue | undefined =
      storageCache.getValue(key, storageType);
    if (storedValue !== undefined) {
      return Promise.resolve(storedValue);
    }
    return retrieveFn()
      .then(value => {
        storageCache.setValue(key, value, storageType, storageCacheExpirationDays);
        return value;
      });
  }
}
