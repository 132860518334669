import { Drawer, List, ListItem, ListItemText } from '@material-ui/core';
import React from 'react';
import { localizedStrings } from '../../../localization/LocalizedStrings';
import { ISortColumnConfig } from '../entities/ISortColumnConfig';
import { documentTypeColumn, iconColumn, titleColumn } from '../entities/SortColumns';
import { ISortMenuProps } from './ISortMenuProps';
import './SortMenu.scss';
import { SortMenuItemContainer } from './SortMenuItemContainer';

export class SortMenu extends React.PureComponent<ISortMenuProps> {

  private readonly sortColumns: ISortColumnConfig[] = [
    iconColumn,
    titleColumn,
    documentTypeColumn,
  ];

  public render(): JSX.Element {
    const { opened, onClose } = this.props;
    return (
      <Drawer anchor="bottom" open={opened} onClose={onClose} className="sort-menu-wrapper">
        <List className="sort-menu">
          <ListItem key={localizedStrings.sortMenuTitle} className="sort-menu-header">
            <ListItemText className="sort-menu-text" primary={localizedStrings.sortMenuTitle} />
          </ListItem>
          {this.getMenuItems()}
        </List>
      </Drawer>
    );
  }

  private getMenuItems(): JSX.Element[] {
    return this.sortColumns.map(column => (
      <SortMenuItemContainer
        key={column.sortProperty}
        label={column.title}
        sortProperty={column.sortProperty}
      />
    ));
  }
}
