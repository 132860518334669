import { getType } from 'typesafe-actions';
import { IPredefinedFiltersConfiguration } from '../../../config/IPredefinedFiltersConfiguration';
import { setPreference, setPreferences } from '../../PreferencesActions';
import { PreferencesAction } from '../../types/PreferencesAction';
import { getPreference, getPreferences } from '../PreferencesStorage';

export function getTaxonomyPreference(
  value: string | undefined,
  action: PreferencesAction,
  preferenceConfig: IPredefinedFiltersConfiguration,
): string {
  const { cacheKey } = preferenceConfig;
  const { payload, type } = action;
  if (type === getType(setPreference) && payload.name === cacheKey) {
    return getSafeString(payload.value);
  }
  if (value === undefined) {
    const preference = getPreference(cacheKey);
    return getSafeString(preference.value);
  }
  return value;
}

export function getTaxonomyPreferences(
  values: string[] | undefined,
  action: PreferencesAction,
  preferenceConfig: IPredefinedFiltersConfiguration,
): string[] {
  const { cacheKey } = preferenceConfig;
  const { payload, type } = action;
  if (type === getType(setPreferences) && payload.name === cacheKey) {
    return getSafeStrings(payload.values);
  }
  if (values === undefined) {
    const preference = getPreferences(cacheKey);
    return getSafeStrings(preference.values);
  }
  return values;
}

function getSafeString(value: string | undefined): string {
  return value === undefined ? '' : value;
}

function getSafeStrings(value: string[] | undefined): string[] {
  return value === undefined ? [] : value;
}
