import * as React from 'react';
import { HouseTitle } from '../common/HouseTitle';
import { HouseTitleLink } from '../common/HouseTitleLink';
import { IFloorHeaderProps } from './IFloorHeaderProps';

export class FloorHeader extends React.PureComponent<IFloorHeaderProps> {

  public render(): JSX.Element {
    return (
      <div className={this.getRowClass()}>
        <div className="col">
          {this.getHeaderElement()}
        </div>
      </div>
    );
  }

  private getHeaderElement(): JSX.Element {
    const { title, url } = this.props;
    return url !== ''
      ? <HouseTitleLink title={title} url={url} />
      : <HouseTitle title={title} />;
  }

  private getRowClass(): string {
    const { className } = this.props;
    const classes = ['row'];
    if (className !== undefined) {
      classes.push(className);
    }
    return classes.join(' ');
  }
}
