import { ExpansionPanelDetails, withStyles } from '@material-ui/core';

const expansionPanelDetails = withStyles({
  root: {
    padding: 0,
  },
})(ExpansionPanelDetails);

export {
  expansionPanelDetails as AccordionExpansionPanelDetails,
};
