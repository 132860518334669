import { Epic } from 'redux-observable';
import { from, of } from 'rxjs';
// tslint:disable-next-line:no-submodule-imports
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { RootService } from '../store/types/RootService';
import { RootState } from '../store/types/RootState';
import { getUserInfoAsync } from './HeaderActions';
import { HeaderAction } from './types/HeaderAction';

export const getUserInfoEpic: Epic<HeaderAction, HeaderAction, RootState, RootService> =
  (action$, state$, { cachedUserService }) =>
    action$.pipe(
      filter(isActionOf(getUserInfoAsync.request)),
      switchMap(() =>
        from(cachedUserService.getCurrentUserProfile())
          .pipe(
            map(getUserInfoAsync.success),
            catchError((message: string) => of(getUserInfoAsync.failure(message))),
          ),
      ),
    );
