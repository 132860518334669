import { Typography } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { preferencesRoute } from '../../entities/Routs';
import { localizedStrings } from '../../localization/LocalizedStrings';
import { IFloor2MessageProps } from './IFloor2MessageProps';

export class Floor2NoConfigMessage extends React.PureComponent<IFloor2MessageProps> {

  public render(): JSX.Element {
    return (
      <Typography className={this.props.className}>
        {localizedStrings.noCoreProcessPart1}
        <Link to={preferencesRoute}>{localizedStrings.noCoreProcessPart2}</Link>
        {localizedStrings.noCoreProcessPart3}
      </Typography>
    );
  }
}
