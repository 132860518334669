import * as React from 'react';
import { connect } from 'react-redux';
import { TaxonomySelector } from '../../components/taxonomySelector/TaxonomySelector';
import { config } from '../../config/Config';
import { localizedStrings } from '../../localization/LocalizedStrings';
import { RootState } from '../../store/types/RootState';
import { loadTermSet } from '../../termSets/TermSetActions';
import { getTermSetByIdFromState } from '../../termSets/TermSetUtils';
import { ITaxonomyPreferenceDispatchProps } from '../common/taxonomy/ITaxonomyPreferenceDispatchProps';
import { ITaxonomyPreferenceProps } from '../common/taxonomy/ITaxonomyPreferenceProps';
import { ITaxonomyPreferenceStateProps } from '../common/taxonomy/ITaxonomyPreferenceStateProps';
import { setPreference } from '../PreferencesActions';

class ScopeOfServicePredefinedFilterContainer extends React.PureComponent<ITaxonomyPreferenceProps> {

  public componentDidMount(): void {
    this.props.onLoadTermSet(config.predefinedFilters.scopeOfService.termSetId);
  }

  public render(): JSX.Element {
    return (
      <TaxonomySelector
        inputId="scope-of-service-predefined-filter"
        treeView={true}
        label={localizedStrings.scopeOfServicePredefinedFilterPlaceholder}
        onChangeSelection={this.props.onChangeSelection}
        termSet={this.props.termSet}
        selectedId={this.props.selectedId}
        allowOnlyTags={true}
        hasEmptyMenuItem={true}
      />
    );
  }
}

const mapStateToProps = (state: RootState): ITaxonomyPreferenceStateProps => ({
  selectedId: state.preferences.scopeOfServiceTermId,
  termSet: getTermSetByIdFromState(state, config.predefinedFilters.scopeOfService.termSetId),
});

const dispatchProps: ITaxonomyPreferenceDispatchProps = {
  onChangeSelection: termId => setPreference({
    name: config.predefinedFilters.scopeOfService.cacheKey,
    value: termId,
  }),
  onLoadTermSet: loadTermSet.request,
};

const scopeOfServicePredefinedFilterContainer = connect(
  mapStateToProps,
  dispatchProps,
)(ScopeOfServicePredefinedFilterContainer);

export {
  scopeOfServicePredefinedFilterContainer as ScopeOfServicePredefinedFilterContainer,
};
