import { connect } from 'react-redux';
import { resetFilters } from '../../../SearchActions';
import { ClearFiltersButton } from './ClearFiltersButton';
import { IClearFiltersButtonProps } from './IClearFiltersButtonProps';

const dispatchProps: IClearFiltersButtonProps = {
  onClear: resetFilters,
};

const clearFiltersButtonContainer = connect(
  undefined,
  dispatchProps,
)(ClearFiltersButton);

export {
  clearFiltersButtonContainer as ClearFiltersButtonContainer,
};
