import { ExpansionPanelSummary, withStyles } from '@material-ui/core';

const expansionPanelSummary = withStyles({
  expandIcon: {
    height: '1.5rem',
    padding: 0,
    right: 0,
  },
  expanded: {
    margin: 0,
    minHeight: '0 !important',
  },
  root: {
    minHeight: 0,
    padding: 0,
  },
})(ExpansionPanelSummary);

export {
  expansionPanelSummary as AccordionExpansionPanelSummary,
};
