import { connect } from 'react-redux';
import { RootState } from '../../../store/types/RootState';
import { IRefinersProps } from './IRefinersProps';
import { Refiners } from './Refiners';

const mapStateToProps = (state: RootState): IRefinersProps => ({
  refinementFilters: state.search.refinementFilters,
  refinements: state.search.result.refinements,
});

const refinersContainer = connect(
  mapStateToProps,
  undefined,
)(Refiners);

export {
  refinersContainer as RefinersContainer,
};
