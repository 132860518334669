import { Typography } from '@material-ui/core';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { IMenuLinkProps } from './IMenuLinkProps';
import './MenuLink.scss';

export class MenuLink extends React.PureComponent<IMenuLinkProps> {

  public render(): JSX.Element {
    const { linkUrl, isExternal } = this.props;
    const linkContent: JSX.Element = this.getLinkContent();
    const url = linkUrl !== undefined ? linkUrl : '/';
    return isExternal
      ? <a className="menu-link-container" href={url}>{linkContent}</a>
      : (
        <Link to={url} className="menu-link-container" onClick={this.props.onLinkClick}>
          {linkContent}
        </Link>
      );
  }

  private getLinkContent(): JSX.Element {
    const { iconClassName, linkTitleLocalizationKey } = this.props;
    return (
      <div className="menu-link">
        <span className={`${iconClassName} link-icon`} />
        <Typography className="link-text">{linkTitleLocalizationKey}</Typography>
      </div>
    );
  }
}
