import React from 'react';
import { connect } from 'react-redux';
import { houseConfig } from '../../config/HouseConfig';
import { getTermHierarchy } from '../../lib/taxonomy/Taxonomy';
import { RootState } from '../../store/types/RootState';
import { loadTermSet } from '../../termSets/TermSetActions';
import { getTermSetByIdFromState } from '../../termSets/TermSetUtils';
import { Breadcrumb } from './Breadcrumb';
import { IBreadcrumbContainerDispatchProps } from './IBreadcrumbContainerDispatchProps';
import { IBreadcrumbContainerProps } from './IBreadcrumbContainerProps';
import { IBreadcrumbContainerStateProps } from './IBreadcrumbContainerStateProps';

class BreadcrumbContainer extends React.PureComponent<IBreadcrumbContainerProps> {

  public render(): JSX.Element | null {
    const { termSet, leafTermId } = this.props;
    const terms = termSet !== undefined && leafTermId !== '' ? getTermHierarchy(termSet, leafTermId) : [];
    return <Breadcrumb terms={terms} />;
  }

  public componentDidMount(): void {
    this.props.onLoaded();
  }
}

const mapStateToProps = (state: RootState): IBreadcrumbContainerStateProps => ({
  leafTermId: state.search.processTypeTermId,
  termSet: getTermSetByIdFromState(state, houseConfig.termSetId),
});

const dispatchProps: IBreadcrumbContainerDispatchProps = {
  onLoaded: () => loadTermSet.request(houseConfig.termSetId),
};

const breadcrumbContainer = connect(
  mapStateToProps,
  dispatchProps,
)(BreadcrumbContainer);

export {
  breadcrumbContainer as BreadcrumbContainer,
};
