import { config } from '../../../config/Config';
import { ISortProperty } from '../../../lib/entities/ISortProperty';
import { IRefinementFilter } from '../../../lib/search/IRefinementFilter';
import { search } from '../../../lib/search/Search';
import { replaceSearchPlaceholders } from '../../../lib/search/SearchPlaceholderReplacers';
import { RootState } from '../../../store/types/RootState';
import { ISearchState } from '../../ISearchState';
import { getBusinessUnitWithParentsQuery } from './DocumentBusinessUnit';
import { convertToDocumentsSearchResult } from './DocumentConverter';
import { replaceDocumentPlaceholders } from './DocumentsPlaceholderReplacer';
import { IDocumentRefinementFilter } from './IDocumentRefinementFilter';
import { IDocumentSearchParameters } from './IDocumentSearchParameters';
import { IDocumentsSearchResult } from './IDocumentsSearchResult';

export async function searchDocumentsUsingState(state: RootState): Promise<IDocumentsSearchResult> {
  const {
    queryText,
    refinementFilters,
    processTypeTermId,
    businessUnitTermId,
    countryTermId,
    languageTermIds,
  } = state.search;
  const sortProperty = getSortProperty(state.search);
  const businessUnitWithParents = getBusinessUnitWithParentsQuery(businessUnitTermId, state);
  return searchDocuments({
    businessUnitWithParents,
    countryTermId,
    languageTermIds,
    processTypeTermId,
    query: queryText === '' ? '*' : queryText,
    refinementFilters,
    sortProperty,
  });
}

function getSortProperty(searchState: ISearchState): ISortProperty | undefined {
  const { sortBy, sortDirection } = searchState;
  return sortBy === ''
    ? undefined
    : {
      direction: sortDirection,
      propertyName: sortBy,
    };
}

async function searchDocuments(parameters: IDocumentSearchParameters): Promise<IDocumentsSearchResult> {
  const { sortProperty, refinementFilters } = parameters;
  const processedQuery = getQueryWithReplacedPlaceholders(parameters);
  const refiners = config.search.refiners.map(refinerConfig => refinerConfig.name);
  const searchResult = await search({
    pageNumber: 1,
    queryText: processedQuery,
    refinementFilters: refinementFilters !== undefined ? getRefinementFilters(refinementFilters) : undefined,
    refiners,
    rowLimit: config.search.pageSize,
    selectProperties: config.search.selectProperties,
    sortProperties: sortProperty !== undefined ? [sortProperty] : [],
    trimDuplicates: config.search.trimDuplicates,
  });
  return convertToDocumentsSearchResult(searchResult);
}

function getRefinementFilters(documentRefinementFilters: IDocumentRefinementFilter[]): IRefinementFilter[] {
  return documentRefinementFilters.map(filter => ({
    name: filter.name,
    values: filter.values.map(value => value.token),
  }));
}

function getQueryWithReplacedPlaceholders(parameters: IDocumentSearchParameters): string {
  const { query, processTypeTermId, businessUnitWithParents, countryTermId, languageTermIds } = parameters;
  const processedQuery = replaceSearchPlaceholders(config.search.queryTemplate, query);
  return replaceDocumentPlaceholders(
    processedQuery,
    processTypeTermId,
    businessUnitWithParents,
    countryTermId,
    languageTermIds,
  );
}
