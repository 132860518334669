import { Epic } from 'redux-observable';
import { from, of } from 'rxjs';
// tslint:disable-next-line:no-submodule-imports
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { ITermSetInfo } from '../lib/taxonomy/ITermSetInfo';
import { RootService } from '../store/types/RootService';
import { RootState } from '../store/types/RootState';
import { loadTermDescription, loadTermSet } from './TermSetActions';
import { TermSetAction } from './types/TermSetAction';

export const loadTermSetEpic: Epic<TermSetAction, TermSetAction, RootState, RootService> =
  (action$, state$, { cachedTaxonomyService }) =>
    action$.pipe(
      filter(isActionOf(loadTermSet.request)),
      mergeMap(action =>
        from(cachedTaxonomyService.ensureTermSetUsingState(state$.value, action.payload))
          .pipe(
            map(value => loadTermSet.success(value as ITermSetInfo)),
            catchError((message: string) => of(loadTermSet.failure(message))),
          ),
      ),
    );

export const loadTermDescriptionEpic: Epic<TermSetAction, TermSetAction, RootState, RootService> =
  (action$, state$, { cachedTaxonomyService }) =>
    action$.pipe(
      filter(isActionOf(loadTermDescription.request)),
      mergeMap(action => {
        const termDescription = cachedTaxonomyService.ensureTermDescription(
          state$.value,
          action.payload.termSetId,
          action.payload.termId,
          action.payload.lcid,
        );
        return from(termDescription)
          .pipe(
            map(value => loadTermDescription.success(value)),
            catchError((message: string) => of(loadTermDescription.failure(message))),
          );
      },
      ),
    );
